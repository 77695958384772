<script setup>
import { ref, toRef, computed } from 'vue'
import SlideOver from '@/Shared/SlideOver.vue'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import { usePage } from '@inertiajs/inertia-vue3'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['close', 'update:modelValue', 'submit'])

const user = computed(() => usePage().props.value.auth.user)

const form = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const open = toRef(props, 'open')
const formElement = ref(null)

const roles = [
  { id: 1, name: 'Admin' },
  { id: 2, name: 'Member' },
]

function isEditMode() {
  return location.pathname.includes('edit')
}

function submitForm() {
  if (formElement.value.requestSubmit) {
    formElement.value.requestSubmit()
  } else {
    formElement.value.submit()
  }
}
</script>

<template>
  <SlideOver :title="isEditMode() ? 'Edit User' : 'New User'" :open="open" @close="emit('close')">
    <template #default>
      <form ref="formElement" class="flex flex-col divide-y divide-gray-200" @submit.prevent="emit('submit')">
        <div class="px-4 pb-5 space-y-6 sm:px-6">
          <TextInput
            v-model="form.user.name"
            label="Name"
            required
            :errors="form.errors.name"
          />

          <TextInput
            v-model="form.user.email"
            label="Email"
            type="email"
            required
            :errors="form.errors.email"
          />

          <TextInput
            v-model="form.user.phone"
            label="Phone Number"
            type="tel"
            required
            :errors="form.errors.phone"
          />

          <TextInput
            v-model="form.user.ics_url"
            label="Calendar URL"
            type="url"
            required
            :errors="form.errors.ics_url"
          />

          <TextInput
            v-if="user.is_admin"
            v-model="form.user.password"
            label="Password"
            type="password"
            :required="!isEditMode()"
            :errors="form.errors.password"
          />

          <SelectInput
            v-model="form.user.role_id"
            label="Role"
            required
            :errors="form.errors.role_id"
          >
            <option :value="null" diabled selected>
              Select a role
            </option>
            <option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.name }}
            </option>
          </SelectInput>
        </div>
      </form>
    </template>

    <template #footer>
      <div class="flex justify-end flex-shrink-0 px-4 py-4 space-x-3">
        <button
          class="px-4 py-2 text-sm border-2 button-outline button-rounded"
          @click="emit('close')"
        >
          Cancel
        </button>
        <button type="button" class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary" @click="submitForm">
          {{ isEditMode() ? 'Update' : 'Create' }}
        </button>
      </div>
    </template>
  </SlideOver>
</template>