<script setup>
import { toRefs } from 'vue'
import NewTask from '@/Shared/NewTask.vue'
import Icon from '@/Shared/Icon.vue'

import TaskItem from './TaskItem.vue'
import useTasks from '../Composables/useTasks.js'

import Pagination from '@/Shared/Pagination.vue'
import usePagination from '@/composables/usePagination.js'

const props = defineProps({
  tasks: Array,
})

const { tasks } = toRefs(props)
const { form, selected, openNewTaskModal, displayTasks, createTask } = useTasks(tasks)

const {
  paginatedData,
  paginator,
} = usePagination(displayTasks, { numButtons: 5, pageSize: 6 })
</script>

<template>
  <div>
    <div class="px-2 pt-6 pb-2 lg:pt-12 lg:pb-3 lg:px-0">
      <h3 class="text-2xl font-bold text-gray-900">
        Tasks
      </h3>
    </div>

    <div class="flex items-center justify-between px-2 lg:px-0">
      <span class="relative z-0 inline-flex rounded-md shadow-sm">
        <button
          :class="selected ? 'bg-hartblue text-white border-hartblue font-semibold' : 'bg-white text-gray-900 font-medium hover:bg-gray-100 border-gray-300'"
          type="button"
          class="relative inline-flex items-center px-4 py-2 transition duration-300 ease-out border text-xsm lg:text-sm rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          @click="selected = true"
        >
          Open
        </button>
        <button
          :class="!selected ? 'bg-hartblue text-white border-hartblue font-semibold' : 'font-medium bg-white text-gray-900 hover:bg-gray-100 border-gray-300'"
          type="button"
          class="relative inline-flex items-center px-4 py-2 -ml-px transition duration-300 ease-out border text-xsm lg:text-sm rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          @click="selected = !selected"
        >
          Completed
        </button>
      </span>
      <button
        class="flex items-center px-5 py-2 lg:px-8 text-xsm lg:text-base button-rounded button-primary"
        @click="openNewTaskModal = true"
      >
        New Task
        <Icon
          name="plus"
          class="w-5 h-5 pl-2 text-white fill-current"
        />
      </button>
    </div>

    <div class="px-4 mt-4 -mx-4 lg:overflow-x-auto sm:-mx-8 sm:px-8">
      <div class="inline-block min-w-full shadow lg:rounded-md">
        <table id="show-tasks" class="w-full leading-normal text-left table-fixed lg:w-auto lg:min-w-full">
          <thead>
            <tr>
              <th class="w-6 table-header blue-header">
                <span class="sr-only">Client or Lead source</span>
              </th>
              <th class="pl-4 text-xs font-medium lg:pl-0 lg:text-base lg:font-semibold blue-header">
                Subject
              </th>
              <th class="hidden table-header blue-header lg:table-cell">
                Due Date
              </th>
              <th class="hidden w-2/5 lg:table-cell table-header blue-header">
                Notes
              </th>
              <th class="px-4 w-36 lg:w-40 table-header lg blue-header">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <TaskItem
              v-for="(task, index) in paginatedData"
              :key="index"
              class="hover:bg-gray-20"
              :task="task"
            />
            <tr v-if="paginatedData.length === 0">
              <td colspan="5" class="hidden px-4 py-5 text-xs text-center lg:table-cell table-item lg:text-sm">
                <p class="text-gray-900">
                  Empty tasks
                </p>
              </td>
              <td colspan="3" class="px-4 py-5 text-xs text-center lg:hidden table-item lg:text-sm">
                <p class="text-gray-900">
                  Empty tasks
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination v-if="paginatedData.length > 0" :paginator="paginator" />
      </div>
    </div>
    <NewTask
      v-model="form"
      :open="openNewTaskModal"
      @close="() => { openNewTaskModal = false; form.reset() }"
      @submit="createTask"
    />
  </div>
</template>