import axios from 'axios'
import { ref, computed } from 'vue'
import * as routes from '@/utils/routes.js'
import { Inertia } from '@inertiajs/inertia'

export default function useNotes(leadOrClient, notesRaw, moduleRaw) {
  const creatingNote = ref(false)
  const noteForm = ref({
    id: leadOrClient.value.id,
    content: '',
  })

  const notes = computed(() => {
    if (typeof notesRaw.value.length == 'undefined') return []
    return notesRaw.value.map(note => {
      return {
        id: note.id,
        title: note.Note_Title,
        content: note.Note_Content,
        created_at: note.Created_Time,
        owner_name: note.Owner.name,
      }
    })
  })

  const createNote = () => {
    creatingNote.value = true
    axios.post(routes.notes(), {
      note: noteForm.value,
      options: {
        module: moduleRaw,
        redirect_url: routes.client(leadOrClient.value.id),
      },
    }).then(() => {
      setTimeout(() => {
        creatingNote.value = false
        noteForm.value = {
          id: leadOrClient.value.id,
          content: '',
        }
      }, 1000)
      Inertia.reload()
    })
  }
  return {
    noteForm,
    creatingNote,
    notes,
    createNote,
  }
}