<script setup>
import { toRefs } from 'vue'
import * as routes from '@/utils/routes'
import { Link } from '@inertiajs/inertia-vue3'
import StatusBadge from '@/Shared/StatusBadge.vue'
import useLeadOrClientInfo from '@/composables/useLeadOrClientInfo.js'
import Icon from '@/Shared/Icon.vue'

const props = defineProps({
  client: Object,
})

const { client } = toRefs(props)
const { fullName, email, plan, formatedPhone, status, googleMapsUrl, cityState, zipCode } = useLeadOrClientInfo(client)

</script>

<template>
  <tr
    class="hover:bg-gray-20"
  >
    <td class="w-2/5 px-4 py-5 text-left lg:w-auto lg:text-center table-item">
      <Link
        :href="routes.activity_client(client.id)"
        class="hidden font-semibold whitespace-nowrap text-hartblue lg:flex text-xsm lg:text-sm"
      >
        {{ fullName }}
        <Icon
          v-if="client.spouse"
          name="users-alt"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
      </Link>
      <Link
        :href="routes.activity_client(client.id)"
        class="block font-semibold leading-4 text-hartblue lg:hidden text-xsm lg:text-sm"
      >
        {{ fullName }}
      </Link>
      <a
        :href="googleMapsUrl"
        class="block mt-1 text-xs text-gray-800 lg:hidden"
      >{{ cityState }} {{ zipCode }}</a>
    </td>
    <td class="hidden px-4 py-5 lg:table-cell table-item">
      <a
        :href="`mailto:${email}`"
        class="text-sm text-gray-800 transition duration-300 ease-out hover:text-hartblue"
      >{{ email }}</a>
    </td>
    <td class="hidden px-4 py-5 table-item lg:table-cell">
      <a
        :href="googleMapsUrl"
        class="flex text-sm text-gray-800 transition duration-300 ease-out hover:text-hartblue"
      >
        {{ cityState }} {{ zipCode }}
        <Icon
          name="location"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
      </a>
    </td>
    <td class="hidden px-4 py-5 lg:table-cell table-item">
      <a
        :href="`tel:${client.mobile}`"
        class="flex text-sm whitespace-nowrap text-hartblue"
      >
        {{ formatedPhone }}
        <Icon
          name="phone"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
      </a>
    </td>
    <td class="hidden px-4 py-5 table-item lg:table-cell">
      <p class="text-sm text-gray-900">
        {{ plan }}
      </p>
    </td>
    <td class="px-4 py-5 text-center table-item">
      <StatusBadge :dropdown="false" class="mx-auto" :status="status" />
    </td>
    <td class="table-item lg:hidden">
      <div class="flex items-center px-4 py-4">
        <a
          :href="`mailto:${email}`"
          class="flex p-2 bg-gray-200 rounded-full text-hartblue"
        >
          <Icon
            name="mail"
            class="w-5 h-5 fill-current"
          />
        </a>
        <a
          :href="`tel:${client.mobile}`"
          class="flex p-2 ml-1 bg-gray-200 rounded-full text-hartblue"
        >
          <Icon
            name="phone"
            class="w-5 h-5 fill-current"
          />
        </a>
      </div>
    </td>
  </tr>
</template>
