import '~/styles/application.css'
import 'floating-vue/dist/style.css'

import { createApp, h } from 'vue'
import Notifications from 'notiwind'
import { InertiaProgress } from '@inertiajs/progress'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import FloatingVue from 'floating-vue'
import Maska from 'maska'
import money from 'v-money'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import '../utils/form-request-submit-polyfill'
import '../utils/postcss-fix-100vh'

InertiaProgress.init({
  showSpinner: true,
})

const pages = import.meta.globEager('../Pages/**/*.vue')

createInertiaApp({
  resolve: name => pages[`../Pages/${name}.vue`],
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(Notifications)
      .use(FloatingVue)
      .use(Maska)
      .use(money, { precision: 2 })
      .use(VueGoogleMaps, { load: { key: 'AIzaSyAnMZQAn-30MDRxhFA76XeMuVNpkQUf5GU', libraries: 'places' } })
      .mount(el)
  },
})
