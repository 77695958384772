import { ref, computed } from 'vue'

export default function useAppointmentSearch(appointments) {
  const searchQuery = ref('')

  const appointmentsMatchingSearchQuery = computed(() => {
    return appointments.value.filter((appointment) => {
      return Object.keys(appointment).some(key =>
        String(appointment[key])
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase()),
      )
    })
  })

  return {
    searchQuery,
    appointmentsMatchingSearchQuery,
  }
}