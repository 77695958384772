import { ref } from 'vue'
import axios from 'axios'
import * as routes from '@/utils/routes'
import { useForm } from '@inertiajs/inertia-vue3'
import { dateFormatToCRM } from '@/utils/dates.js'

export default function usePolicy(contact) {

  const openPolicyModal = ref(false)

  const policyForm = useForm({
    policy: {
      new_to_medicare: false,
      application_submitted: '',
      effective_date: '',
      policy_holder: '',
      product_name: '',
      new_renewal: '',
      term: '',
      source_of_funds: '',
      amount: '',
    },
    contact: contact,
    company_name: '',
    ancillary_plans: {
      have_any_plan: false,
      plan: null,
    },
  })

  const submitAncillaryPlansForm = (policyFormClone) => {
    let form = useForm(policyFormClone)
    form.company_name = policyForm.ancillary_plans.plan.company
    form.policy.product_name = policyForm.ancillary_plans.plan.value
    axios.put(routes.client_policies(policyForm.contact.id, { format: 'json' }), form)
  }

  const submitPolicyForm = () => {
    if (policyForm.policy.effective_date != '') {
      policyForm.policy.effective_date = dateFormatToCRM(policyForm.policy.effective_date)
    }
    policyForm.policy.application_submitted = dateFormatToCRM(policyForm.policy.application_submitted)
    policyForm.contact.date_of_birth = dateFormatToCRM(policyForm.contact.date_of_birth)

    if (policyForm.ancillary_plans.have_any_plan) {
      submitAncillaryPlansForm({ ...{
        policy: { ...policyForm.policy },
        company_name: policyForm.company_name,
        contact: { ...policyForm.contact },
        ancillary_plans: { ...policyForm.ancillary_plans },
      } })
    }

    policyForm.put(routes.client_policies(policyForm.contact.id, { format: 'html' }), {
      onSuccess: () => {
        policyForm.policy = {
          new_to_medicare: true,
          application_submitted: '',
          effective_date: '',
          policy_holder: '',
          product_name: '',
        }
        policyForm.company_name = ''
        openPolicyModal.value = false
      },
    })

  }

  const initializePolicyModal = () => {
    policyForm.policy.policy_holder = policyForm.contact.id
    policyForm.contact.need_update = policyForm.contact.date_of_birth == null ? true : false
    openPolicyModal.value = true
  }

  return {
    openPolicyModal,
    policyForm,
    initializePolicyModal,
    submitPolicyForm,
  }
}