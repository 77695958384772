import { computed, watch } from 'vue'
import usePaginator from 'vue-use-paginator'

export default function useAppointmentPagination(appointments) {
  const paginator = usePaginator({ numButtons: 5, pageSize: 10 })
  paginator.numItems.value = appointments.value.length

  watch(appointments, (value) => {
    paginator.numItems.value = value.length
  })

  const paginatedAppointments = computed(() => {
    return appointments.value.slice(paginator.slice.value[0], paginator.slice.value[1])
  })

  return {
    paginatedAppointments,
    paginator,
  }
}