import { ref } from 'vue'
import * as routes from '@/utils/routes.js'
import axios from 'axios'
import dayjs from 'dayjs'

export default function useSecureModalCard() {

  const openSecurityModal = ref(false)
  const clikedLabel = ref(null)
  const showDOBBtn = ref(true)
  const showSSNBtn = ref(true)
  const showSMNBtn = ref(true)
  const slotInfo = ref({
    title: '',
    content: '',
  })

  const regitryLogMessage = (clientOrLead, message) => {
    axios.post(routes.notes(), {
      title: 'Audit Log',
      note: {
        id: clientOrLead.id,
        content: message,
      },
      options: {
        module: clientOrLead.module,
        redirect_url: clientOrLead.redirect_to,
        hastitle: true,
      },
    })
  }

  const sendSecurityCode = (label, obj) => {
    axios.post(routes.verify_authorize({ format: 'json' }))
      .then(() => {
        openSecurityModal.value = true
        clikedLabel.value = label
        slotInfo.value = obj
        axios.post(routes.verify({ format: 'json' }))
      })
  }

  const showClickedLabel = (clientOrLead) => {
    switch (clikedLabel.value) {
    case 'DOB':
      regitryLogMessage(clientOrLead, `${clientOrLead.agent_name} viewed the Date of Birth field on ${dayjs().format('MM/DD/YY')} at ${dayjs().format('hh:mm A')}`)
      showDOBBtn.value = false
      break
    case 'SMN':
      regitryLogMessage(clientOrLead, `${clientOrLead.agent_name} viewed the Medicare Number field on ${dayjs().format('MM/DD/YY')} at ${dayjs().format('hh:mm A')}`)
      showSMNBtn.value = false
      break
    case 'SSN':
      regitryLogMessage(clientOrLead, `${clientOrLead.agent_name} viewed the SSN field on ${dayjs().format('MM/DD/YY')} at ${dayjs().format('hh:mm A')}`)
      showSSNBtn.value = false
      break
    default:
      break
    }
  }

  return {
    openSecurityModal,
    clikedLabel,
    showDOBBtn,
    showSSNBtn,
    showSMNBtn,
    sendSecurityCode,
    showClickedLabel,
    slotInfo,
  }
}