<script setup>
import { ref, toRef, computed, inject } from 'vue'
import { DatePicker } from 'v-calendar'
import SlideOver from '@/Shared/SlideOver.vue'
import TextInput from '@/Shared/TextInput.vue'

import 'v-calendar/dist/style.css'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['close', 'update:modelValue', 'submit'])
const fields = inject('fields')
const form = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const open = toRef(props, 'open')
const formElement = ref(null)

const taskOwners = computed(() => {
  return fields.taskOwners.filter((owner) => {
    return owner.status == 'active' && owner.email != 'jneira@gohartland.com'
  })
})

function submitForm() {
  if (!validateFormDates()) return
  if (formElement.value.requestSubmit) {
    formElement.value.requestSubmit()
  } else {
    formElement.value.submit()
  }
}

function validateFormDates() {
  if (form.value.task.id != undefined) return true
  form.value.errors.due_date = undefined
  if (form.value.task.due_date != null) {
    return true
  } else {
    form.value.errors.due_date = ['Select a date']
    return false
  }
}
</script>

<template>
  <SlideOver title="New task" :open="open" @close="emit('close')">
    <template #default>
      <div class="relative">
        <div v-if="form.processing" class="absolute z-10 flex items-center justify-center w-full h-full bg-gray-300 bg-opacity-60 ">
          <div class="mt-1 lds-ring">
            <div /><div /><div /><div />
          </div>
        </div>
        <form ref="formElement" class="flex flex-col divide-y divide-gray-200" @submit.prevent="emit('submit')">
          <div class="px-4 pb-5 space-y-6 sm:px-6">
            <TextInput
              v-model="form.task.subject"
              label="Task name"
              required
              :errors="form.errors.subject"
            />

            <div>
              <label class="block text-sm font-medium text-gray-700" for="assign">
                Assign
              </label>
              <div class="mt-1">
                <select
                  id="assign"
                  v-model="form.task.owner"
                  class="form-underline"
                  :class="{ error: form.errors.owner }"
                >
                  <option value="">
                    Current Agent
                  </option>
                  <option v-for="taskOwner in taskOwners" :key="taskOwner.id" :value="taskOwner.id">
                    {{ taskOwner.full_name }}
                  </option>
                </select>
              </div>
              <div
                v-if="form.errors.task_owner"
                class="mt-2 text-xs text-red-600"
              >
                {{ form.errors.task_owner[0] }}
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">Select date</label>
              <div class="mt-1 rounded shadow-sm">
                <DatePicker v-model="form.task.due_date" color="indigo" expanded />
              </div>
              <div
                v-if="form.errors.due_date"
                class="mt-2 text-xs text-red-600 lg:text-sm"
              >
                {{ form.errors.due_date[0] }}
              </div>
            </div>

            <div>
              <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
              <div class="mt-1">
                <textarea
                  id="description"
                  v-model="form.task.description"
                  class="resize-none form-underline"
                  rows="6"
                  placeholder="A short description of the task…"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end flex-shrink-0 px-4 py-4 space-x-3">
        <button
          class="px-4 py-2 text-sm border-2 button-outline button-rounded"
          @click="emit('close')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary"
          :disabled="form.processing"
          @click="submitForm"
        >
          Create task
        </button>
      </div>
    </template>
  </SlideOver>
</template>
