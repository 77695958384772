import { ref } from 'vue'
import axios from 'axios'
import { notify } from 'notiwind'
import * as routes from '@/utils/routes'
import { Inertia } from '@inertiajs/inertia'
import { useForm } from '@inertiajs/inertia-vue3'
import { dateFormatToCRM } from '@/utils/dates.js'

export default function useAppointmentStageUpdater(appointment) {

  const openSoldModal = ref(false)
  const openLostModal = ref(false)
  const openOngoingModal = ref(false)

  const lostForm = useForm({
    stage: {
      id: null,
      stage: '',
      lost_reason: '',
    },
  })

  const ongoingForm = useForm({
    stage: {
      id: null,
      stage: '',
      lost_reason: '',
    },
  })

  const soldForm = useForm({
    policy: {
      new_to_medicare: false,
      application_submitted: '',
      effective_date: '',
      policy_holder: '',
      product_name: '',
      new_renewal: '',
      term: '',
      source_of_funds: '',
      amount: '',
    },
    company_name: '',
    appointment: null,
    stage: '',
    ancillary_plans: {
      have_any_plan: false,
      plan: null,
    },
  })

  const submitLostForm = () => {
    lostForm.put(routes.appointment_stage(lostForm.stage.id, { format: 'html' }), {
      onSuccess: () => {
        lostForm.stage.id = null
        lostForm.stage.lost_reason = ''
        lostForm.stage.stage = ''
        openLostModal.value = false
      },
    })
  }

  const submitOngoingForm = () => {
    ongoingForm.put(routes.appointment_stage(ongoingForm.stage.id, { format: 'html' }), {
      onSuccess: () => {
        ongoingForm.stage.id = null
        ongoingForm.stage.lost_reason = ''
        ongoingForm.stage.stage = ''
        openOngoingModal.value = false
      },
    })
  }

  const submitAncillaryPlansForm = (soldFormClone) => {
    let form = useForm(soldFormClone)
    form.company_name = soldForm.ancillary_plans.plan.company
    form.policy.product_name = soldForm.ancillary_plans.plan.value
    axios.put(routes.appointment_policies(soldForm.appointment.id, { format: 'json' }), form)
  }

  const submitSoldForm = () => {
    if (soldForm.policy.effective_date != '') {
      soldForm.policy.effective_date = dateFormatToCRM(soldForm.policy.effective_date)
    }
    soldForm.policy.application_submitted = dateFormatToCRM(soldForm.policy.application_submitted)
    soldForm.appointment.contact_date_of_birth = dateFormatToCRM(soldForm.appointment.contact_date_of_birth)

    if (soldForm.ancillary_plans.have_any_plan) {
      submitAncillaryPlansForm({ ...{
        policy: { ...soldForm.policy },
        company_name: soldForm.company_name,
        appointment: { ...soldForm.appointment },
        stage: soldForm.stage,
        ancillary_plans: { ...soldForm.ancillary_plans },
      } })
    }

    soldForm.put(routes.appointment_policies(soldForm.appointment.id, { format: 'html' }), {
      onSuccess: () => {
        soldForm.policy = {
          new_to_medicare: true,
          application_submitted: '',
          effective_date: '',
          policy_holder: '',
          product_name: '',
        }
        soldForm.company_name = ''
        openSoldModal.value = false
      },
    })
  }

  const openModal = (stage) => {
    if (stage.name == 'Sold') {
      soldForm.policy.policy_holder = stage.appointment.contact_name.id
      soldForm.appointment = stage.appointment
      soldForm.appointment.contact_name.need_update = stage.appointment.contact_date_of_birth == null ? true : false
      soldForm.stage = stage.name
      openSoldModal.value = true
    } else if (stage.name == 'Lost') {
      lostForm.stage.id = stage.appointment.id
      lostForm.stage.lost_reason = ''
      lostForm.stage.stage = stage.name
      openLostModal.value = true
    } else {
      ongoingForm.stage.id = stage.appointment.id
      ongoingForm.stage.lost_reason = ''
      ongoingForm.stage.stage = stage.name
      openOngoingModal.value = true
    }
  }

  const update = async (stage) => {
    try {
      appointment.value.stage = 'Updating'
      await axios.put(routes.appointment_stage(appointment.value.id, { format: 'json' }),
        { stage: { stage: stage.name } },
      )
      Inertia.reload()
    } catch ({ response }) {
      notify({
        group: 'bottom',
        title: 'Error updating the appointment',
        text: `${response.data.error} - ${response.data.code}`,
      }, 5000)
    }
  }

  return {
    openSoldModal,
    openLostModal,
    openOngoingModal,
    lostForm,
    soldForm,
    ongoingForm,
    submitLostForm,
    submitOngoingForm,
    submitSoldForm,
    openModal,
    update,
  }
}