
import { ref } from 'vue'
import { useForm } from '@inertiajs/inertia-vue3'
import { formatDate, dateHourFormatToCRM, dayjs } from '@/utils/dates.js'
import { Inertia } from '@inertiajs/inertia'
import axios from 'axios'
import * as routes from '@/utils/routes.js'
import { notify } from 'notiwind'

export default function useTasks(leadOrClien) {
  const openNewTask = ref(false)

  const taskForm = useForm({
    task: {
      due_date: dateHourFormatToCRM(dayjs()),
      owner: '',
    },
  })

  const createTask = () => {
    taskForm.processing = true
    let { task } = taskForm
    axios.post(routes.tasks(), { task: { ...task, due_date: formatDate(task.due_date, 'YYYY-MM-DD'), client_id: leadOrClien.value.id } }).then(() => {
      Inertia.reload()
      openNewTask.value = false
      taskForm.task = {}
      taskForm.processing = false
    }).catch((response) => {
      openNewTask.value = false
      taskForm.processing = false
      notify({
        group: 'bottom',
        title: 'Error creating the task',
        text: `${response}`,
      }, 7000)
    })
  }
  return {
    openNewTask,
    taskForm,
    createTask,
  }
}