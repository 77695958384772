<script setup>
import { ref, watch, toRefs } from 'vue'
import Icon from '@/Shared/Icon.vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'

const props = defineProps({
  stage: String,
})

const emit = defineEmits(['change'])

const stages = ref([
  {
    name: 'Need to Schedule',
    enabled: false,
    hidden: false,
    background: 'bg-orange-100',
    color: 'text-orange-700',
    enables: [],
  },
  {
    name: 'Scheduled',
    enabled: false,
    hidden: false,
    background: 'bg-teal-100',
    color: 'text-teal-700',
    enables: ['Need to Schedule', 'Confirmed', 'Unable to Confirm', 'Started', 'Cancelled', 'No Show'],
  },
  {
    name: 'Unable to Confirm',
    enabled: false,
    hidden: false,
    background: 'bg-blue-300',
    color: 'text-blue-700',
    enables: ['Need to Schedule', 'Started', 'Cancelled', 'No Show'],
  },
  {
    name: 'Confirmed',
    enabled: false,
    hidden: false,
    background: 'bg-indigo-100',
    color: 'text-indigo-700',
    enables: ['Need to Schedule', 'Started', 'Cancelled', 'No Show'],
  },
  {
    name: 'Cancelled',
    enabled: false,
    hidden: false,
    background: 'bg-gray-200',
    color: 'text-gray-700',
    enables: ['Need to Schedule', 'Confirmed', 'Unable to Confirm', 'Scheduled', 'Started', 'No Show'],
  },
  {
    name: 'No Show',
    enabled: false,
    hidden: false,
    background: 'bg-purple-200',
    color: 'text-purple-700',
    enables: [],
  },
  {
    name: 'Started',
    enabled: false,
    hidden: false,
    background: 'bg-yellow-100',
    color: 'text-yellow-700',
    enables: ['Sold', 'Lost', 'Ongoing'],
  },

  {
    name: 'Sold',
    enabled: false,
    hidden: false,
    background: 'bg-emerald-100',
    color: 'text-emerald-700',
    enables: [],
  },
  {
    name: 'Lost',
    enabled: false,
    hidden: false,
    background: 'bg-red-100',
    color: 'text-red-700',
    enables: [],
  },
  {
    name: 'Ongoing',
    enabled: false,
    hidden: false,
    background: 'bg-cyan-100',
    color: 'text-cyan-700',
    enables: [],
  },
  {
    name: 'Completed',
    enabled: false,
    hidden: true,
    background: 'bg-emerald-100',
    color: 'text-emerald-700',
    enables: [],
  },
])

const { stage } = toRefs(props)

const selectedObject = ref(null)
const selectedStage = ref(null)

watch(stage, () => {
  selectedObject.value = stages.value.find((state) => state.name === stage.value)
  selectedStage.value = selectedObject.value
  stages.value.forEach((_stage) => {
    _stage.enabled = false
  })

  selectedStage.value.enables.forEach(enable => {
    stages.value.find((stage) => stage.name === enable).enabled = true
  })

}, {
  immediate: true,
})

function updateStage(value) {
  emit('change', value)
}
</script>

<template>
  <Listbox :model-value="selectedStage" as="div" @update:model-value="updateStage">
    <div class="relative inline-block">
      <ListboxButton :class=" [selectedStage.background, selectedStage.color, 'inline-flex items-center py-1 pl-3 pr-2 text-xs md:text-sm font-medium rounded-full']">
        {{ selectedStage.name }}
        <span :class="[selectedStage.color, 'flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center']">
          <span class="sr-only">Open select menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-3.5 h-3.5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      </ListboxButton>
      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div class="absolute right-0 z-10 pb-11">
          <ListboxOptions class="w-56 mt-2 overflow-y-auto text-xs origin-top-right bg-white rounded-md shadow-lg h-36 md:h-auto ring-1 ring-black ring-opacity-5 focus:outline-none md:text-sm">
            <ListboxOption
              v-for="option in stages"
              :key="option.id"
              v-slot="{ active, selected }"
              as="template"
              :value="option"
              :disabled="!option.enabled"
            >
              <li
                :class="[
                  { 'hidden': option.hidden },
                  { 'bg-gray-100': active },
                  { 'opacity-50': !option.enabled },
                  'text-gray-700 cursor-default select-none relative py-2 pl-3 pr-9'
                ]"
              >
                <div class="flex items-center">
                  <span :class="[option.background, 'flex-shrink-0 inline-block h-3 w-3 rounded-full']" aria-hidden="true" />
                  <span :class="[selected ? 'font-medium' : 'font-normal', 'ml-3 block truncate']">
                    {{ option.name }}
                  </span>
                </div>

                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-hartblue">
                  <Icon name="check" class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </div>
      </transition>
    </div>
  </Listbox>
</template>