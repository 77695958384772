import { ref, computed, watch } from 'vue'

export default function useClientFilters(clients) {
  const url = new URL(window.location)

  const filters = ref({
    clientStatus: false,
    type: '',
    carrier: '',
  })

  if (url.searchParams.has('clientStatus') && url.searchParams.has('type') && url.searchParams.has('carrier')) {
    filters.value = {
      clientStatus: url.searchParams.get('clientStatus').toLowerCase() == 'true' ? true : false,
      type: url.searchParams.get('type'),
      carrier: url.searchParams.get('carrier'),
    }
  }

  watch(filters.value, (filter) => {
    url.searchParams.set('clientStatus', filter.clientStatus)
    url.searchParams.set('type', filter.type)
    url.searchParams.set('carrier', filter.carrier)
    window.history.pushState({}, '', url)
  })

  const filteredClients = computed(() => {
    return clients.value.filter((client) => {
      return isActive(client) && isSelectedType(client) && isSelectedCarrie(client)
    }).sort((x, y) => {
      if (`${x.first_name} ${x.last_name}` < `${y.first_name} ${y.last_name}`) return -1
      if (`${x.first_name} ${x.last_name}` > `${y.first_name} ${y.last_name}`) return 1
      return 0
    })
  })

  const isActive = (client) => {
    if (filters.value.clientStatus == false) return true
    return client.active_client == true
  }

  const isSelectedType = (client) => {
    if (filters.value.type == '') {
      return true
    } else {
      return client.plan_type === filters.value.type
    }
  }

  const isSelectedCarrie = (client) => {
    if (filters.value.carrier == '') return true
    return client.company == filters.value.carrier
  }

  return {
    filters,
    filteredClients,
  }
}