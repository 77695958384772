<script setup>
import { toRefs } from 'vue'
import * as routes from '@/utils/routes'
import { Link } from '@inertiajs/inertia-vue3'

const props = defineProps({
  leadId: String,
  path: {
    type: String,
    default: null,
  },
})

const { path } = toRefs(props)

function isActive(_path) {
  return path.value == _path
}
</script>

<template>
  <div class="-mt-3 lg:shadow-sm lg:mt-0">
    <ul class="flex">
      <li class="flex-1 lg:flex-initial lg:mr-1 lg:hidden">
        <Link
          class="tab"
          :class="isActive('show') ? 'active' : 'inactive'"
          :href="routes.lead(leadId)"
        >
          Info
        </Link>
      </li>
      <li class="flex-1 lg:flex-initial lg:mr-1">
        <Link
          class="tab"
          :class="isActive('activity') ? 'active' : 'inactive'"
          :href="routes.activity_lead(leadId)"
        >
          Activity
        </Link>
      </li>
    </ul>
  </div>
</template>