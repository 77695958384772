import { ref } from 'vue'
import dayjs from 'dayjs'
import axios from 'axios'
import * as routes from '@/utils/routes.js'
import { Inertia } from '@inertiajs/inertia'
import { dateHourFormatToCRM, nowFullDate, isSameDate } from '@/utils/dates.js'
import useLeadOrClientInfo from '@/composables/useLeadOrClientInfo.js'
import { notify } from 'notiwind'

export default function useAppointments(leadOrClient) {

  const { fullName, plan } = useLeadOrClientInfo(leadOrClient)

  const openNewAppt = ref(false)
  const openUpdateAppt = ref(false)
  const agentAppointments = ref([])
  const appointmentForm = ref({
    appointment: {
      date: nowFullDate('YYYY-MM-DDTHH:00:00Z'),
      type: '',
      plan_type: '',
      office_location: '',
    },
    errors: [],
    processing: false,
  })

  const createAppointment = () => {
    appointmentForm.value.processing = true
    let appointment = {
      deal_name: `${plan.value} Appointment - ${fullName.value} `,
      contact_name: leadOrClient.value.id,
      type: appointmentForm.value.appointment.type,
      office_location: appointmentForm.value.appointment.office_location,
      plan_type: appointmentForm.value.appointment.plan_type,
      start: dateHourFormatToCRM(appointmentForm.value.appointment.date),
      end: dateHourFormatToCRM(dayjs(appointmentForm.value.appointment.date).add(1, 'hour')),
    }
    axios.post(routes.appointments(), { appointment: appointment })
      .then(() => {
        setTimeout(() => {
          appointmentForm.value.processing = false
          openNewAppt.value = false
          resetApptForm()
        }, 1000)
        Inertia.reload()
      }).catch(() => {
        setTimeout(() => {
          appointmentForm.value.processing = false
          openNewAppt.value = false
          resetApptForm()
        }, 100)
      })
  }

  const updateApointment = () => {
    appointmentForm.value.processing = true
    let appointment = {
      deal_name: appointmentForm.value.appointment.deal_name,
      stage: 'Scheduled',
      type: appointmentForm.value.appointment.type,
      office_location: appointmentForm.value.appointment.office_location,
      start: dateHourFormatToCRM(appointmentForm.value.appointment.date),
      end: dateHourFormatToCRM(dayjs(appointmentForm.value.appointment.date).add(1, 'hour')),
      updated_on_portal: !isSameDate(appointmentForm.value.appointment.date, appointmentForm.value.appointment.current_start),
    }

    axios.put(routes.appointment(appointmentForm.value.appointment.id, { format: 'json' }), { appointment: appointment })
      .then(() => {
        setTimeout(() => {
          appointmentForm.value.processing = false
          openUpdateAppt.value = false
          resetApptForm()
        }, 1000)
        Inertia.reload()
      }).catch(() => {
        setTimeout(() => {
          appointmentForm.value.processing = false
          openUpdateAppt.value = false
          resetApptForm()
        }, 100)
      })
  }

  function middlewareUpdate(appointment) {
    if (appointment.stage == 'Sold' || appointment.stage == 'Lost' ) {
      notify({
        group: 'bottom',
        title: 'Error updating the appointment',
        text: 'You can\'t update an appointment that is already sold or lost',
      }, 3000)
    } else {
      openUpdateAppt.value = true
      const editAppointmentData = {
        ...appointment,
        date: appointment.start,
        current_start: appointment.start,
      }
      appointmentForm.value.appointment = editAppointmentData
    }
  }

  function resetApptForm() {
    appointmentForm.value.appointment.type = ''
    appointmentForm.value.appointment.office_location = ''
  }

  function getAgentAppointments() {
    axios.get(routes.scheduleds_appointments())
      .then((appointments) => {
        agentAppointments.value = appointments.data
      }).catch(console.error)
  }

  return {
    openNewAppt,
    openUpdateAppt,
    appointmentForm,
    agentAppointments,
    createAppointment,
    updateApointment,
    middlewareUpdate,
    resetApptForm,
    getAgentAppointments,
  }
}