import { computed } from 'vue'
import { inCurrentWeek, inCurrentMonth } from '@/utils/dates.js'

export default function useLeadsCard(leads) {

  const leadsAssigned = computed(() => {
    return leads.value.length
  })

  const leadsThisWeek = computed(() => {
    return leads.value.filter(lead => {
      return lead.agent_assigned != null && inCurrentWeek(lead.agent_assigned)
    }).length
  })

  const leadsThisMonth = computed(() => {
    return leads.value.filter(lead => {
      return lead.agent_assigned != null && inCurrentMonth(lead.agent_assigned)
    }).length
  })

  return {
    leadsAssigned,
    leadsThisWeek,
    leadsThisMonth,
  }
}