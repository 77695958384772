<script setup>
import { ref, watch, toRefs } from 'vue'
import Icon from '@/Shared/Icon.vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'

const props = defineProps({
  status: String,
})

const emit = defineEmits(['change'])

const statuses = ref([
  {
    name: 'Open',
    enabled: true,
    background: 'bg-blue-100',
    color: 'text-blue-700',
    enables: ['Completed'],
  },
  {
    name: 'Completed',
    enabled: true,
    background: 'bg-green-100',
    color: 'text-green-700',
    enables: ['Open'],
  },
])

const { status } = toRefs(props)

const selectedObject = ref(null)
const selectedStatus = ref(null)

watch(status, () => {
  selectedObject.value = statuses.value.find((state) => state.name === status.value)
  selectedStatus.value = selectedObject.value
}, {
  immediate: true,
})

function updateStatus(value) {
  emit('change', value)
}
</script>

<template>
  <Listbox :model-value="selectedStatus" as="div" @update:model-value="updateStatus">
    <div class="relative inline-block">
      <ListboxButton :class=" [selectedStatus.background, selectedStatus.color, 'inline-flex items-center py-1 pl-3 pr-2 text-xs md:text-sm focus:outline-none font-medium rounded-full']">
        {{ selectedStatus.name }}
        <span :class="[selectedStatus.color, 'flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center']">
          <span class="sr-only">Open select menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-3.5 h-3.5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      </ListboxButton>
      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute right-0 z-10 w-40 mt-2 text-xs origin-top-right bg-white rounded-md shadow-lg md:w-56 md:text-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ListboxOption
            v-for="option in statuses"
            :key="option.id"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
            :disabled="!option.enabled"
          >
            <li
              :class="[
                { 'bg-gray-100': active },
                { 'opacity-50': !option.enabled },
                'text-gray-700 cursor-default select-none relative py-2 pl-3 pr-9'
              ]"
            >
              <div class="flex items-center">
                <span :class="[option.background, 'flex-shrink-0 inline-block h-3 w-3 rounded-full']" aria-hidden="true" />
                <span :class="[selected ? 'font-medium' : 'font-normal', 'ml-3 block truncate']">
                  {{ option.name }}
                </span>
              </div>

              <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-hartblue">
                <Icon name="check" class="w-5 h-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>