<script setup>
import { toRefs } from 'vue'
import Icon from '@/Shared/Icon.vue'
import NewAppointment from './NewAppointment.vue'
import UpdateAppointment from './NewAppointment.vue'
import useAppointments from '@/composables/useAppointments.js'
import AppointmentListItem from './AppointmentListItem.vue'

import SoldAppointmentModal from '@/Shared/SoldAppointmentModal.vue'
import LostAppointmentModal from '@/Shared/LostAppointmentModal.vue'
import OngoingAppointmentModal from '@/Shared/OngoingAppointmentModal.vue'
import useAppointmentStageUpdater from '@/composables/useAppointmentStageUpdater.js'
import Pagination from '@/Shared/Pagination.vue'
import usePagination from '@/composables/usePagination.js'

const props = defineProps({
  client: Object,
  appointments: Array,
  products: Array,
  lostReason: Array,
  newRenewal: Array,
  sourceOfFunds: Array,
  policyTerms: Array,
})

const { client, appointments } = toRefs(props)

const {
  openNewAppt,
  openUpdateAppt,
  appointmentForm,
  agentAppointments,
  resetApptForm,
  createAppointment,
  updateApointment,
  middlewareUpdate,
  getAgentAppointments,
} = useAppointments(client)

const {
  openSoldModal,
  openLostModal,
  openOngoingModal,
  lostForm,
  ongoingForm,
  soldForm,
  submitLostForm,
  submitOngoingForm,
  submitSoldForm,
  openModal,
} = useAppointmentStageUpdater(null)

const {
  paginatedData,
  paginator,
} = usePagination(appointments, { numButtons: 3, pageSize: 3 })

getAgentAppointments()
</script>

<template>
  <div id="appointments" class="lg:pt-4">
    <div class="flex flex-row items-center">
      <div class="w-1/2">
        <h2 class="pt-1 pb-2 text-xl font-bold text-gray-900">
          Appointments
        </h2>
      </div>
      <div class="flex justify-end w-1/2 lg:hidden">
        <button
          class="flex justify-end px-4 py-2 m-2 text-xs text-center text-gray-900 bg-hartgreen lg:hidden button-rounded"
          @click="openNewAppt = true"
        >
          New Appt
          <Icon
            name="calendar"
            class="w-4 h-4 mx-1 fill-current"
          />
        </button>
      </div>
    </div>

    <AppointmentListItem
      v-for="appointment in paginatedData"
      :key="appointment.id"
      :appointment="appointment"
      @open-edit-modal="middlewareUpdate(appointment)"
      @open-modal="openModal"
    />
    <Pagination v-if="appointments.length > 4" :paginator="paginator" />
    <div v-if="paginatedData.length == 0" class="px-2 py-2 mt-2 text-sm text-gray-800 bg-gray-100 rounded-md lg:py-4">
      No appointments have been created
    </div>

    <UpdateAppointment
      v-model="appointmentForm"
      :open="openUpdateAppt"
      @submit="updateApointment"
      @close="openUpdateAppt = false"
    />

    <NewAppointment
      v-model="appointmentForm"
      :open="openNewAppt"
      :appointments="agentAppointments"
      @submit="createAppointment"
      @close="() => { openNewAppt = false; resetApptForm() }"
    />

    <SoldAppointmentModal
      v-model="soldForm"
      :open="openSoldModal"
      :products="products"
      :new-renewal="newRenewal"
      :policy-terms="policyTerms"
      :source-of-funds="sourceOfFunds"
      @close="openSoldModal=false"
      @submit="submitSoldForm"
    />

    <LostAppointmentModal
      v-model="lostForm"
      :open="openLostModal"
      :lost-reason="lostReason"
      @close="openLostModal=false"
      @submit="submitLostForm"
    />

    <OngoingAppointmentModal
      v-model="ongoingForm"
      :open="openOngoingModal"
      :lost-reason="lostReason"
      @close="openOngoingModal=false"
      @submit="submitOngoingForm"
    />
  </div>
</template>