import { ref, computed } from 'vue'
export default function useClientSearch(clients) {
  const searchQuery = ref('')

  const clientsMatchingSearchQuery = computed(() => {
    return clients.value.filter(client => {

      return Object.keys(client).some(key => {
        return String(client[key])
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase())
      },
      )
    })
  })
  return {
    searchQuery,
    clientsMatchingSearchQuery,
  }
}