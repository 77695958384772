<script setup>
import Icon from '@/Shared/Icon.vue'

defineProps({
  dropdown: Boolean,
  status: String,
})

const type = {
  null: {
    display: 'None',
    btnClass: 'text-gray-700 bg-gray-200',
    dropdownClass: 'text-gray-600',
  },
  'Open': {
    display: 'Open',
    btnClass: 'text-blue-700 bg-blue-200',
    dropdownClass: 'text-blue-600',
  },
  'Qualified - Now': {
    display: 'Qualified - Now',
    btnClass: 'text-blue-700 bg-blue-200',
    dropdownClass: 'text-blue-600',
  },
  'New': {
    display: 'New',
    btnClass: 'text-green-700 bg-green-200',
    dropdownClass: 'text-green-600',
  },
  'Completed': {
    display: 'Completed',
    btnClass: 'text-green-700 bg-green-200',
    dropdownClass: 'text-green-600',
  },
  'Confirmed': {
    display: 'Confirmed',
    btnClass: 'text-indigo-700 bg-indigo-100',
    dropdownClass: 'text-indigo-600',
  },
  'Qualified - Later': {
    display: 'Qualified - Later',
    btnClass: 'text-indigo-700 bg-indigo-100',
    dropdownClass: 'text-indigo-600',
  },
  'Active': {
    display: 'Active',
    btnClass: 'text-green-700 bg-green-200',
    dropdownClass: 'text-green-600',
  },
  'Inactive': {
    display: 'Inactive',
    btnClass: 'text-gray-700 bg-gray-200',
    dropdownClass: 'text-gray-600',
  },
  'Bad Lead': {
    display: 'Bad Lead',
    btnClass: 'text-gray-700 bg-gray-200',
    dropdownClass: 'text-gray-600',
  },
  'Need to Schedule': {
    display: 'Need to Schedule',
    btnClass: 'bg-orange-100 text-orange-700',
    dropdownClass: 'text-orange-700',
  },
  'Scheduled': {
    display: 'Scheduled',
    btnClass: 'bg-teal-100 text-teal-700',
    dropdownClass: 'text-teal-600',
  },
  'Started': {
    display: 'Started',
    btnClass: 'bg-yellow-100 text-yellow-700',
    dropdownClass: 'text-yellow-600',
  },
  'Unable to Contact': {
    display: 'Unable to Contact',
    btnClass: 'bg-yellow-100 text-yellow-700',
    dropdownClass: 'text-yellow-600',
  },
  'Requested Callback': {
    display: 'Requested Callback',
    btnClass: 'bg-yellow-100 text-orange-700',
    dropdownClass: 'text-orange-600',
  },
  'Sold': {
    display: 'Sold',
    btnClass: 'bg-emerald-100 text-emerald-700',
    dropdownClass: 'text-emerald-600',
  },
  'Lost': {
    display: 'Lost',
    btnClass: 'bg-red-100 text-red-700',
    dropdownClass: 'text-red-600',
  },
  'NQ': {
    display: 'NQ',
    btnClass: 'bg-red-100 text-red-700',
    dropdownClass: 'text-red-600',
  },
  'Updating': {
    display: 'Updating',
    btnClass: 'bg-yellow-100 text-yellow-700',
    dropdownClass: 'text-yellow-600',
  },
}
</script>

<template>
  <button type="button" :class="type[status]?.btnClass ? type[status].btnClass : type[null].btnClass" class="flex items-center px-3 py-1 text-xs font-medium lg:text-sm button-rounded">
    {{ type[status]?.display ? type[status].display : status }}
    <Icon
      v-if="dropdown"
      name="chevron"
      :class="type[status]?.dropdownClass ? type[status].dropdownClass : type[null].dropdownClass"
      class="w-3 pl-1 fill-current"
    />
  </button>
</template>