<script setup>
import Icon from '@/Shared/Icon.vue'

const props = defineProps({
  paginator: Object,
})

// eslint-disable-next-line vue/no-setup-props-destructure
const {
  page,
  buttons,
  hasPrev,
  hasNext,
  goPrev,
  goNext,
} = props.paginator
</script>

<template>
  <nav class="flex items-center justify-between px-4 pb-4 bg-white border-t border-gray-200">
    <div class="flex flex-1 w-0 -mt-px">
      <button
        :disabled="!hasPrev"
        class="inline-flex items-center pt-4 pr-1 font-medium text-gray-600 border-t-2 border-transparent text-xsm md:text-sm disabled:hover:border-transparent disabled:text-gray-400 hover:text-gray-700 hover:border-gray-300"
        @click="goPrev()"
      >
        <Icon name="arrow-left" class="w-5 h-5 mr-3 fill-current" aria-hidden="true" />
        Previous
      </button>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <button
        v-for="(button, idx) in buttons"
        :key="idx"
        :class="[
          button.active ? 'border-indigo-500 text-indigo-600' : 'text-gray-600 border-transparent hover:text-gray-700 hover:border-gray-300',
          'inline-flex items-center px-4 pt-4 text-xsm md:text-sm font-medium border-t-2'
        ]"
        @click="page = button.page"
      >
        {{ button.ellipsis ? '...' : button.page }}
      </button>
    </div>
    <div class="flex justify-end flex-1 w-0 -mt-px">
      <button
        :disabled="!hasNext"
        class="inline-flex items-center pt-4 pr-1 font-medium text-gray-600 border-t-2 border-transparent text-xsm md:text-sm disabled:hover:border-transparent disabled:text-gray-400 hover:text-gray-700 hover:border-gray-300"
        @click="goNext()"
      >
        Next
        <Icon name="arrow-right" class="w-5 h-5 ml-3 fill-current" aria-hidden="true" />
      </button>
    </div>
  </nav>
</template>