<script setup>
import * as routes from '@/utils/routes'
import { useForm } from '@inertiajs/inertia-vue3'

const form = useForm({
  email: null,
})

function onFormSubmit() {
  form
    .transform((data) => ({
      user: data,
    }))
    .post(routes.confirmations())
}
</script>

<template>
  <div class="max-w-4xl p-8 mx-auto bg-gray-100 rounded-md">
    <h1>Confirm Email</h1>
    <form class="space-y-4" @submit.prevent="onFormSubmit">
      <div class="flex flex-col">
        <label class="block mb-1 text-sm font-medium text-gray-700">Email</label>
        <input
          v-model="form.email"
          type="text"
          class="leading-relaxed form-underline"
          placeholder="john@example.com"
          required
        >
        <div v-if="form.errors.email">
          {{ form.errors.email }}
        </div>
      </div>

      <button type="submit" class="w-full px-8 py-3 text-lg button-rounded button-primary" :disabled="form.processing">
        Confirm Email
      </button>
    </form>
  </div>
</template>