<script setup>
import axios from 'axios'
import { computed } from 'vue'
import * as routes from '@/utils/routes'
import { useForm, usePage } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo.vue'

const flash = computed(() => usePage().props.value.flash)

const form = useForm({
  code: null,
})

async function resendCode() {
  try {
    const response = await axios.post(routes.verify({ format: 'json' }))
    if (response.status === 200) {
      flash.value.success = 'We sent another code.'
    }
  } catch (error) {
    console.log(error)
  }
}

function onFormSubmit() {
  form.put(routes.verify())
}
</script>

<template>
  <div class="flex h-screen">
    <div class="flex flex-col justify-center w-full max-w-md px-3 mx-auto sm:px-8 lg:px-16 sm:max-w-xl lg:w-1/2">
      <img
        src="@/images/logo.png"
        alt="Hartland Logo"
        class="block h-24 mx-auto lg:h-32 xl:h-32"
      >

      <form class="px-4 sm:px-0 lg:py-20" @submit.prevent="onFormSubmit">
        <div class="w-full h-8 mb-6">
          <div v-if="flash.alert" class="px-2 py-2 bg-red-100 rounded-md">
            <span class="block text-sm font-medium text-red-700">
              {{ flash.alert }}
            </span>
          </div>
          <div v-else-if="flash.success" class="px-2 py-2 bg-green-100 rounded-md">
            <span class="block text-sm font-medium text-green-800">
              {{ flash.success }}
            </span>
          </div>
        </div>

        <div class="flex flex-col">
          <h1 class="pb-2 text-2xl font-semibold text-gray-900">
            Enter your verification code
          </h1>
          <label class="block pb-6 mb-1 text-sm font-medium text-gray-700">
            Input the code we sent to your phone number to access your account.
          </label>
          <div class="flex items-center space-x-4">
            <input
              v-model="form.code"
              v-maska="'######'"
              inputmode="numeric"
              autocomplete="one-time-code"
              type="text"
              class="leading-relaxed form-underline"
              required
            >
            <button type="button" class="flex self-stretch items-center whitespace-nowrap px-2.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500" @click.prevent="resendCode">
              Resend code
            </button>
          </div>
        </div>

        <button type="submit" class="w-full px-8 py-3 mt-10 text-lg button-rounded button-primary" :disabled="form.processing">
          Verify
        </button>
      </form>

      <div class="flex items-center pt-8 mx-auto lg:pt-0">
        <p class="font-semibold text-gray-500">
          By
        </p>
        <Logo
          version="iq-gray"
          class="block h-4 ml-1"
        />
      </div>
    </div>

    <div class="items-center hidden w-1/2 lg:flex bg-pureblue">
      <img
        class="object-cover object-left w-full h-full ml-auto"
        src="@/images/bg.png"
        alt="Background"
      >
    </div>
  </div>
</template>
