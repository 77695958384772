import { ref, onMounted, nextTick } from 'vue'
import { Inertia } from '@inertiajs/inertia'
import { useForm } from '@inertiajs/inertia-vue3'
import * as routes from '@/utils/routes'
import { omit } from '~/utils/helpers'

export default function useUserForm(user, openModal) {
  const open = ref(false)

  const form = useForm({
    user: persisted() ? omit(user.value, ['id']) : {},
  })

  onMounted(async () => {
    await nextTick()
    open.value = openModal.value
  })

  function onSubmit() {
    const [method, route] = persisted()
      ? ['put', routes.user(user.value.id)]
      : ['post', routes.users()]

    form.submit(method, route, {
      onSuccess: () => open.value = false,
    })
  }

  function closeForm() {
    open.value = false
    setTimeout(() => {
      Inertia.visit(routes.users())
    }, 300)
  }

  function persisted() {
    return user.value?.id
  }

  return {
    open,
    form,
    onSubmit,
    closeForm,
  }
}