<script setup>
import Modal from '@/Shared/Modal.vue'
import { useForm } from '@inertiajs/inertia-vue3'
import * as routes from '@/utils/routes'
defineProps({
  open: {
    type: Boolean,
    required: true,
  },
})
const emit = defineEmits(['close'])
const form = useForm({
  user: {
    email: null,
  },
})
function requestPasswordReset() {
  form.post(routes.passwords())
  emit('close')
}
</script>

<template>
  <Modal :open="open" @close="emit('close')">
    <form @submit.prevent="requestPasswordReset()">
      <div class="flex flex-col items-center">
        <h1 class="py-8 text-2xl font-bold text-gray-900">
          Forgot Password?
        </h1>
        <p class="px-4 text-xl font-light text-center">
          Enter your email below to receive your password reset instructions
        </p>
        <div class="flex flex-col w-full px-4 py-8 lg:px-0">
          <label class="block mb-1 text-sm font-medium text-gray-700">Email</label>
          <input
            v-model="form.user.email"
            type="email"
            class="form-underline"
            placeholder="john@example.com"
            required
          >
        </div>
        <div class="flex flex-col-reverse w-full px-4 pt-2 pb-10 lg:flex-row lg:px-0">
          <button
            type="button"
            class="w-full px-6 py-2 text-sm border-2 button-rounded button-outline"
            @click="emit('close')"
          >
            Cancel
          </button>
          <button type="submit" class="w-full px-6 py-2 mb-3 text-sm border-2 border-hartblue lg:ml-3 lg:mb-0 button-rounded button-primary">
            Request Password Reset
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>