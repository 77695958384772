<script setup>
import { computed } from 'vue'
import { phone } from '@/utils/filters'
import Icon from '@/Shared/Icon.vue'
import { DatePicker } from 'v-calendar'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import 'v-calendar/dist/style.css'

const props = defineProps({
  appointment: {
    type: Object,
  },
  open: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: Object,
  },
})

const types = [
  'Medicare',
  'ACA',
]

const locations = [
  'Office',
  'Home',
  'Phone',
  'Zoom',
]

const offices = [
  { id: 'Berwyn', name: 'Berwyn' },
  { id: 'Woodridge', name: 'Woodridge' },
  { id: 'Lawrence', name: 'Lawrence' },
  { id: 'Las Vegas', name: 'Las Vegas' },
  { id: 'Phoenix', name: 'Phoenix' },
  { id: 'Temporary', name: 'Temporary' },
]

const emit = defineEmits(['close', 'update:modelValue', 'submit'])

const form = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 z-10 overflow-hidden" @close="emit('close')">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="absolute inset-0 transition-opacity bg-gray-400 bg-opacity-50" />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md pointer-events-auto">
              <form class="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl" @submit.prevent="emit('submit')">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="px-4 py-6 bg-hartblue sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        Schedule Appointment
                      </DialogTitle>
                      <div class="flex items-center ml-3 h-7">
                        <button type="button" class="text-indigo-200 rounded-md bg-hartblue hover:text-white focus:outline-none" @click="emit('close')">
                          <span class="sr-only">Close panel</span>
                          <Icon name="close" class="w-5 h-5 fill-current" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div>
                      <p class="text-sm text-indigo-200">
                        Agent: {{ form.agent }}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-col justify-between flex-1">
                    <div v-if="Object.keys(form.errors).length > 0" class="p-4 rounded-md bg-red-50">
                      <div class="flex">
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">
                            There were errors with your submission
                          </h3>
                          <div class="mt-2 text-sm text-red-700">
                            <ul role="list" class="pl-5 space-y-1 list-disc">
                              <li v-for="error in form.errors" :key="error">
                                {{ error }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="p-4 sm:px-6">
                      <div class="p-4 bg-gray-100 rounded">
                        <h3 class="pb-1.5 font-semibold leading-4 text-gray-800 border-b border-gray-300">
                          Contact Person
                        </h3>
                        <div class="mt-2 space-y-1">
                          <p class="text-sm font-medium text-gray-600">
                            {{ appointment.contact_first_name }} {{ appointment.contact_last_name }}
                          </p>
                          <p class="text-sm font-medium text-gray-600">
                            {{ phone(appointment.contact_mobile_phone) }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="pt-2 pb-5 space-y-6">
                        <div>
                          <label for="start" class="block text-sm font-medium text-gray-900"> Start date </label>
                          <div class="mt-1">
                            <DatePicker v-model="form.start" :popover="{ visibility: 'focus' }" mode="dateTime">
                              <template #default="{ inputValue, inputEvents }">
                                <input
                                  type="text"
                                  class="block w-full border-gray-300 rounded-md shadow-sm disabled:text-gray-700 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  :value="inputValue"
                                  disabled
                                  v-on="inputEvents"
                                >
                              </template>
                            </DatePicker>
                          </div>
                        </div>

                        <div>
                          <label for="end" class="block text-sm font-medium text-gray-900"> End date </label>
                          <div class="mt-1">
                            <DatePicker v-model="form.end" :popover="{ visibility: 'focus' }" mode="dateTime">
                              <template #default="{ inputValue, inputEvents }">
                                <input
                                  type="text"
                                  class="block w-full border-gray-300 rounded-md shadow-sm disabled:text-gray-700 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  :value="inputValue"
                                  disabled
                                  v-on="inputEvents"
                                >
                              </template>
                            </DatePicker>
                          </div>
                        </div>

                        <div>
                          <label for="type" class="block text-sm font-medium text-gray-900"> Type </label>
                          <div class="mt-1">
                            <select
                              id="type"
                              v-model="form.type"
                              class="block w-full border-gray-300 rounded-md shadow-sm invalid:text-gray-600 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              required
                              disabled
                            >
                              <option value="" selected>
                                Select an appointment type
                              </option>
                              <option v-for="typeAppt in types" :key="typeAppt" :value="typeAppt">
                                {{ typeAppt }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div>
                          <label for="location" class="block text-sm font-medium text-gray-900"> Location </label>
                          <div class="mt-1">
                            <select
                              id="location"
                              v-model="form.location"
                              class="block w-full border-gray-300 rounded-md shadow-sm invalid:text-gray-600 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              required
                              disabled
                            >
                              <option value="" selected>
                                Select a location
                              </option>
                              <option
                                v-for="location in locations"
                                :key="location"
                                :value="location"
                              >
                                {{ location }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div v-if="form.location == 'Office'">
                          <label for="office-location" class="block text-sm font-medium text-gray-900"> Office Location </label>
                          <div class="mt-1">
                            <select
                              id="office-location"
                              v-model="form.office_location"
                              class="block w-full border-gray-300 rounded-md shadow-sm invalid:text-gray-600 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              required
                              disabled
                            >
                              <option value="" selected>
                                Select an office location
                              </option>
                              <option
                                v-for="office in offices"
                                :key="office.id"
                                :value="office.name"
                              >
                                {{ office.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end flex-shrink-0 px-4 py-4">
                  <button type="button" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="emit('close')">
                    Cancel
                  </button>
                  <button type="submit" class="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-hartblue hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Schedule Appointment
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>