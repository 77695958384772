import { ref, computed, watch } from 'vue'
import { inLastWeek, inCurrentWeek, inNextWeek, inLastMonth, inCurrentMonth, inUpcomingDays, inLastThirtyDays, inCurrentYear, inCurrentDay } from '@/utils/dates'

export default function useLeadFilters(leads) {
  const url = new URL(window.location)

  const filters = ref({
    type: '',
    leadStatus: '',
    assigned: '',
  })

  if (url.searchParams.has('leadStatus') && url.searchParams.has('type') && url.searchParams.has('assigned')) {
    filters.value = {
      type: url.searchParams.get('type'),
      leadStatus: url.searchParams.get('leadStatus').toLowerCase(),
      assigned: url.searchParams.get('assigned'),
    }
  }

  watch(filters.value, (filter) => {
    url.searchParams.set('type', filter.type)
    url.searchParams.set('assigned', filter.assigned)
    url.searchParams.set('leadStatus', filter.leadStatus)
    window.history.pushState({}, '', url)
  })

  const filteredLeads = computed(() => {
    return leads.value.filter((lead) => {
      return isSelectedType(lead) && isInDateRange(lead) && isSelectedStatus(lead)
    }).sort((x, y) => {
      if (`${x.first_name} ${x.last_name}` < `${y.first_name} ${y.last_name}`) return -1
      if (`${x.first_name} ${x.last_name}` > `${y.first_name} ${y.last_name}`) return 1
      return 0
    })
  })

  const isSelectedType = (lead) => {
    if (filters.value.type == '') {
      return true
    } else {
      return lead.plan_type === filters.value.type
    }
  }

  const isSelectedStatus = (lead) => {
    return filters.value.leadStatus == '' ? true : lead.lead_status_agent.toLowerCase() === filters.value.leadStatus.toLowerCase()
  }

  const isInDateRange = (lead) => {
    const start = lead.agent_assigned

    switch (filters.value.assigned) {
    case 'today':
      return inCurrentDay(start)
    case 'upcomming':
      return inUpcomingDays(start)
    case 'this-week':
      return inCurrentWeek(start)
    case 'last-week':
      return inLastWeek(start)
    case 'next-week':
      return inNextWeek(start)
    case 'this-month':
      return inCurrentMonth(start)
    case 'last-month':
      return inLastMonth(start)
    case 'last-thirty-days':
      return inLastThirtyDays(start)
    case 'this-year':
      return inCurrentYear(start)
    default:
      return true
    }
  }

  return {
    filters,
    filteredLeads,
  }
}