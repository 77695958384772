import axios from 'axios'
import * as routes from '@/utils/routes'

export default function usePhoneVerification() {

  const verifyCallerId = async () => {
    const { data } = await axios.get(routes.my_phone_verifications({ format: 'json' }))
    return data
  }

  return {
    verifyCallerId,
  }
}