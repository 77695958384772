<script setup>
import { ref, computed } from 'vue'
import { mask } from 'maska'

const props = defineProps({
  id: {
    type: String,
    default() {
      return `text-input-${Math.random()}`
    },
  },
  modelValue: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
})

const realValue = ref()
const fakeValue = ref()

const maskPattern = ref('###-##-####')

const displayValue = ref()

const ssn = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

function checkSSNInitialValue() {
  if (ssn.value != null) {
    let newValue = ssn.value.split('-').join('')
    displayValue.value = newValue
    realValue.value = newValue
    hideValue()
  }
}

function hideValue() {
  if (displayValue.value == '' || displayValue.value == undefined) return
  fakeValue.value = `***-**-${displayValue.value.slice(-4)}`
  displayValue.value = fakeValue.value
}

function showValue() {
  if (realValue.value == '' || realValue.value == undefined) return
  displayValue.value = mask(realValue.value, maskPattern.value)
}

function cleanValue(value) {
  realValue.value = value
  ssn.value = realValue.value
}

const emit = defineEmits(['update:modelValue'])
setTimeout(checkSSNInitialValue, 800)
</script>

<template>
  <div>
    <label v-if="label" class="block text-sm font-medium text-gray-700" :for="id">
      {{ label }}
    </label>
    <div class="mt-1">
      <input type="hidden" :value="ssn">
      <input
        :id="id"
        v-model="displayValue"
        v-maska="maskPattern"
        class="col-span-4 form-underline"
        type="text"
        maxlength="11"
        v-bind="$attrs"
        @input="cleanValue($event.target.value)"
        @focusout="hideValue()"
        @focusin="showValue()"
      >
    </div>
    <div
      v-if="errors.length"
      class="mt-2 text-xs text-red-600"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>