<script setup>
import { toRefs, computed } from 'vue'
import { formatPhone } from '@/utils/helpers'
import PhoneVerification from './PhoneVerification.vue'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const { user } = toRefs(props)

const abbrName = computed(() => {
  let splitedName = user.value.name.split(' ')
  return `${splitedName[0][0]}${splitedName[splitedName.length - 1][0]}`
})
</script>

<template>
  <div>
    <div class="container pt-5 text-center lg:pb-8">
      <div class="flex items-center justify-center w-20 h-20 m-auto bg-gray-300 rounded-full lg:h-25 lg:w-25">
        <span class="text-5xl font-black text-white">{{ abbrName }}</span>
      </div>
      <h2 class="block p-4 text-xl font-bold text-gray-900 lg:text-2xl">
        {{ user.name }}
      </h2>
    </div>
    <div class="pt-6 lg:pt-4">
      <h5 class="py-1 font-semibold text-gray-900">
        General
      </h5>
      <div class="flex py-3 border-b">
        <div class="block w-24">
          <span class="text-xs text-gray-700">Full Name:</span>
        </div>
        <div class="flex-1 block">
          <p class="text-sm font-medium text-right lg:text-left">
            {{ user.name }}
          </p>
        </div>
      </div>
      <div class="flex py-3 border-b">
        <div class="block w-24">
          <span class="text-xs text-gray-700">Email:</span>
        </div>
        <div class="flex-1 block">
          <p class="text-sm font-medium text-right lg:text-left text-hartblue">
            {{ user.email }}
          </p>
        </div>
      </div>
      <div class="flex py-3 border-b">
        <div class="block w-24">
          <span class="text-xs text-gray-700">Mobile Phone:</span>
        </div>
        <div class="flex flex-1 items-center justify-end lg:justify-start space-x-1">
          <span class="text-sm font-medium text-right lg:text-left text-hartblue">
            {{ formatPhone(user.mobilePhone) }} 
          </span>
          <div class="block lg:hidden">
            <PhoneVerification :user="user" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
