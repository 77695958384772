<script setup>
import axios from 'axios'
import { toRefs, computed } from 'vue'
import { formatDate } from '@/utils/dates'
import StatusTaskBadge from '@/Shared/StatusTaskBadge.vue'
import * as routes from '@/utils/routes.js'
import { Inertia } from '@inertiajs/inertia'
import Icon from '@/Shared/Icon.vue'
const props = defineProps({
  task: Object,
})

const { task } = toRefs(props)

const isNotOwner = computed(() => {
  return task.value.owner_email != 'jneira@gohartland.com'
})

const updateStatusTask = (status) => {
  task.value.status = status.name
  axios.put(
    routes.task_statuses(task.value.id, { format: 'json' }),
    { status: status.name })
    .then(() => {
      Inertia.reload()
    })
}

</script>

<template>
  <div class="flex items-center py-3 border-b flex-wraps lg:py-4">
    <div class="w-2/3 lg:w-1/6 sm:px-3">
      <p class="leading-tight text-gray-700 lg:text-gray-900 lg:leading-normal lg:inline-block">
        <span class="lg:pl-[6px] text-xs lg:text-sm">
          {{ formatDate(task.due_date, 'MM/DD/YYYY') }}
        </span>
      </p>
      <span class="block mt-1 text-sm leading-4 text-gray-900 lg:hidden">{{ task.subject }}</span>
      <div v-if="isNotOwner" class="flex mt-1 text-sm leading-4 text-gray-900 lg:hidden">
        <span class="px-3 py-1 text-blue-700 bg-blue-100 rounded-full">
          {{ task.owner_first_name }} {{ task.owner_last_name }}
        </span>
      </div>
      <div v-if="isNotOwner" class="items-center hidden text-xs text-gray-900 lg:flex min-w-fit">
        <Icon
          name="users-alt"
          class="w-6 h-6 p-1 ml-1 rounded-full fill-current lg:block bg-blue-50 text-hartblue"
        />
        <span class="ml-2">{{ task.owner_first_name }} {{ task.owner_last_name }}</span>
      </div>
    </div>
    <div class="hidden w-1/3 lg:block lg:w-1/3 sm:pr-3">
      <span class="text-sm text-gray-800">{{ task.subject }}</span>
    </div>
    <div class="hidden lg:block lg:w-1/3 sm:px-3 lg:pr-10">
      <span
        class="block text-xs text-gray-900"
      >
        {{ task.description }}
      </span>
    </div>
    <div class="flex justify-end w-1/3 pr-4 lg:pr-0 lg:w-1/6 sm:pr-3 lg:justify-start">
      <StatusTaskBadge :status="task.status" @change="updateStatusTask" />
    </div>
  </div>
</template>