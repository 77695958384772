<script setup>
import { toRefs } from 'vue'
import * as routes from '@/utils/routes'
import { useForm } from '@inertiajs/inertia-vue3'
import { omit } from '~/utils/helpers'

import useLeadOrClientInfo from '@/composables/useLeadOrClientInfo'

const props = defineProps({
  lead: Object
})

const { lead } = toRefs(props)

const form = useForm({
  lead: omit(lead.value, ['type']),
})

const { fullName } = useLeadOrClientInfo(lead)

const unArchiveLead = () => {
  if (confirm(`Unarchive ${fullName.value}?`)) {
    form.lead.lead_status_agent = "New"
    form.lead.agent_archived = false
    form.put(routes.lead(form.lead.id))
  }
}
</script>

<template>
  <div class="w-[calc(100%_-_20px)] h-[calc(100%_-_20px)] bg-white bg-opacity-75 absolute flex justify-center items-center flex-col z-20 gap-y-3">
    <h2 class="font-semibold">This lead is archived.</h2>
    <button class="mx-auto block px-6 py-2 text-sm text-center border-2 border-hartblue button-rounded button-primary" @click="unArchiveLead()">
      Unarchive
    </button>
  </div>
</template>
