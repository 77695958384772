import { useForm } from '@inertiajs/inertia-vue3'
import * as routes from '@/utils/routes.js'

export default function useSearches() {
  const url = new URL(window.location)

  const form = useForm({
    q: '',
  })

  if (url.searchParams.has('q')) {
    form.q = url.searchParams.get('q')
  }

  const submitForm = () => {
    form.get(routes.search())
  }

  return {
    form,
    submitForm,
  }
}