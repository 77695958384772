import { computed, watch } from 'vue'
import usePaginator from 'vue-use-paginator'

export default function usePagination(data, opts = { numButtons: 5, pageSize: 5 }) {

  const selectOpts = [
    {
      label: '5 per page',
      value: 5,
    },
    {
      label: '10 per page',
      value: 10,
    },
    {
      label: '25 per page',
      value: 25,
    },
    {
      label: '50 per page',
      value: 50,
    },
    {
      label: '100 per page',
      value: 100,
    },
  ]

  const paginator = usePaginator({
    ...opts,
    numItems: data.value.length,
  })

  watch(data, (value) => {
    paginator.numItems.value = value.length
  })

  const paginatedData = computed(() => {
    return data.value.slice(paginator.slice.value[0], paginator.slice.value[1])
  })

  return {
    selectOpts,
    paginatedData,
    paginator,
  }
}