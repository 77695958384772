import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(isToday)

window.dayjs = dayjs

const timeZone = 'America/Chicago'

const timezones = [
  { label: 'Local', value: 'local' },
  { label: 'Chicago', value: 'America/Chicago' },
  { label: 'Las Vegas', value: 'America/Los_Angeles' },
  { label: 'Arizona', value: 'America/Phoenix' },
  { label: 'Florida', value: 'America/New_York' },
]

// Dayjs format https://day.js.org/docs/en/display/format
const formatDate = (date, format = 'MM/DD/YYYY hh:mm A', timeZone = null) => {
  if (!date) return '--'
  if (timeZone) return dayjs(date).tz(timeZone).format(format)
  return dayjs(date)
    .format(format)
}

const formatDateOnly = (date) => {
  if (!date) return '--'
  return dayjs(date)
    .format('MM/DD/YYYY')
}
const formatHourOnly = (date) => {
  if (!date) return '--'
  return dayjs(date)
    .format('hh:mm A')
}

const lastWeek = dayjs().subtract(1, 'week')
const nextWeek = dayjs().add(1, 'week')
const lastMonth = dayjs().subtract(1, 'month')
const nextMonth = dayjs().add(1, 'month')
const lastYear = dayjs().subtract(1, 'year')
const lastThirtyDays = dayjs().subtract(30, 'days')

const inCurrentDay = (date) => {
  if (!date) return false
  return dayjs(date).isToday()
}

const inLastWeek = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(
    lastWeek.startOf('week'),
    lastWeek.endOf('week'),
  )
}

const inCurrentWeek = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(
    dayjs().startOf('week'),
    dayjs().endOf('week'),
  )
}

const inNextWeek = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(
    nextWeek.startOf('week'),
    nextWeek.endOf('week'),
  )
}

const inLastMonth = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(
    lastMonth.startOf('month'),
    lastMonth.endOf('month'),
  )
}

const inCurrentMonth = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  )
}

const inNextMonth = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(
    nextMonth.startOf('month'),
    nextMonth.endOf('month'),
  )
}

const inCurrentYear = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(
    dayjs().startOf('year'),
    dayjs().endOf('year'),
  )
}

const inLastYear = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(
    lastYear.startOf('year'),
    lastYear.endOf('year'),
  )
}

const inUpcomingDays = (date) => {
  if (!date) return false
  return dayjs(date).isAfter(dayjs())
}

const inLastThirtyDays = (date) => {
  if (!date) return false
  return dayjs(date).isBetween(lastThirtyDays, dayjs())
}

const dateFormatToCRM = (date) => {
  return dayjs(date)
    .format('YYYY-MM-DD')
}

const mountDayYearFormat = (date) => {
  if (!date) return '--'
  return dayjs(date)
    .format('MMM DD, YYYY')
}

const dateHourFormatToCRM = (date) => {
  return dayjs(date)
    .format('YYYY-MM-DDTHH:mm:ssZ')
}

const nowFullDate = (format = 'YYYY-MM-DDTHH:mm:ssZ') => {
  return dayjs().format(format)
}

const sinceYesterday = (date) => {
  if (!date) return false
  return dayjs(date).isAfter(dayjs().subtract(1, 'day'))
}

const addTime = (date, time = 1, duration = 'Hour') => {
  if (!date) return false
  return dayjs(date).add(time, duration)
}

const isSameDate = (date1, date2) => {
  return dayjs(date1).isSame(dayjs(date2), 'hour')
}

export {
  dayjs,
  mountDayYearFormat,
  nowFullDate,
  timeZone,
  timezones,
  formatDate,
  inCurrentDay,
  inLastWeek,
  inCurrentWeek,
  inNextWeek,
  inLastMonth,
  inCurrentMonth,
  inNextMonth,
  inCurrentYear,
  inLastYear,
  inUpcomingDays,
  sinceYesterday,
  inLastThirtyDays,
  formatDateOnly,
  formatHourOnly,
  dateFormatToCRM,
  dateHourFormatToCRM,
  addTime,
  isSameDate,
}