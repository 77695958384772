<script setup>
import { ref, toRefs, computed } from 'vue'
import SlideOver from '@/Shared/SlideOver.vue'
import RadioInput from '@/Shared/RadioInput.vue'

const props = defineProps({
  lostReason: {
    type: Array,
    default: () => [],
  },
  open: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['close', 'update:modelValue', 'submit'])

const form = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const { open } = toRefs(props)
const errors = ref([])
const formElement = ref(null)

function submitForm() {
  if (validateForm()) return

  if (formElement.value.requestSubmit) {
    formElement.value.requestSubmit()
  } else {
    formElement.value.submit()
  }

}

function validateForm() {
  errors.value = []
  if (form.value.stage.lost_reason == '') {
    errors.value.push('You must select an option')
    return true
  }
}
</script>

<template>
  <SlideOver
    title=""
    :open="open"
    @close="emit('close')"
  >
    <template #default>
      <div class="relative">
        <div v-if="form.processing" class="absolute z-10 flex items-center justify-center w-full h-full bg-gray-300 bg-opacity-60 ">
          <div class="mt-1 lds-ring">
            <div /><div /><div /><div />
          </div>
        </div>
        <div class="pb-10 mx-5">
          <h3 class="text-xl font-semibold text-gray-800 ">
            Select from the list below the reason the appointment is being marked as ongoing
          </h3>
          <form
            ref="formElement"
            class="mt-6"
            @submit.prevent="emit('submit')"
          >
            <RadioInput
              v-for="option in lostReason.slice(1)"
              :key="option.id"
              v-model="form.stage.lost_reason"
              name="optLostSelected"
              :label="option.display_value"
              :value="option.id"
            />
            <div
              v-if="errors.length"
              class="mt-2 text-sm text-red-600"
            >
              {{ errors[0] }}
            </div>
          </form>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end flex-shrink-0 px-4 py-4 space-x-3">
        <button
          class="px-4 py-2 text-sm border-2 button-outline button-rounded"
          @click="emit('close')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary disabled:bg-[#7d96cd] disabled:cursor-not-allowed"
          :disabled="form.processing"
          @click="submitForm"
        >
          Submit
        </button>
      </div>
    </template>
  </SlideOver>
</template>