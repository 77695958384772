import { computed } from 'vue'
import { formatPhone } from '@/utils/helpers.js'
import { isEmpty } from '@/utils/helpers.js'
import dayjs from 'dayjs'

export default function useLeadOrClientInfo(leadOrClient) {

  const fullName = computed(() => {
    return `${leadOrClient.value.first_name} ${leadOrClient.value.last_name}`
  })

  const email = computed(() => {
    return isEmpty(leadOrClient.value.email)
  })

  const plan = computed(() => {
    return isEmpty(leadOrClient.value.plan_type)
  })

  const formatedPhone = computed(() => {
    return leadOrClient.value.mobile ? formatPhone(leadOrClient.value.mobile) : '--'
  })

  const formatedOtherPhone = computed(() => {
    return leadOrClient.value.other_phone ? formatPhone(leadOrClient.value.other_phone) : '--'
  })

  const cityState = computed(() => {
    return `
      ${isEmpty(leadOrClient.value.mailing_city)},
      ${isEmpty(leadOrClient.value.mailing_state)}
      `
  })

  const zipCode = computed(() => {
    return isEmpty(leadOrClient.value.mailing_zip)
  })

  const addressInfo = computed(() => {
    return `
    ${isEmpty(leadOrClient.value.mailing_street)}
    ${isEmpty(leadOrClient.value.mailing_street_2, '')}
  `
  })

  const status = computed(() => {
    return leadOrClient.value.active_client ? 'Active' : 'Inactive'
  })

  const ssn = computed(() => {
    return isEmpty(leadOrClient.value.ssn)
  })

  const medicareNumber = computed(() => {
    return isEmpty(leadOrClient.value.medicare_number)
  })

  const source = computed(() => {
    return isEmpty(leadOrClient.value.lead_source)
  })

  const language = computed(() => {
    return isEmpty(leadOrClient.value.primary_language)
  })

  const spouse = computed(() => {
    return isEmpty(leadOrClient.value.spouse.name)
  })

  const yearlyIncome = computed(() => {
    let sum = 0
    if (leadOrClient.value.social_security) sum += leadOrClient.value.social_security
    if (leadOrClient.value.additional_pension) sum += leadOrClient.value.additional_pension
    if (leadOrClient.value.rents) sum += leadOrClient.value.rents
    if (leadOrClient.value.wages) sum += leadOrClient.value.wages
    if (leadOrClient.value.self_employment) sum += leadOrClient.value.self_employment
    if (leadOrClient.value.monthly_income) sum += leadOrClient.value.monthly_income
    return sum
  })

  const googleMapsUrl = computed(() => {
    let mapsUrl = 'https://maps.google.com/?q='
    if (leadOrClient.value.mailing_street) {
      mapsUrl += leadOrClient.value.mailing_street
      if (leadOrClient.value.mailing_city) {
        mapsUrl += `+${leadOrClient.value.mailing_city}`
      }
      if (leadOrClient.value.mailing_state) {
        mapsUrl += `+${leadOrClient.value.mailing_state}`
      }
      if (leadOrClient.value.mailing_zip) {
        mapsUrl += `+${leadOrClient.value.mailing_zip}`
      }
      return mapsUrl
    }
    return '#'
  })

  const intakeDate = computed(() => {
    if (!leadOrClient.value.intake_date) return '--'
    return dayjs(leadOrClient.value.intake_date).format('MM/YY/DD')
  })

  return {
    googleMapsUrl,
    fullName,
    email,
    plan,
    formatedPhone,
    formatedOtherPhone,
    addressInfo,
    status,
    cityState,
    zipCode,
    ssn,
    medicareNumber,
    source,
    spouse,
    intakeDate,
    yearlyIncome,
    language,
  }

}
