<script setup>
import { ref, toRef, computed, inject, watch } from 'vue'
import SlideOver from '@/Shared/SlideOver.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextAreaInput from '@/Shared/TextAreaInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import LeadSourceReferralAlert from '@/Shared/LeadSourceReferralAlert.vue'
import useUSStates from '@/composables/useUSStates.js'
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption } from '@headlessui/vue'
import * as routes from '@/utils/routes.js'
import axios from 'axios'
import Icon from '@/Shared/Icon.vue'
import { formatPlaceToObject, debounce } from '@/utils/helpers.js'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['close', 'update:modelValue', 'submit'])

const form = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const open = toRef(props, 'open')
const formElement = ref(null)

const { states } = useUSStates()

const phone = ref(form.value.lead.mobile)
const otherPhone = ref(form.value.lead.other_phone)

const query = ref('')
const filteredPeople = ref([])
const fields = inject('fields')

const sources = fields.leadSources
const leadStatusAgent = fields.lead_status_agent
const medicareOptiosns = fields.medicareOpt
const interesedIn = fields.interesedIn
const medicalConditions = fields.medicalConditions
const medicaidProgram = fields.medicaidProgram
const sepOpts = fields.sepOpts
const primaryLanguage = fields.primaryLanguage
const MINIMUM_PHONE_NUMBER_LENGTH = 7

const plantTypeOpts = [
  { label: 'Medicare', value: 'Medicare' },
  { label: 'ACA', value: 'ACA' },
  { label: 'Life', value: 'Life' },
]

function submitForm() {
  if (formElement.value.requestSubmit) {
    formElement.value.requestSubmit()
  } else {
    formElement.value.submit()
  }
}

const defaultLanguage = () => {
  if (form.value.lead.primary_language) return
  form.value.lead.primary_language = 'Spanish'
}

const searchSpouse = (q) => {
  query.value = q
  if (!isNaN(parseInt(q)) && q.split('').length >= MINIMUM_PHONE_NUMBER_LENGTH) {
    axios.get(routes.spouse(q))
      .then((resp) => {
        filteredPeople.value = resp.data.map((respClient) => {
          return {
            name: `${respClient.first_name} ${respClient.last_name}`,
            id: respClient.id,
          }
        })

      })
  }
}

const searchIfContactExists = (q, field) => {
  query.value = q

  if (form.value.errors[field]) {
    delete form.value.errors[field]
  }

  if (!form.value.lead.id && !isNaN(parseInt(q)) && q.split('').length >= MINIMUM_PHONE_NUMBER_LENGTH) {
    axios.get(routes.contact(q, { format: 'json' }))
      .then((resp) => {
        if (resp.data) {
          form.value.errors[field] = ['Contact already exists']
        }
      }).catch((err) => {
        console.error(err)
      })
  }

  if (field == 'mobile') {
    form.value.lead.mobile = q
  }

  if (field == 'other_phone') {
    form.value.lead.other_phone = q
  }

}

const searchIfContactExistsDebounced = debounce(searchIfContactExists, 500)

const showLeadSourceInput = computed(() => {
  if (!form.value.lead.id) return false

  let src = sources.filter((source) => {
    return source.actual_value == form.value.lead.lead_source
  })

  return src.length < 1
})

const resetSpouseFieldOnStart = () => {
  if (typeof form.value.lead.spouse == 'object' && form.value.lead.spouse != null) {
    let newSpouse = { ...form.value.lead.spouse }
    filteredPeople.value = [newSpouse]
  }
}

const changeGooglePacContainer = () => {
  let autocompletedContainer = document.querySelector('#googlemapsautopachere')
  let googlePac = document.querySelector('.pac-container.pac-logo')
  autocompletedContainer.prepend(googlePac)
}

const clealAllGooglePacContainers = () => {
  let googlePacContainers = document.querySelectorAll('.pac-container.pac-logo')
  googlePacContainers.forEach((pac) => {
    pac.remove()
  })
}

watch(open, (newValue) => {
  if (!newValue) {
    clealAllGooglePacContainers()
  }
})

const setPlace = (place) => {
  form.value.lead.mailing_street = place.name
  form.value.lead.mailing_city = place.vicinity
  let placeObj = formatPlaceToObject(place)
  form.value.lead.mailing_zip = placeObj.postal_code
  form.value.lead.mailing_state = placeObj.administrative_area_level_1
}

const updateLeadStatusAgent = (event) => {
  if (event.target.value == 'Archive') {
    form.value.lead.agent_archived = true
  } else {
    form.value.lead.agent_archived = false
  }
}

resetSpouseFieldOnStart()
defaultLanguage()
</script>

<template>
  <SlideOver
    :title="form.lead.id?'Edit Lead':'New Lead'"
    :open="open"
    @close="emit('close')"
  >
    <template #default>
      <div class="relative">
        <div v-if="form.processing" class="absolute z-10 flex items-center justify-center w-full h-full bg-gray-300 bg-opacity-60 ">
          <div class="mt-1 lds-ring">
            <div /><div /><div /><div />
          </div>
        </div>
        <form
          ref="formElement"
          class="flex flex-col divide-y divide-gray-200"
          @submit.prevent="emit('submit')"
        >
          <div class="px-4 pb-5 space-y-6 sm:px-6">
            <div class="grid grid-cols-2 gap-x-4">
              <TextInput
                v-model="form.lead.first_name"
                label="First Name"
                required
                :errors="form.errors.first_name"
              />
              <TextInput
                v-model="form.lead.last_name"
                label="Last Name"
                required
                :errors="form.errors.last_name"
              />
            </div>
            <div class="grid grid-cols-2 gap-x-4">
              <TextInput
                v-model="phone"
                v-maska="'(###) ###-####'"
                label="Mobile phone"
                type="tel"
                required
                :errors="form.errors.mobile"
                @maska="searchIfContactExistsDebounced($event.target.dataset.maskRawValue, 'mobile')"
              />

              <TextInput
                v-model="otherPhone"
                v-maska="'(###) ###-####'"
                label="Home phone"
                type="tel"
                :errors="form.errors.other_phone"
                @maska="searchIfContactExistsDebounced($event.target.dataset.maskRawValue, 'other_phone')"
              />
            </div>

            <TextInput
              v-model="form.lead.email"
              label="Email"
              type="email"
              :errors="form.errors.email"
            />
            <div>
              <label class="block text-sm font-medium text-gray-700" for="Address2">
                Address
              </label>
              <div class="mt-1">
                <GMapAutocomplete
                  id="Address2"
                  placeholder=""
                  type="text"
                  :value="form.lead.mailing_street"
                  class="form-underline"
                  :options="{
                    componentRestrictions: { country: 'us' }
                  }"
                  @place_changed="setPlace"
                  @focusin="changeGooglePacContainer"
                />
              </div>
            </div>
            <!-- <TextInput
              v-model="form.lead.mailing_street"
              label="Address"
              :errors="form.errors.mailing_street"
            /> -->
            <TextInput
              v-model="form.lead.mailing_street_2"
              label="Apartment, Building, Floor"
              :errors="form.errors.mailing_street_2"
            />
            <div class="grid grid-cols-2 gap-x-4">
              <TextInput
                v-model="form.lead.mailing_city"
                label="City"
                :errors="form.errors.mailing_city"
              />
              <TextInput
                v-model="form.lead.mailing_zip"
                label="Zip"
                :errors="form.errors.mailing_zip"
              />
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700" for="stateid">
                State
              </label>
              <div class="mt-1">
                <select
                  id="stateid"
                  v-model="form.lead.mailing_state"
                  class="form-underline"
                  :class="{ error: form.errors.mailing_state }"
                >
                  <option :value="null" disabled selected>
                    Select a state
                  </option>
                  <option v-for="(state, index) in states" :key="index" :value="state.id">
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div
                v-if="form.errors.mailing_state"
                class="mt-2 text-xs text-red-600"
              >
                {{ form.errors.mailing_state[0] }}
              </div>
            </div>
            <SelectInput
              v-model="form.lead.primary_language"
              label="Primary Language"
              :errors="form.errors.primary_language"
            >
              <option
                v-for="language in primaryLanguage.slice(1)"
                :key="language.id"
                :value="language.actual_value"
              >
                {{ language.display_value }}
              </option>
            </SelectInput>
            <div class="flex-wrap lg:flex lg:-mx-3">
              <div class="w-full lg:px-3">
                <SelectInput
                  v-model="form.lead.plan_type"
                  label="Type"
                  :errors="form.errors.plan_type"
                  required
                >
                  <option value="" disabled selected>
                    Select a Type
                  </option>
                  <option v-for="(planTypeOpt, index) in plantTypeOpts" :key="index" :value="planTypeOpt.value">
                    {{ planTypeOpt.label }}
                  </option>
                </SelectInput>
              </div>
            </div>
            <TextInput
              v-model="form.lead.sold_plan"
              label="Sold Plan:"
              :errors="form.errors.sold_plan"
            />
            <div v-if="showLeadSourceInput">
              <label class="block text-sm font-medium text-gray-700" for="text-input-969452234918913">
                Lead Source
              </label>
              <div class="mt-1">
                <input
                  id="text-input-969452234918913"
                  class="form-underline disabled:bg-gray-100 disabled:cursor-not-allowed"
                  type="text"
                  :value="form.lead.lead_source"
                  disabled
                >
              </div>
            </div>
            <SelectInput
              v-else
              v-model="form.lead.lead_source"
              label="Lead Source"
              required
              :errors="form.errors.lead_source"
            >
              <option value="" disabled selected>
                Select a lead source
              </option>
              <option v-for="source in sources.slice(1)" :key="source.id" :value="source.actual_value">
                {{ source.display_value }}
              </option>
            </SelectInput>

            <LeadSourceReferralAlert v-model="form.lead.agent_referral_description" :lead-source="form.lead.lead_source" />

            <div class="relative">
              <label class="flex pb-1 text-sm font-medium text-gray-700" title="Find spouse by entering the phone number">
                Spouse
                <Icon v-tooltip="'Find spouse by entering the phone number'" name="information-circle" class="w-5 h-5 ml-1 text-blue-400" />
              </label>
              <Combobox v-model="form.lead.spouse">
                <ComboboxInput
                  v-maska="['(###) ###-####', 'X* X*']"
                  class="form-underline"
                  :display-value="(person) => person && person.name"
                  @maska="searchSpouse($event.target.dataset.maskRawValue)"
                />
                <ComboboxOptions class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <div
                    v-if="filteredPeople.length === 0 && query !== ''"
                    class="relative px-4 py-2 text-gray-700 cursor-default select-none"
                  >
                    Nothing found.
                  </div>
                  <ComboboxOption
                    v-for="person in filteredPeople"
                    :key="person.id"
                    v-slot="{ _selected, active }"
                    :value="person"
                  >
                    <li
                      class="relative py-2 pl-10 pr-4 cursor-default select-none"
                      :class="{
                        'text-white bg-blue-400': active,
                        'text-gray-900': !active,
                      }"
                    >
                      <span
                        class="block truncate"
                        :class="{ 'font-medium': _selected, 'font-normal': !_selected }"
                      >
                        {{ person.name }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </Combobox>
            </div>
            <TextInput
              v-model="form.lead.emergency_contact_name"
              label="Emergency Contact Name:"
              :errors="form.errors.emergency_contact_name"
            />

            <TextInput
              v-model="form.lead.emergency_contact_phone"
              v-maska="'(###) ###-####'"
              label="Emergency Contact Phone:"
              type="tel"
              :errors="form.errors.emergency_contact_phone"
            />

            <SelectInput
              v-if="form.lead.id"
              v-model="form.lead.lead_status_agent"
              label="Status"
              required
              :errors="form.errors.lead_status_agent"
              @change="updateLeadStatusAgent"
            >
              <option value="" disabled selected>
                Select a status
              </option>
              <option v-for="status in leadStatusAgent.slice(1)" :key="status.id" :value="status.actual_value">
                {{ status.display_value }}
              </option>
            </SelectInput>

            <div class="pt-3 mt-6 space-y-6 border-t-2">
              <h3 class="text-lg font-bold leading-tight text-gray-800 lg:text-xl">
                Income/Public Aid
              </h3>
            </div>
            <TextInput
              v-model="form.lead.social_security"
              label="Social Security:"
              type="number"
              :errors="form.errors.social_security"
            />
            <TextInput
              v-model="form.lead.spend_down"
              label="Spend Down:"
              type="number"
              :errors="form.errors.spend_down"
            />
            <TextInput
              v-model="form.lead.additional_pension"
              label="Additional Pension:"
              type="number"
              :errors="form.errors.additional_pension"
            />
            <TextInput
              v-model="form.lead.rents"
              label="Rents:"
              type="number"
              :errors="form.errors.rents"
            />
            <TextInput
              v-model="form.lead.wages"
              label="Wages:"
              type="number"
              :errors="form.errors.wages"
            />
            <TextInput
              v-model="form.lead.self_employment"
              label="Self-Employment:"
              type="number"
              :errors="form.errors.self_employment"
            />
            <TextInput
              v-model="form.lead.monthly_income"
              label="Other Income:"
              type="number"
              :errors="form.errors.monthly_income"
            />

            <div class="flex-wrap lg:flex lg:-mx-3">
              <div class="w-full lg:px-3">
                <label class="block text-sm font-medium text-gray-700">LIS:</label>
                <span class="relative z-0 inline-flex w-full pt-1 rounded-md shadow-sm">
                  <button
                    :class="form.lead.lis ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                    type="button"
                    class="relative inline-flex items-center justify-center flex-1 px-4 py-3 text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    @click="form.lead.lis = true;"
                  >
                    YES
                  </button>
                  <button
                    :class="!form.lead.lis ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                    type="button"
                    class="relative inline-flex items-center justify-center flex-1 px-4 py-3 -ml-px text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    @click="form.lead.lis = !form.lead.lis;"
                  >
                    NO
                  </button>
                </span>
              </div>
            </div>
            <TextInput
              v-if="form.lead.lis"
              v-model="form.lead.lis_level"
              label="LIS Level:"
              :errors="form.errors.lis_level"
            />
            <TextInput
              v-if="form.lead.lis"
              v-model="form.lead.lis_percentage"
              label="LIS Percentage:"
              :errors="form.errors.lis_percentage"
            />
            <div class="pt-3 mt-6 space-y-6 border-t-2">
              <h3 class="text-lg font-bold leading-tight text-gray-800 lg:text-xl">
                Intake
              </h3>
            </div>
            <TextInput
              v-model="form.lead.intake_date"
              label="Intake Date:"
              type="date"
              :errors="form.errors.intake_date"
            />
            <div class="mt-2.5">
              <label class="block text-sm font-medium text-gray-700 cursor-pointer">
                Medicare:
              </label>
              <div v-for="medicare in medicareOptiosns" :key="medicare.id" class="mt-2.5">
                <div class="flex items-center mt-1 space-x-3">
                  <input
                    :id="medicare.id"
                    v-model="form.lead.already_has_medicare"
                    class="cursor-pointer"
                    type="checkbox"
                    :value="medicare.actual_value"
                  >
                  <label class="block text-sm font-medium text-gray-700 cursor-pointer" :for="medicare.id">
                    {{ medicare.display_value }}
                  </label>
                </div>
              </div>
            </div>
            <div class="mt-2.5">
              <label class="block text-sm font-medium text-gray-700 cursor-pointer">
                Interested In:
              </label>
              <div v-for="interesed in interesedIn" :key="interesed.id" class="mt-2.5">
                <div class="flex items-center mt-1 space-x-3">
                  <input
                    :id="interesed.id"
                    v-model="form.lead.interested_in"
                    class="cursor-pointer"
                    type="checkbox"
                    :value="interesed.actual_value"
                  >
                  <label class="block text-sm font-medium text-gray-700 cursor-pointer" :for="interesed.id">
                    {{ interesed.display_value }}
                  </label>
                </div>
              </div>
            </div>
            <SelectInput
              v-model="form.lead.sep"
              label="SEP"
              :errors="form.errors.sep"
            >
              <option value="" disabled selected>
                Select a SEP
              </option>
              <option v-for="sepOpt in sepOpts.slice(1)" :key="sepOpt.id" :value="sepOpt.actual_value">
                {{ sepOpt.display_value }}
              </option>
            </SelectInput>

            <div class="mt-2.5">
              <label class="block text-sm font-medium text-gray-700 cursor-pointer">
                Medical Conditions:
              </label>
              <div v-for="condition in medicalConditions" :key="condition.id" class="mt-2.5">
                <div class="flex items-center mt-1 space-x-3">
                  <input
                    :id="condition.id"
                    v-model="form.lead.medical_conditions"
                    class="cursor-pointer"
                    type="checkbox"
                    :value="condition.actual_value"
                  >
                  <label class="block text-sm font-medium text-gray-700 cursor-pointer" :for="condition.id">
                    {{ condition.display_value }}
                  </label>
                </div>
              </div>
            </div>
            <TextAreaInput
              v-model="form.lead.medications"
              label="Current Medications:"
              :errors="form.errors.medications"
            />
            <TextAreaInput
              v-model="form.lead.preferred_benefits"
              label="Preferred Benefits:"
              :errors="form.errors.preferred_benefits"
            />
            <TextAreaInput
              v-model="form.lead.specialists"
              label="Specialists:"
              :errors="form.errors.specialists"
            />
            <div class="flex-wrap lg:flex lg:-mx-3">
              <div class="w-full lg:px-3">
                <label class="block text-sm font-medium text-gray-700">Has Medicaid:</label>
                <span class="relative z-0 inline-flex w-full pt-1 rounded-md shadow-sm">
                  <button
                    :class="form.lead.has_medicaid ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                    type="button"
                    class="relative inline-flex items-center justify-center flex-1 px-4 py-3 text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    @click="form.lead.has_medicaid = true;"
                  >
                    YES
                  </button>
                  <button
                    :class="!form.lead.has_medicaid ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                    type="button"
                    class="relative inline-flex items-center justify-center flex-1 px-4 py-3 -ml-px text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    @click="form.lead.has_medicaid = false;"
                  >
                    NO
                  </button>
                </span>
              </div>
            </div>
            <SelectInput
              v-model="form.lead.medicaid_program"
              label="Medicaid Program"
              :errors="form.errors.medicaid_program"
            >
              <option value="" disabled selected>
                Select a Medicaid Program
              </option>
              <option v-for="program in medicaidProgram.slice(1)" :key="program.id" :value="program.actual_value">
                {{ program.display_value }}
              </option>
            </SelectInput>
            <TextInput
              v-model="form.lead.medicaid_number"
              label="Medicaid Number"
              type="number"
              :errors="form.errors.medicaid_number"
            />
            <TextInput
              v-model="form.lead.plan_carrier_at_intake"
              label="Current Plan/Carrier:"
              :errors="form.errors.plan_carrier_at_intake"
            />
            <TextInput
              v-model="form.lead.pcp_name"
              label="PCP Name:"
              :errors="form.errors.pcp_name"
            />

            <TextInput
              v-model="form.lead.pcp_phone"
              v-maska="'(###) ###-####'"
              label="PCP Phone:"
              type="tel"
              :errors="form.errors.pcp_phone"
            />
            <TextInput
              v-model="form.lead.part_a"
              label="Part A"
              type="date"
              max="2099-12-31"
              :errors="form.errors.part_a"
            />
            <TextInput
              v-model="form.lead.part_b"
              label="Part B"
              type="date"
              max="2099-12-31"
              :errors="form.errors.part_b"
            />
            <div class="flex-wrap lg:flex lg:-mx-3">
              <div class="w-full lg:px-3">
                <label class="block text-sm font-medium text-gray-700">Permanently Disabled:</label>
                <span class="relative z-0 inline-flex w-full pt-1 rounded-md shadow-sm">
                  <button
                    :class="form.lead.permanently_disabled ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                    type="button"
                    class="relative inline-flex items-center justify-center flex-1 px-4 py-3 text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    @click="form.lead.permanently_disabled = true;"
                  >
                    YES
                  </button>
                  <button
                    :class="!form.lead.permanently_disabled ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                    type="button"
                    class="relative inline-flex items-center justify-center flex-1 px-4 py-3 -ml-px text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    @click="form.lead.permanently_disabled = false;"
                  >
                    NO
                  </button>
                </span>
              </div>
            </div>
            <div class="pt-3 mt-6 space-y-6 border-t-2">
              <h3 class="text-lg font-bold leading-tight text-gray-800 lg:text-xl">
                Sensitive Info
              </h3>
              <TextInput
                v-model="form.lead.date_of_birth"
                label="Date of Birth"
                type="date"
                max="2099-12-31"
                :errors="form.errors.date_of_birth"
              />
            </div>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end flex-shrink-0 px-4 py-4 space-x-3">
        <button
          class="px-4 py-2 text-sm border-2 button-outline button-rounded"
          @click="emit('close')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary disabled:cursor-not-allowed disabled:bg-blue-400 disabled:border-blue-400"
          :disabled="form.processing"
          @click="submitForm"
        >
          {{ form.lead.id?'Update Lead':'Create Lead' }}
        </button>
      </div>
    </template>
  </SlideOver>
</template>