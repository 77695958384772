import { ref, computed, watch } from 'vue'
import { inLastWeek, inCurrentWeek, inNextWeek, inLastMonth, inCurrentMonth, inUpcomingDays, sinceYesterday, inLastThirtyDays, inCurrentYear } from '@/utils/dates'

export default function useAppointmentFilters(appointments) {
  const url = new URL(window.location)

  const filters = ref({
    type: 'All',
    dateRange: 'recent-future',
    stage: [],
  })

  /**
	 * Update filters if present in the query parameters.
	 */
  if (url.searchParams.has('type') && url.searchParams.has('dateRange') && url.searchParams.has('stage')) {
    filters.value = {
      type: url.searchParams.get('type'),
      dateRange: url.searchParams.get('dateRange'),
      stage: url.searchParams.get('stage').split(','),
    }
  }

  /**
	 * Add/update the query parameters when the filters change.
	 */
  watch(filters.value, (filter) => {
    url.searchParams.set('type', filter.type)
    url.searchParams.set('dateRange', filter.dateRange)
    url.searchParams.set('stage', filter.stage)
    window.history.pushState({}, '', url)
  })

  const filteredAppointments = computed(() => {
    return appointments.value.filter((appointment) => {
      return isSelectedType(appointment) && isInDateRange(appointment) && isInStage(appointment)
    })
  })

  const isSelectedType = (appointment) => {
    if (filters.value.type === 'All') return true
    return appointment.plan_type === filters.value.type
  }

  const isInDateRange = (appointment) => {
    const start = appointment.start

    switch (filters.value.dateRange) {
    case 'upcoming':
      return inUpcomingDays(start)
    case 'recent-future':
      return sinceYesterday(start)
    case 'this-week':
      return inCurrentWeek(start)
    case 'last-week':
      return inLastWeek(start)
    case 'next-week':
      return inNextWeek(start)
    case 'this-month':
      return inCurrentMonth(start)
    case 'last-month':
      return inLastMonth(start)
    case 'last-thirty-days':
      return inLastThirtyDays(start)
    case 'this-year':
      return inCurrentYear(start)
    default:
      return sinceYesterday(start)
    }
  }

  const isInStage = (appointment) => {
    if (filters.value.stage.length === 0) return true
    return filters.value.stage.includes(appointment.stage)
  }

  return {
    filters,
    filteredAppointments,
  }
}