<script setup>
import axios from 'axios'
import { notify } from 'notiwind'
import { ref, onMounted, watch } from 'vue'
import * as routes from '@/utils/routes'
import Icon from '@/Shared/Icon.vue'
import { phone } from '@/utils/filters'
import { Inertia } from '@inertiajs/inertia'
import usePhoneVerification from '@/composables/usePhoneVerification.js'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const { verifyCallerId } = usePhoneVerification()

onMounted(() => verifyCallerId())

const open = ref(false)
const verificationRequest = ref({})

async function requestPhoneVerification() {
  const { data } = await axios.post(routes.my_phone_verifications({ format: 'json' }))
  verificationRequest.value = data
  open.value = true
  if (data?.error) return
  watchForVerification()
  stopVerification()
}

const phoneVerified = ref(false)
const interval = ref(null)

function watchForVerification() {
  interval.value = setInterval(async () => {
    const { verified } = await verifyCallerId()
    phoneVerified.value = verified
  }, 3000)
}

// When the verification returns true, clear the interval, close the modal
// and reload the page to reflect the changes.
watch(phoneVerified, (verified) => {
  if (verified) {
    clearInterval(interval.value)
    open.value = false
    Inertia.reload()
  }
})

function stopVerification() {
  setTimeout(() => {
    if (phoneVerified.value) return
    clearInterval(interval.value)
    handleTimedOutError()
  }, 60000)
}

function handleTimedOutError() {
  open.value = false
  verificationRequest.value = {}
  notify({
    group: 'conditional',
    title: 'Error',
    type: 'warning',
    text: 'Verification timed out. Please try again.',
  }, 6000)
}
</script>

<template>
  <span v-if="user.phone_verified" title="Verified phone">
    <Icon name="check-badge" class="w-5 h-5 text-hartgreen" />
  </span>
  <div v-else class="pl-2">
    <button class="text-xs lg:text-base font-medium text-hartblue" @click="requestPhoneVerification">
      Verify Now
    </button>
  </div>

  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button type="button" class="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2" @click="open = false">
                  <span class="sr-only">Close</span>
                  <Icon name="close" class="w-4 h-4 fill-current" aria-hidden="true" />
                </button>
              </div>
              <div v-if="verificationRequest?.validation_code">
                <div class="flex items-center justify-center w-12 h-12 mx-auto bg-blue-100 rounded-full">
                  <Icon name="phone" class="w-6 h-6 fill-current text-hartblue" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <p class="text-sm text-gray-500">
                    Please enter the following code in the call we are making to the phone number {{ phone(user.mobilePhone) }}
                  </p>
                  <div class="my-4">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                      {{ verificationRequest.validation_code }}
                    </DialogTitle>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
                  <Icon name="exclamation-triangle" class="w-6 h-6 text-red-500" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <p class="text-sm text-red-500">
                    {{ verificationRequest.error }}
                  </p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>