import { ref, computed } from 'vue'
import axios from 'axios'
import { notify } from 'notiwind'
import * as routes from '@/utils/routes.js'
import { Inertia } from '@inertiajs/inertia'
import { formatDate, dateHourFormatToCRM, dayjs } from '@/utils/dates.js'
import { useForm } from '@inertiajs/inertia-vue3'

export default function useTasks(tasks) {
  const selected = ref(true)
  const openNewTaskModal = ref(false)
  // const openTasks = ref([])
  // const completedTasks = ref([])

  const form = useForm({
    task: {
      due_date: dateHourFormatToCRM(dayjs()),
    },
  })

  const displayTasks = computed(() => {
    if (selected.value) {
      return tasks.value.filter((task) => {
        return task.status == 'Open'
      })
    } else {
      return tasks.value.filter((task) => {
        return task.status == 'Completed'
      }).sort((a, b) => {
        return new Date(b.due_date) - new Date(a.due_date)
      })
    }
  })

  const createTask = () => {
    form.processing = true
    let { task } = form
    axios.post(routes.tasks(), { task: { ...task, due_date: formatDate(task.due_date, 'YYYY-MM-DD') } }).then(() => {
      Inertia.reload()
      openNewTaskModal.value = false
      form.task = {}
      form.processing = false
    }).catch((response) => {
      openNewTaskModal.value = false
      form.processing = false
      notify({
        group: 'bottom',
        title: 'Error creating the task',
        text: `${response}`,
      }, 7000)
    })
  }

  return {
    form,
    selected,
    openNewTaskModal,
    displayTasks,
    createTask,
  }
}