<script setup>
import { toRefs, computed } from 'vue'
import { formatPhone } from '@/utils/helpers.js'
import { formatDate } from '@/utils/dates'
import useAppointmentStageUpdater from '@/composables/useAppointmentStageUpdater.js'
import * as routes from '@/utils/routes'

import StageSelect from '@/Shared/AppointmentStageSelect.vue'
import StatusBadge from '@/Shared/StatusBadge.vue'
import Icon from '@/Shared/Icon.vue'

const props = defineProps({
  appointment: Object,
})

const { appointment } = toRefs(props)

const emit = defineEmits(['openModal'])

const fullName = computed(() => {
  return appointment.value.contact_first_name && appointment.value.contact_last_name
    ? `${appointment.value.contact_first_name} ${appointment.value.contact_last_name}`
    : '--'
})

const { update } = useAppointmentStageUpdater(appointment)

const updateMiddleware = (stage) => {
  if (stage.name != 'Sold' && stage.name != 'Lost' && stage.name != 'Ongoing') {
    update(stage)
    return true
  }
  emit('openModal', { appointment: appointment.value, ...stage })
}

const googleMapsUrl = computed(() => {
  return `https://maps.google.com/?q=${appointment.value.contact_mailing_street}+${appointment.value.contact_mailing_city}+${appointment.value.contact_mailing_state}+${appointment.value.contact_mailing_zip}`
})

const activityClientUrl = computed(() => {
  if (typeof appointment.value.contact_name?.id == 'undefined') return '#'
  return routes.activity_client(appointment.value.contact_name.id)
})
</script>

<template>
  <tr class="hover:bg-gray-20">
    <td class="px-4 pt-5 pb-3 lg:py-5 lg:text-center table-item">
      <p class="text-gray-800 text-xxs lg:text-sm">
        <span class="block font-semibold">{{ formatDate(appointment.start, 'MM/DD/YY') }}</span>
        <span class="block">{{ formatDate(appointment.start, 'hh:mm A') }} - {{ formatDate(appointment.end, 'hh:mm A') }}</span>
      </p>
    </td>
    <td class="hidden px-4 pt-5 pb-3 lg:py-5 lg:table-cell table-item">
      <p class="text-xs text-gray-800 lg:text-sm">
        {{ appointment.plan_type }}
      </p>
    </td>
    <td class="px-4 pt-5 pb-3 lg:py-5 table-item">
      <a :href="activityClientUrl" class="block text-xs font-medium leading-4 lg:leading-5 lg:text-sm text-hartblue">
        {{ fullName }}
      </a>
      <dl class="text-xs font-normal lg:hidden">
        <dt class="sr-only">
          Plan Type
        </dt>
        <dd class="mt-1 text-gray-700 truncate">
          {{ appointment.plan_type }}
        </dd>
      </dl>
    </td>
    <td class="hidden px-4 pt-5 pb-3 lg:py-5 table-item lg:table-cell">
      <p v-if="appointment.type != 'Home'" class="flex text-sm text-gray-800">
        <Icon
          name="building"
          class="w-5 h-5 mr-1 fill-current text-hartblue"
        />
        {{ appointment.type == 'Office' ? `${appointment.type} - ${appointment.office_location}` : appointment.type }}
      </p>
      <p v-else class="flex space-x-1 text-xs text-hartblue">
        <Icon
          name="location"
          class="w-5 h-5 fill-current text-hartblue shrink-0"
        />
        <a :href="googleMapsUrl" target="_blank">
          {{ appointment.contact_mailing_street }} {{ appointment.contact_mailing_city }} {{ appointment.contact_mailing_state }} {{ appointment.contact_mailing_zip }}
        </a>
      </p>
    </td>
    <td class="hidden px-4 pt-5 pb-3 lg:py-5 table-item lg:table-cell">
      <a :href="`tel:${appointment.contact_mobile_phone}`" class="flex text-sm text-hartblue">
        {{ formatPhone(appointment.contact_mobile_phone) }}
        <Icon
          name="phone"
          class="w-5 h-5 ml-1 fill-current"
        />
      </a>
    </td>
    <td class="pt-5 pb-3 lg:py-5 lg:text-center table-item">
      <StatusBadge
        v-if="appointment.stage == 'Updating'"
        class="mx-auto"
        :dropdown="false"
        :status="appointment.stage"
      />
      <StageSelect v-else :stage="appointment.stage" @change="updateMiddleware" />
    </td>
  </tr>
  <tr class="border-b border-gray-200 lg:hidden">
    <td colspan="3" class="px-4 py-1 bg-slate-100">
      <p v-if="appointment.type != 'Home'" class="flex items-end text-xs text-gray-800">
        <Icon
          name="building"
          class="w-5 h-5 mr-1 fill-current text-hartblue"
        />
        <span class="-pb-2">
          {{ appointment.type == 'Office' ? `${appointment.type} - ${appointment.office_location}` : appointment.type }}
        </span>

        <a :href="`tel:${appointment.contact_mobile_phone}`" class="flex items-end ml-3 text-xs text-hartblue">
          {{ formatPhone(appointment.contact_mobile_phone) }}
          <Icon
            name="phone"
            class="w-5 h-5 ml-1 fill-current"
          />
        </a>
      </p>
      <p v-else class="flex items-end space-x-1 text-xs text-hartblue">
        <Icon
          name="location"
          class="w-4 h-4 fill-current text-hartblue shrink-0"
        />
        <a :href="googleMapsUrl" target="_blank">
          {{ appointment.contact_mailing_street }} {{ appointment.contact_mailing_city }} {{ appointment.contact_mailing_state }} {{ appointment.contact_mailing_zip }}
        </a>
      </p>
    </td>
  </tr>
</template>