import { ref } from 'vue'
import axios from 'axios'
import { notify } from 'notiwind'
import * as routes from '@/utils/routes'

export default function useRecordCall(client, phoneField) {
  const open = ref(false)
  const interval = ref(null)
  const call = ref({
    room: null,
    agent_call_id: null,
    client_call_id: null,
    agent_calling: true,
    client_calling: false,
  })

  const generateConferenceCode = async () => {
    const { data } = await axios.post(routes.calls_conferences())
    call.value.room = data.room
    open.value = true
    requestAgentCall()
  }

  const requestAgentCall = async () => {
    if (!call.value.room || client.value[phoneField] === null) open.value = false

    const clientLanguage = client.value.primary_language === 'English' ? 'en-US' : 'es-MX'
    try {
      const { data } = await axios.post(routes.calls(), {
        call: {
          room: call.value.room,
          client_phone: client.value[phoneField],
          client_id: client.value.id,
          client_type: client.value.type,
          client_language: clientLanguage,
        },
      })

      if (typeof data.error != 'undefined') {
        notify({
          group: 'conditional',
          title: 'Error',
          type: 'error',
          text: data.error.table.error,
        }, 10000)
        closeModalAndCleanData()
        return
      }

      if (typeof data.call_id != 'undefined') {
        call.value.agent_call_id = data.call_id
        watchForCallUpdates()
      }

    } catch (error) {
      closeModalAndCleanData()
    }
  }

  const watchForCallUpdates = () => {
    interval.value = setInterval(async () => {
      const { data } = await axios.get(routes.calls_conference({ room: call.value.room }))
      if (data == null) return
      if (data.status === 'completed') {
        closeModalAndCleanData()
      }
      if (data.status === 'in-progress') {
        call.value.client_calling = true
      }
    }, 4000)
  }

  const closeModalAndCleanData = () => {
    open.value = false
    call.value.client_calling = false
    clearInterval(interval.value)
  }

  return {
    open,
    call,
    generateConferenceCode,
    closeModalAndCleanData,
  }
}