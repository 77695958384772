import { ref, computed } from 'vue'
import * as routes from '@/utils/routes.js'
import axios from 'axios'

export default function useSecureModal(emit, showOnModal) {

  const codeLength = ref(6)
  const code = ref(null)
  const completedCode = ref(false)
  const validatingCode = ref(false)
  const validCodeClass = ref('text-hartblue')
  const showResendBtn = ref(false)
  const showModalSlot = ref(false)

  const isValidateDisabled = computed(() => {
    if (code.value == null) return true
    return code.value.split('').length < codeLength.value
  })

  const validateCode = () => {
    validatingCode.value = true
    if (code.value != null) {
      setTimeout(() => {
        axios.put(routes.verify_authorize_code({ format: 'json' }, {
          code: code.value,
        }))
          .then(() => {
            validCodeClass.value = 'text-green-500'
            validatingCode.value = false
            setTimeout(() => {
              emit('isvalidated')
              validCodeClass.value = 'text-hartblue'
              showModalSlot.value = showOnModal
              if (showOnModal) return
              emit('close')
            }, 500)
          }).catch(() => {
            validCodeClass.value = 'text-red-500'
            validatingCode.value = false
            showResendBtn.value = true
          })
      }, 700)
    }
  }

  const resendCode = () => {
    axios.post(routes.verify({ format: 'json' }))
    setTimeout(() => {
      showResendBtn.value = false
    }, 500)
  }

  return {
    codeLength,
    code,
    completedCode,
    validatingCode,
    validCodeClass,
    showResendBtn,
    isValidateDisabled,
    validateCode,
    resendCode,
    showModalSlot,
  }
}