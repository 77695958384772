import { computed } from 'vue'
import dayjs from 'dayjs'
import { inLastMonth, inCurrentMonth } from '@/utils/dates.js'

export default function useAppointmentsCard(appointments) {

  const upcommingAppointments = computed(() => {
    return appointments.value.filter((appointment) => {
      return dayjs(appointment.start) >= dayjs() && (appointment.stage == 'Scheduled' || appointment.stage == 'Confirmed' || appointment.stage == 'Started')
    }).length
  })

  const appointmentsSoldThisMonth = computed(() => {
    return appointments.value.filter((appointment) => {
      return inCurrentMonth(appointment.start) && appointment.stage == 'Sold'
    }).length
  })

  const appointmentsSoldLastMonth = computed(() => {
    return appointments.value.filter((appointment) => {
      return inLastMonth(appointment.start) && appointment.stage == 'Sold'
    }).length
  })

  return {
    upcommingAppointments,
    appointmentsSoldThisMonth,
    appointmentsSoldLastMonth,
  }
}