<script setup>
import { ref, toRefs, computed } from 'vue'
import SlideOver from '@/Shared/SlideOver.vue'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import dayjs from 'dayjs'
import { formatPhone } from '@/utils/helpers.js'
import useUSStates from '@/composables/useUSStates.js'

const props = defineProps({
  newRenewal: {
    type: Array,
    default: () => [],
  },
  sourceOfFunds: {
    type: Array,
    default: () => [],
  },
  policyTerms: {
    type: Array,
    default: () => [],
  },
  open: {
    type: Boolean,
    required: true,
  },
  products: Array,
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['close', 'update:modelValue', 'submit'])

const form = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const { open, products } = toRefs(props)
const formElement = ref(null)
const currentYear = dayjs().format('YY')
const currentMonthNumber = dayjs().format('M')
const { states } = useUSStates()
const planType = ref('')

const planTypesOpts = [
  { id: 'Medicare', name: 'Medicare' },
  { id: 'ACA', name: 'ACA' },
  { id: 'Life', name: 'Life' },
]

const amountMoneyFormat = ref(
  {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 2,
  },
)

const stateObject = computed(() => {
  if (!form.value.contact.mailing_state) return false
  return states.filter((state) => {
    return state.id == form.value.contact.mailing_state
  })[0]
})

const companyOpts = computed(() => {
  let hash = []
  return products.value.filter((current) => {
    if (current.product_category == planType.value) {
      let exists = !hash[`${current.company}_${current.state[0]}`]
      hash[`${current.company}_${current.state[0]}`] = true
      let hasProducts = current.state.length == 0 || current.state[0] == form.value.contact.mailing_state
      return exists && hasProducts
    } else {
      return false
    }
  }).map(product => {
    return {
      label: product.company,
      value: product.company,
    }
  })
})

const planNameOpts = computed(() => {
  return products.value.filter((product) => {
    return product.product_category == planType.value && product.company == form.value.company_name && ( product.state.length == 0 || product.state[0] == form.value.contact.mailing_state)
  }).map(product => {
    let label = product.year ? `[${product.year}] ${product.product_name}` : `${product.product_name}`
    if (product.product_code) label = label.concat(` | ${product.product_code}`)

    return {
      label: label,
      value: product.id,
    }
  })
})

const ancillaryPlansOpts = computed(() => {
  return products.value.filter((product) => {
    return product.company == 'GTL' || product.company == 'NCD'
  }).map(product => {
    return {
      company: product.company,
      label: product.product_name,
      value: product.id,
    }
  })
})

const effectiveDateOpts = []
const showEffectiveDateNextYear = currentMonthNumber >= 10
for (let index = currentMonthNumber; index <= 12; index++) {
  effectiveDateOpts.push({
    label: `${index}/1/${currentYear}`,
    value: `${index}/1/${currentYear}`,
  })
}

if ( showEffectiveDateNextYear ) {
  const nextYear = dayjs().add(1, 'Year').format('YY')
  for (let index = 1; index <= 3; index++) {
    effectiveDateOpts.push({
      label: `${index}/1/${nextYear}`,
      value: `${index}/1/${nextYear}`,
    })
  }
}
function submitForm() {
  formElement.value.requestSubmit()
}
</script>

<template>
  <SlideOver
    class="z-20"
    title="Create Policy"
    :open="open"
    @close="emit('close')"
  >
    <template #default>
      <div class="relative ">
        <div v-if="form.processing" class="absolute z-10 flex items-center justify-center w-full h-full bg-gray-300 bg-opacity-60 ">
          <div class="mt-1 lds-ring">
            <div /><div /><div /><div />
          </div>
        </div>
        <div class="px-5 pb-8">
          <div class="pt-4 border-t">
            <div class="p-5 space-y-4 bg-blue-100 rounded ">
              <p class="text-gray-800 lg:text-sm">
                <span class="block text-lg font-semibold">{{ form.contact.first_name }} {{ form.contact.last_name }}</span>
              </p>
              <div class="flex justify-between">
                <p class="text-gray-800">
                  <span class="block text-sm">Type</span>
                  <span class="block font-semibold">{{ form.contact.plan_type }}</span>
                </p>
                <p class="text-gray-800">
                  <span class="block text-sm">Mobile Phone</span>
                  <span class="block font-semibold">{{ formatPhone(form.contact.mobile) }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="pt-9">
            <h3 class="text-xl font-semibold text-gray-800 ">
              Policy Info
            </h3>
            <form ref="formElement" class="space-y-3" @submit.prevent="emit('submit')">
              <SelectInput
                v-model="planType"
                label="Type"
                required
              >
                <option value="" diabled selected>
                  Select an type
                </option>
                <option v-for="planTypeOpt in planTypesOpts" :key="planTypeOpt.id" :value="planTypeOpt.id">
                  {{ planTypeOpt.name }}
                </option>
              </SelectInput>

              <div v-if="planType == 'Medicare'" class="flex-wrap lg:flex lg:-mx-3">
                <div class="w-full lg:px-3">
                  <label class="block text-sm font-medium text-gray-700">New to Medicare</label>
                  <span class="relative z-0 inline-flex w-full pt-1 rounded-md shadow-sm">
                    <button
                      :class="form.policy.new_to_medicare ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                      type="button"
                      class="relative inline-flex items-center justify-center flex-1 px-4 py-3 text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      @click="form.policy.new_to_medicare = true "
                    >
                      Yes
                    </button>
                    <button
                      :class="!form.policy.new_to_medicare ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                      type="button"
                      class="relative inline-flex items-center justify-center flex-1 px-4 py-3 -ml-px text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      @click="form.policy.new_to_medicare = !form.policy.new_to_medicare"
                    >
                      No
                    </button>
                  </span>
                </div>
              </div>
              <TextInput
                v-model="form.policy.application_submitted"
                label="Sold Date"
                type="date"
                required
                :errors="form.errors.application_submitted"
              />
              <SelectInput
                v-if="planType != 'Life'"
                v-model="form.policy.new_renewal"
                label="New Business, Renewal"
                required
                :errors="form.errors.new_renewal"
              >
                <option value="" disabled>
                  Select a option
                </option>
                <option v-for="(newRenewalopt, index) in newRenewal.slice(1)" :key="index" :value="newRenewalopt.id">
                  {{ newRenewalopt.display_value }}
                </option>
              </SelectInput>
              <SelectInput
                v-else
                v-model="form.policy.term"
                label="Term"
                required
                :errors="form.errors.term"
              >
                <option value="" disabled>
                  Select a option
                </option>
                <option v-for="(policyTermsOpt, index) in policyTerms.slice(1)" :key="index" :value="policyTermsOpt.id">
                  {{ policyTermsOpt.display_value }}
                </option>
              </SelectInput>
              <SelectInput
                v-model="form.company_name"
                label="Company"
                required
                :errors="form.errors.company_name"
              >
                <option v-if="companyOpts.length >= 1" value="" disabled>
                  Select a Company
                </option>
                <option v-else value="" disabled>
                  {{ stateObject ? `No plans available in ${stateObject.name}` : 'No plans available - State is missing' }}
                </option>

                <option v-for="(company, index) in companyOpts" :key="index" :value="company.value">
                  {{ company.label }}
                </option>
              </SelectInput>
              <SelectInput
                v-model="form.policy.product_name"
                label="Plan Name"
                required
                :errors="form.errors.product_name"
              >
                <option v-if="companyOpts.length >= 1" value="" disabled>
                  Select a Plan Name
                </option>
                <option v-else value="" disabled>
                  {{ stateObject ? `No plans available in ${stateObject.name}` : 'No plans available - State is missing' }}
                </option>
                <option v-for="(plan, index) in planNameOpts" :key="index" :value="plan.value">
                  {{ plan.label }}
                </option>
              </SelectInput>
              <SelectInput
                v-if="planType != 'Life'"
                v-model="form.policy.effective_date"
                label="Effective Date"
                required
                :errors="form.errors.effective_date"
              >
                <option value="" disabled>
                  Select a Date
                </option>
                <option v-for="(effectiveDate, index) in effectiveDateOpts" :key="index" :value="effectiveDate.value">
                  {{ effectiveDate.label }}
                </option>
              </SelectInput>
              <SelectInput
                v-else
                v-model="form.policy.source_of_funds"
                label="Source of Funds"
                required
                :errors="form.errors.source_of_funds"
              >
                <option value="" disabled>
                  Select a option
                </option>
                <option v-for="(sourceOfFundsOpt, index) in sourceOfFunds.slice(1)" :key="index" :value="sourceOfFundsOpt.id">
                  {{ sourceOfFundsOpt.display_value }}
                </option>
              </SelectInput>
              <div v-if="planType == 'Medicare' || planType == 'ACA'" class="w-full lg:px-3">
                <label class="block text-sm font-medium text-gray-700">Did you also sell an ancillary plan?</label>
                <span class="relative z-0 inline-flex w-full pt-1 rounded-md shadow-sm">
                  <button
                    :class="form.ancillary_plans.have_any_plan ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                    type="button"
                    class="relative inline-flex items-center justify-center flex-1 px-4 py-3 text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    @click="form.ancillary_plans.have_any_plan = true"
                  >
                    Yes
                  </button>
                  <button
                    :class="!form.ancillary_plans.have_any_plan ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
                    type="button"
                    class="relative inline-flex items-center justify-center flex-1 px-4 py-3 -ml-px text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    @click="form.ancillary_plans.have_any_plan = false"
                  >
                    No
                  </button>
                </span>
              </div>
              <SelectInput
                v-if="form.ancillary_plans.have_any_plan"
                v-model="form.ancillary_plans.plan"
                label="Ancillary Plan"
                required
                :errors="form.errors.ancillary_plans"
              >
                <option value="" disabled>
                  Select a Plan
                </option>
                <option v-for="(ancillaryPlan, index) in ancillaryPlansOpts" :key="index" :value="ancillaryPlan">
                  {{ ancillaryPlan.label }}
                </option>
              </SelectInput>
              <TextInput
                v-if="planType == 'Life'"
                v-model.lazy="form.policy.amount"
                v-money="amountMoneyFormat"
                label="Amount"
                required
                :errors="form.errors.amount"
              />
              <div v-if="form.contact.need_update">
                <h3 class="text-lg font-bold leading-tight text-gray-800 lg:text-xl">
                  Sensitive Info
                </h3>
                <TextInput
                  v-model="form.contact.date_of_birth"
                  label="Date of Birth"
                  type="date"
                  required
                  :errors="form.errors.date_of_birth"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end flex-shrink-0 px-4 py-4 space-x-3">
        <button
          class="px-4 py-2 text-sm border-2 button-outline button-rounded"
          @click="emit('close')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary disabled:bg-[#7d96cd] disabled:cursor-not-allowed"
          :disabled="form.processing"
          @click="submitForm"
        >
          Submit
        </button>
      </div>
    </template>
  </SlideOver>
</template>