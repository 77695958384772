<script setup>
import { toRefs, watch } from 'vue'
import Icon from '@/Shared/Icon.vue'
import SlideOver from '@/Shared/SlideOver.vue'
import SecurityInputsBlock from '@/Shared/SecurityInputsBlock.vue'
import useSecureModal from '@/composables/useSecureModal.js'
const props = defineProps({
  showInfoOnModal: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['close', 'isvalidated'])
const { open, showInfoOnModal } = toRefs(props)

const {
  codeLength,
  code,
  completedCode,
  validatingCode,
  validCodeClass,
  showResendBtn,
  isValidateDisabled,
  showModalSlot,
  validateCode,
  resendCode,
} = useSecureModal(emit, showInfoOnModal)

watch(open, (value) => {
  if (value == false) {
    showModalSlot.value = false
  }
})

</script>

<template>
  <SlideOver
    title="Verifying your identity"
    :open="open"
    @close="emit('close')"
  >
    <template #default>
      <div class="px-6 mt-16">
        <div>
          <div class="flex items-center justify-center mx-auto bg-gray-300 rounded-full h-25 w-25">
            <div>
              <Icon
                v-if="!validatingCode"
                name="lock"
                :class="validCodeClass"
                class="w-16 h-20 fill-current "
              />
              <div v-else class="mt-1 lds-ring">
                <div /><div /><div /><div />
              </div>
            </div>
          </div>
          <div v-if="showModalSlot">
            <slot />
          </div>
          <div v-else>
            <SecurityInputsBlock
              v-model="code"
              class="flex justify-center pt-6 space-x-3"
              :size="codeLength"
              @completed="completedCode == true"
            />

            <div class="flex flex-col justify-center w-full text-center">
              <p class="pt-4 font-semibold text-gray-900">
                We sent you a code!
              </p>
              <p class="px-8 pt-2 text-sm text-gray-900">
                In order to safeguard our client's information, we need to verify you are {{ $page.props.auth.user.name }}. Please enter the code we sent to your cell phone ending in <strong>{{ $page.props.auth.user.phone.slice(-4) }}</strong>. Do not share this code with anyone else. Once you click on Validate, we will show you the data you requested. It will be logged for security purposes.
              </p>
            </div>
            <div v-if="showResendBtn" class="flex justify-center mt-3">
              <button
                type="button"
                class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary disabled:bg-[#7d96cd] disabled:cursor-not-allowed"
                @click=" resendCode"
              >
                Resend code
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end flex-shrink-0 px-4 py-4 space-x-3">
        <button
          class="px-4 py-2 text-sm border-2 button-outline button-rounded"
          @click="emit('close')"
        >
          Cancel
        </button>
        <button
          v-if="showModalSlot"
          type="button"
          class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary disabled:bg-[#7d96cd] disabled:cursor-not-allowed"
          @click="emit('close')"
        >
          Close
        </button>
        <button
          v-else
          type="button"
          class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary disabled:bg-[#7d96cd] disabled:cursor-not-allowed"
          :disabled="isValidateDisabled"
          @click=" validateCode"
        >
          Validate
        </button>
      </div>
    </template>
  </SlideOver>
</template>