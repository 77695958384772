import { computed } from 'vue'
import { inCurrentYear } from '@/utils/dates.js'

export default function useClientsCard(clients) {

  const activeClients = computed(() => {
    return clients.value.filter((client) => {
      return client.active_client
    }).length
  })

  const newClients = computed(() => {
    return clients.value.filter((client) => {
      return client.active_client == true && client.enrollment_year != null && inCurrentYear(client.enrollment_year)
    }).length
  })

  const renewalClients = computed(() => {
    return clients.value.filter((client) => {
      return client.active_client == true && client.enrollment_year != null && !inCurrentYear(client.enrollment_year)
    }).length
  })

  return {
    activeClients,
    newClients,
    renewalClients,
  }
}