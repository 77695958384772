<script setup>
import { ref, watch, toRefs } from 'vue'
import * as routes from '@/utils/routes'
import { formatDate } from '@/utils/dates'
import { omit } from '~/utils/helpers'
import { blank, moneyFormat } from '@/utils/filters'
import LeadForm from './Form.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/inertia-vue3'
import StatusBadge from '@/Shared/StatusBadge.vue'
import SecurityModal from '@/Shared/SecurityModal.vue'
import RecordCallButton from '@/Shared/RecordCallButton.vue'
import useLeadOrClientInfo from '@/composables/useLeadOrClientInfo'
import useSecureModalCard from '@/composables/useSecureModalCard'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

const props = defineProps({
  lead: Object,
})

const { lead } = toRefs(props)
const updateLeadLoading = ref(false)
const open = ref(false)

const form = useForm({
  lead: omit(lead.value, ['type']),
})

/* For some reason the form is not updating the lead object
 * when the lead is updated from the server. So we need to
 * watch for changes in the lead prop and update the form
 * manually.
 */
watch(lead, () => {
  form.lead = omit(lead.value, ['type'])
})

const {
  openSecurityModal,
  showDOBBtn,
  showSSNBtn,
  showSMNBtn,
  sendSecurityCode,
  showClickedLabel,
} = useSecureModalCard()

const {
  fullName,
  email,
  plan,
  formatedPhone,
  addressInfo,
  formatedOtherPhone,
  cityState,
  zipCode,
  ssn,
  medicareNumber,
  source,
  googleMapsUrl,
  intakeDate,
  yearlyIncome,
  language,
} = useLeadOrClientInfo(lead)

const updateLead = () => {
  if (form.lead.date_of_birth != null) {
    form.lead.date_of_birth = formatDate(form.lead.date_of_birth, 'YYYY-MM-DD')
  }

  if (form.lead.spouse != null) {
    form.lead.spouse = form.lead.spouse.id
  }

  form.put(routes.lead(form.lead.id), {
    onSuccess: () => {
      open.value = false
      updateLeadLoading.value = false
    },
  })
}

const archiveLead = () => {
  if (confirm(`Archive ${fullName.value}?`)) {
    form.lead.lead_status_agent = 'Archive'
    form.lead.agent_archived = true
    form.put(routes.lead(form.lead.id))
  }
}

</script>

<template>
  <div class="p-5 bg-white rounded-lg shadow-sm">
    <div class="relative flex justify-center">
      <h1 class="w-56 pl-8 text-2xl font-bold text-center text-gray-900 lg:pl-0">
        {{ fullName }}
      </h1>
      <div v-if="!lead.agent_archived" class="inset-y-0 right-0 pl-3 lg:absolute lg:pl-0">
        <button
          class="text-hartblue focus:outline-none"
          @click="open = true"
        >
          <Icon
            name="edit"
            class="w-5 h-5 fill-current"
          />
        </button>
      </div>
    </div>
  </div>

  <div>
    <div class="pt-6 lg:pt-4">
      <TabGroup>
        <TabList>
          <div class="flex space-x-1 lg:block">
            <Tab v-slot="{ selected }" class="w-1/2 lg:w-auto">
              <h5 :class="[selected ? 'active' : 'inactive']" class="tab">
                General
              </h5>
            </Tab>
            <Tab v-slot="{ selected }" class="w-1/2 lg:w-auto">
              <h5 :class="[selected ? 'active' : 'inactive']" class="tab">
                More
              </h5>
            </Tab>
          </div>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div class="p-5 bg-white rounded-b-lg rounded-r-lg shadow-sm">
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Full Name:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ fullName }}
                  </p>
                </div>
              </div>
              <div class="flex items-center py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Status:</span>
                </div>
                <div class="flex justify-end flex-1 lg:block">
                  <StatusBadge :dropdown="false" :status="lead.lead_status_agent" />
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Sold Plan:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.sold_plan) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Email:</span>
                </div>
                <div class="flex-1 block text-right lg:text-left">
                  <a :href="`mailto:${email}`" class="text-sm font-medium text-hartblue">
                    {{ email }}
                  </a>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Mobile Phone:</span>
                </div>
                <div class="flex">
                  <a :href="`tel:${lead.mobile}`" class="flex justify-end text-sm font-medium lg:justify-start text-hartblue">
                    {{ formatedPhone }}
                    <Icon
                      name="phone"
                      class="w-5 h-5 ml-1 fill-current text-hartblue"
                    />
                  </a>
                  <RecordCallButton v-if="formatedPhone != '--'" :client="lead" phone-field="mobile" />
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Home Phone:</span>
                </div>
                <div class="flex">
                  <a :href="`tel:${lead.other_phone}`" class="flex justify-end text-sm font-medium lg:justify-start text-hartblue">
                    {{ formatedOtherPhone }}
                    <Icon
                      name="phone"
                      class="w-5 h-5 ml-1 fill-current text-hartblue"
                    />
                  </a>
                  <RecordCallButton v-if="formatedOtherPhone != '--'" :client="lead" phone-field="other_phone" />
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Address:</span>
                </div>
                <div class="flex-1 block">
                  <a
                    :href="googleMapsUrl"
                    class="flex justify-start text-sm transition duration-300 ease-out lg:justify-start text-hartblue"
                  >
                    {{ addressInfo }}
                    <Icon
                      name="location"
                      class="w-5 h-5 ml-1 fill-current text-hartblue"
                    />
                  </a>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">City/State:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ cityState }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">ZIP Code:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ zipCode }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Language:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ language }}
                  </p>
                </div>
              </div>
              <div v-if="lead.spouse != null" class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Spouse:</span>
                </div>
                <div class="flex-1 block">
                  <a :href="routes.activity_lead(lead.spouse.id)" class="flex justify-end text-sm font-medium lg:justify-start text-hartblue">
                    {{ lead.spouse.name }}
                    <Icon
                      name="users-alt"
                      class="w-5 h-5 ml-1 fill-current text-hartblue"
                    />
                  </a>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Type:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ plan }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Lead Source:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ source }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Last Activity:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ formatDate(lead.modified_time, 'MM/DD/YYYY') }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Emergency Contact Name:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.emergency_contact_name) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Emergency Contact Phone:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.emergency_contact_phone) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Permanently Disabled:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ lead.permanently_disabled ? 'Yes' : 'No' }}
                  </p>
                </div>
              </div>
              <h5 class="pt-4 pb-2 font-semibold text-gray-900">
                Sensitive Info
              </h5>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">DOB:</span>
                </div>
                <div class="flex-1 block text-right lg:text-left">
                  <button
                    v-if="showDOBBtn && lead.date_of_birth != null"
                    class="text-sm font-medium text-hartblue"
                    @click="sendSecurityCode('DOB', { title: 'DOB', content: formatDate(lead.date_of_birth, 'MM/DD/YYYY') })"
                  >
                    View
                  </button>
                  <p v-else class="text-sm font-medium text-right lg:text-left">
                    {{ formatDate(lead.date_of_birth, 'MM/DD/YYYY') }}
                  </p>
                </div>
              </div>

              <div class="pt-5">
                <button v-if="!lead.agent_archived" class="block px-6 py-2 mx-auto text-sm text-center border-2 border-hartblue button-rounded button-outline" @click="archiveLead()">
                  Archive
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div class="p-5 bg-white rounded-b-lg rounded-r-lg shadow-sm">
              <h5 class="pt-4 pb-2 font-semibold text-gray-900">
                Income / Public Aid
              </h5>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Social Security:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ moneyFormat(lead.social_security) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Spend Down:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ moneyFormat(lead.spend_down) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Additional Pension:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ moneyFormat(lead.additional_pension) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Rents:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ moneyFormat(lead.rents) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Wages:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ moneyFormat(lead.wages) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Self-Employment:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ moneyFormat(lead.self_employment) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Other Income:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ moneyFormat(lead.monthly_income) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Yearly Income:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ moneyFormat(yearlyIncome) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">LIS:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ lead.lis?'Yes':'No' }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">LIS Level:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.lis_level) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">LIS Percentage:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.lis_percentage) }}
                  </p>
                </div>
              </div>
              <h5 class="pt-4 pb-2 font-semibold text-gray-900">
                Intake
              </h5>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Intake Date:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ intakeDate }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Current Medications:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.medications) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Preferred Benefits:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.preferred_benefits) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Specialists:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.specialists) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Medicare:</span>
                </div>
                <div class="flex-1 block">
                  <ul v-if="lead.already_has_medicare.length > 0">
                    <li v-for="(medicareOpt, index) in lead.already_has_medicare" :key="index">
                      <p class="text-sm font-medium text-right lg:text-left">
                        {{ blank(medicareOpt) }}
                      </p>
                    </li>
                  </ul>
                  <p v-else class="text-sm font-medium text-right lg:text-left">
                    --
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Medicaid Program:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.medicaid_program) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Medicaid Number:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.medicaid_number) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Current Plan/Carrier:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.plan_carrier_at_intake) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">PCP Name:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.pcp_name) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">PCP Phone:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.pcp_phone) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Interested In:</span>
                </div>
                <div class="flex-1 block">
                  <ul v-if="lead.interested_in.length > 0">
                    <li v-for="(interest, index) in lead.interested_in" :key="index">
                      <p class="text-sm font-medium text-right lg:text-left">
                        {{ blank(interest) }}
                      </p>
                    </li>
                  </ul>
                  <p v-else class="text-sm font-medium text-right lg:text-left">
                    --
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">SEP:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.sep) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Medical Conditions:</span>
                </div>
                <div class="flex-1 block">
                  <ul v-if="lead.medical_conditions.length > 0">
                    <li v-for="(condition, index) in lead.medical_conditions" :key="index">
                      <p class="text-sm font-medium text-right lg:text-left">
                        {{ blank(condition) }}
                      </p>
                    </li>
                  </ul>
                  <p v-else class="text-sm font-medium text-right lg:text-left">
                    --
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Part A:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.part_a) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3 border-b">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Part B:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ blank(lead.part_b) }}
                  </p>
                </div>
              </div>
              <div class="flex py-3">
                <div class="block w-24">
                  <span class="text-xs text-gray-700">Has Medicaid:</span>
                </div>
                <div class="flex-1 block">
                  <p class="text-sm font-medium text-right lg:text-left">
                    {{ lead.has_medicaid ? 'Yes' : 'No' }}
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>

    <LeadForm
      v-model="form"
      :open="open"
      @submit="updateLead"
      @close="open = false"
    />

    <SecurityModal
      :open="openSecurityModal"
      :show-info-on-modal="true"
      @close="openSecurityModal = false"
      @isvalidated="showClickedLabel({ id: lead.id, agent_name: $page.props.auth.user.name, module: 'Lead', redirect_to: routes.lead(lead.id) })"
    >
      <div class="flex flex-col justify-center w-full text-center">
        <h2 v-if="!showSMNBtn" class="pt-4 text-2xl font-semibold text-gray-900">
          Medicare #: {{ medicareNumber }}
        </h2>
        <h2 v-if="!showSSNBtn" class="pt-4 text-2xl font-semibold text-gray-900">
          SSN: {{ ssn }}
        </h2>
      </div>
    </SecurityModal>
  </div>
</template>
