import { ref } from 'vue'
import * as routes from '@/utils/routes.js'
import { useForm } from '@inertiajs/inertia-vue3'
export default function usePushNotifications(vapidKey) {

  const showbannerNoti = ref(false)
  const showPushForm = ref(false)

  const form = useForm({
    push: {
      name: null,
      email: null,
    },
    subscription_data: {},
  })

  const url = window.location.href
  let swLocation = '/sw.js'
  let swReg

  function start() {
    if ( navigator.serviceWorker ) {
      if ( url.includes('localhost') ) {
        swLocation = '/sw.js'
      }

      window.addEventListener('load', function() {
        navigator.serviceWorker.register( swLocation )
          .then(function(reg) {
            swReg = reg
            swReg.pushManager.getSubscription()
              .then( verifySubscrition )
          })
      })

      if (localStorage.getItem('tempPayload')) {
        form.subscription_data = JSON.parse(localStorage.getItem('tempPayload'))
      }

    }
  }

  function verifySubscrition(active) {
    if (active) {
      showbannerNoti.value = true
    } else {
      showPushForm.value = true
      localStorage.removeItem('tempPayload')
      form.subscription_data = {}
    }
  }

  function getPublicKey() {
    return new Promise((resolve) => {
      resolve(new Uint8Array(vapidKey))
    })
  }

  function registerSuscribtion() {
    if (!swReg) return console.error('No existe el registro del sw')
    getPublicKey().then(function(key) {
      swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: key,
      }).then(res => res.toJSON())
        .then(suscription => {
          localStorage.setItem('tempPayload', JSON.stringify(suscription))
          form.subscription_data = suscription
          showbannerNoti.value = true
          verifySubscrition(suscription)
        })
    })
  }

  function registerForm() {
    if (showbannerNoti.value && localStorage.getItem('tempPayload')) {
      form.post(routes.push_register(), {
        onSuccess: () => form.reset(),
      })
    }
  }

  return {
    form,
    start,
    registerSuscribtion,
    registerForm,
    showbannerNoti,
    showPushForm,
  }
}