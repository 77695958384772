<script setup>
import { ref, toRefs } from 'vue'
import Logo from '@/Shared/Logo.vue'
import Form from './Components/Form.vue'
import { useForm } from '@inertiajs/inertia-vue3'
import * as routes from '@/utils/routes'

import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import luxon2Plugin from '@fullcalendar/luxon2'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import iCalendarPlugin from '@fullcalendar/icalendar'
import interactionPlugin from '@fullcalendar/interaction'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { timezones, addTime } from '@/utils/dates.js'

const props = defineProps({
  url: String,
  appointment: Object,
  agent: String,
})

const { appointment, agent, url } = toRefs(props)

const openForm = ref(false)
const openRedirectModal = ref(false)
const calendarApi = ref({})
const recordUrl = `https://crm.zoho.com/crm/hartland1/tab/Potentials/${appointment.value.id}`

const timezone = ref(timezones[0])

const calendarOptions = ref({
  plugins: [luxon2Plugin, dayGridPlugin, timeGridPlugin, interactionPlugin, iCalendarPlugin],
  timeZone: 'local',
  initialView: 'timeGridWeek',
  slotMinTime: '08:00:00',
  slotMaxTime: '20:59:59',
  slotDuration: '00:30:00',
  slotLabelInterval: '00:30:00',
  expandRows: true,
  selectable: true,
  events: {
    url: `/calendar?url=${url.value}`,
    format: 'ics',
  },
  headerToolbar: {
    end: 'today',
    center: 'prev,title,next',
    start: 'dayGridMonth,timeGridWeek,timeGridDay',
  },
  buttonText: {
    today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
  },
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
  },
  select: handleDateSelection,
})

const form = useForm({
  agent: agent.value,
  id: appointment.value.id,
  start: null,
  end: null,
  type: appointment.value.plan_type,
  location: appointment.value.type,
  office_location: appointment.value.office_location,
})

function handleDateSelection(data) {
  calendarApi.value = data.view.calendar

  if (data.view.type === 'dayGridMonth') {
    return calendarApi.value.changeView('timeGridDay', data.startStr)
  }
  
  form.start = data.startStr
  form.end = addTime(data.start, 1, 'Hour').format() 
  openForm.value = true
}

function handleFormSubmit() {
  form.post(routes.schedulings(), {
    onError: errors => {
      console.log(errors)
    },
    onSuccess: () => {
      addEventToCalendar()
      resetAndRedirect()
    },
  })
}

function addEventToCalendar() {
  calendarApi.value.addEvent({
    title: `${form.type} - ${appointment.value.contact_first_name} ${appointment.value.contact_last_name}`,
    start: form.start,
    end: form.end,
  })
}

function resetAndRedirect() {
  form.reset()
  openForm.value = false
  setTimeout(() => {
    openRedirectModal.value = true
  }, 500)
  setTimeout(() => {
    window.location.href = recordUrl
  }, 3500)
}
</script>

<template>
  <div class="min-h-screen bg-gray-100">
    <div class="bg-[#d24143] flex items-center">
      <div class="max-w-[120rem] mx-auto px-20 py-2.5 w-full flex items-center justify-between">
        <div class="flex items-center space-x-6">
          <Logo
            class="w-11"
            version="desktop"
          />
          <h1 class="text-xl font-medium text-white">
            Agent Scheduler
          </h1>
        </div>
        <div>
          <h3 class="text-sm font-medium text-white lg:text-lg">
            {{ agent }}'s Calendar
          </h3>
        </div>
      </div>
    </div>

    <div class="lg:pt-8 h-[calc(100vh-63.61px)] max-w-[120rem] mx-auto overflow-x-hidden">
      <div class="lg:px-20">
        <div class="py-6 mb-10 bg-white rounded-md lg:px-8">
          <div class="w-full px-2 pb-4 border-b border-gray-200 lg:flex lg:items-center lg:justify-between lg:pb-2 lg:px-0">
            <div>
              <a :href="recordUrl" class="flex items-center text-sm font-medium lg:text-base text-hartblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                </svg>
                Go to record
              </a>
            </div>
            <div class="flex items-center space-x-3">
              <label for="type" class="text-sm font-medium text-gray-900"> Timezone </label>
              <div>
                <select
                  id="type"
                  v-model="timezone"
                  class="block w-full border-gray-300 rounded-md shadow-sm invalid:text-gray-600 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  required
                  @change="calendarOptions.timeZone = timezone.value"
                >
                  <option :value="null" disabled selected>
                    Select a timezone
                  </option>
                  <option v-for="tz in timezones" :key="tz.value" :value="tz">
                    {{ tz.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="w-full mt-4 lg:mt-10">
            <FullCalendar class="h-[600px] md:h-auto" :options="calendarOptions" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Form
    v-model="form"
    :appointment="appointment"
    :open="openForm"
    @close="openForm = false"
    @submit="handleFormSubmit"
  />

  <TransitionRoot as="template" :show="openRedirectModal">
    <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="openRedirectModal = false">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </TransitionChild>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div class="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-base font-medium leading-6 text-gray-800">
                  Event scheduled successfully
                </DialogTitle>
                <div class="mt-2">
                  <p class="px-10 text-sm text-gray-600">
                    You are being redirected to the CRM, please wait...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style>
  div.fc-header-toolbar.fc-toolbar > div:nth-child(2) > div {
    @apply flex items-center space-x-4;
  }

  .fc .fc-button-primary {
    @apply bg-hartblue border-hartblue hover:bg-hartpurple text-sm;
  }

  .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
    @apply bg-hartpurple border-hartblue hover:bg-hartpurple;
  }

  .fc-prev-button, .fc-next-button {
    @apply !bg-transparent !border-transparent;
  }

  .fc .fc-button .fc-icon {
    @apply !text-hartblue;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    @apply px-2 lg:px-0;
  }

  .fc .fc-toolbar-title {
    @apply text-base md:text-lg text-center text-gray-800;
  }

  .fc .fc-daygrid-day.fc-day-today, .fc .fc-timegrid-col.fc-day-today {
    @apply bg-hartblue/10;
  }

  .fc-col-header-cell-cushion {
    @apply text-gray-800 font-semibold !py-2;
  }

  .fc-col-header-cell {
    @apply bg-gray-100;
  }

  .fc-daygrid-dot-event .fc-event-title {
    @apply font-medium;
  }

  .fc-direction-ltr .fc-daygrid-event .fc-event-time {
    @apply text-gray-600;
  }

  .fc .fc-timegrid-axis-cushion {
    @apply text-sm capitalize text-gray-500;
  }

  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    @apply text-center uppercase text-gray-500;
  }

  .fc .fc-button-primary:not(:disabled):focus {
    @apply !shadow-none;
  }

  .fc-header-toolbar.fc-toolbar > div:nth-child(3) {
    @apply !hidden md:!block;
  }
</style>