const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name',
}

const omit = (obj, keys) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([k]) => !keys.includes(k)),
  )
}

const formatPhone = (mobile) => {
  if (mobile == null) return 
  return `(${mobile.slice(0, 3)}) ${mobile.slice(3, 6)}-${mobile.slice(6)}`
}

const isEmpty = (property, show = '--') => {
  return property ? property : show
}
/**
 * Format result from Geo google APIs
 * @param place
 * @returns {{formatted output}}
 */
const formatPlaceToObject = (place) => {
  let returnData = {}
  for (let i = 0; i < place.address_components.length; i++) {
    let addressType = place.address_components[i].types[0]
    if (ADDRESS_COMPONENTS[addressType]) {
      let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]]
      returnData[addressType] = val
    }
  }
  returnData['latitude'] = place.geometry.location.lat()
  returnData['longitude'] = place.geometry.location.lng()
  return returnData
}

const debounce = (callback, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      callback(...args)
    }, wait)
  }
}

const scrollToEnd = ({ target }) => {
  target.scrollTop = target.scrollHeight
}

export {
  isEmpty,
  formatPhone,
  omit,
  formatPlaceToObject,
  debounce,
  scrollToEnd,
}