<script setup>
import { toRefs } from 'vue'
import Icon from '@/Shared/Icon.vue'

const props = defineProps({
  leads: Array,
  filters: Object,
  modelValue: [Number, String],
})

const emit = defineEmits(['close', 'update:modelValue'])

const { filters } = toRefs(props)

const typeOptions = [
  { label: 'None', value: '' },
  { label: 'ACA', value: 'ACA' },
  { label: 'Medicare', value: 'Medicare' },
  { label: 'Life', value: 'Life' },
]

const dateRangeOptions = [
  { label: 'No filter', value: '' },
  { label: 'Today', value: 'today' },
  { label: 'This Week', value: 'this-week' },
  { label: 'Last Week', value: 'last-week' },
  { label: 'Next Week', value: 'next-week' },
  { label: 'This month', value: 'this-month' },
  { label: 'Last 30 days', value: 'last-thirty-days' },
  { label: 'Last Month', value: 'last-month' },
  { label: 'Current Year', value: 'this-year' },
]

const leadStatusOptions = [
  { label: 'All', value: '' },
  { label: 'No Answer', value: 'No Answer' },
  { label: 'NQ', value: 'NQ' },
  { label: 'Not Interested', value: 'Not Interested' },
  { label: 'Follow Up (Tomorrow)', value: 'Follow Up (Tomorrow)' },
  { label: 'Follow Up (2 Days)', value: 'Follow Up (2 Days)' },
  { label: 'Follow Up (1 Month)', value: 'Follow Up (1 Month)' },
  { label: 'Send to Schedulers', value: 'Send to Schedulers' },
  { label: 'Qualified', value: 'Qualified' },
]

const clearFilters = () => {
  filters.value.type = ''
  filters.value.leadStatus = ''
  filters.value.assigned = ''

}

const close = () => {
  emit('close')
}
</script>

<template>
  <div>
    <div class="relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <Icon name="search" class="w-4 h-4 text-gray-500 pointer-events-none fill-current" />
      </div>
      <input
        id="email"
        type="text"
        class="block w-full pl-10 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        placeholder="Search lead..."
        @input="emit('update:modelValue', $event.target.value)"
      >
    </div>

    <div class="flex justify-between pt-8">
      <h2 for="period" class="block mb-1 text-xl font-semibold text-white lg:text-gray-900">
        Filter by
      </h2>
      <button class="text-gray-600" @click="clearFilters">
        Clear filters
      </button>
    </div>

    <div class="pt-4">
      <label for="type" class="block pb-1 font-semibold text-gray-900">Type</label>
      <select id="type" v-model="filters.type" class="form-underline">
        <option v-for="(option, index) in typeOptions" :key="index" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <div class="pt-4">
      <label for="leadStatusOpt" class="block pb-1 font-semibold text-gray-900">Lead Status</label>
      <select id="leadStatusOpt" v-model="filters.leadStatus" class="form-underline">
        <option v-for="(option, index) in leadStatusOptions" :key="index" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <div class="pt-4">
      <label for="assigned" class="block pb-1 font-semibold text-gray-900">Assigned</label>
      <select id="assigned" v-model="filters.assigned" class="form-underline">
        <option v-for="(option, index) in dateRangeOptions" :key="index" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <slot />
    <!-- <div class="hidden pt-16 lg:block">
      <button class="w-full px-4 py-2 text-blue-400 border-2 border-blue-400 button-rounded" @click="clearFilters">
        Clear Filters
      </button>
    </div> -->
    <div class="flex justify-center pt-10 mx-4 lg:hidden">
      <button
        class="w-full px-6 py-2 leading-relaxed button-rounded button-primary"
        @click="close"
      >
        Apply Filters
      </button>
    </div>
  </div>
</template>