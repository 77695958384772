<script setup>
import axios from 'axios'
import { toRefs, computed } from 'vue'
import { formatDate } from '@/utils/dates.js'
import StatusTaskBadge from '@/Shared/StatusTaskBadge.vue'
import * as routes from '@/utils/routes.js'
import { Inertia } from '@inertiajs/inertia'
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon.vue'

const props = defineProps({
  task: Object,
})

const { task } = toRefs(props)

const updateStatusTask = (status) => {
  task.value.status = status.name
  axios.put(
    routes.task_statuses(task.value.id, { format: 'json' }),
    { status: status.name })
    .then(() => {
      Inertia.reload()
    })
}

const clientOrLeadUrl = computed(() => {
  if (task.value.client_type == 'Client') {
    return routes.activity_client(task.value.client_id.id)
  }

  if (task.value.client_type == 'Lead') {
    return routes.activity_lead(task.value.client_id.id)
  }
  return '#'
})

const fullName = computed(() => {
  return `${task.value.client_first_name} ${task.value.client_last_name}`
})
</script>

<template>
  <tr>
    <td class="lg:pl-2 table-item">
      <Icon
        v-if="task.client_id != null"
        name="users-alt"
        class="p-1 ml-1 rounded-full fill-current lg:block bg-blue-50 w-7 h-7 text-hartblue"
      />
    </td>
    <td class="py-5 pl-4 pr-4 text-xs table-item lg:text-sm">
      <div v-if="task.client_id != null">
        <p class="text-gray-900">
          {{ task.subject }}
        </p>
        <dl class="font-normal">
          <dt class="sr-only">
            Client full name
          </dt>
          <dd class="mt-1 text-gray-700 truncate">
            <Link
              :href="clientOrLeadUrl"
              class="font-semibold whitespace-nowrap text-hartblue lg:flex text-xsm lg:text-sm"
            >
              {{ fullName }}
            </Link>
          </dd>
        </dl>
      </div>
      <p v-else class="text-gray-900">
        {{ task.subject }}
      </p>
      <dl class="font-normal lg:hidden">
        <dt class="sr-only">
          Due Date
        </dt>
        <dd class="mt-1 text-gray-700 truncate">
          {{ formatDate(task.due_date, 'MM/DD/YYYY') }}
        </dd>
      </dl>
    </td>
    <td class="hidden px-4 py-5 text-xs table-item lg:text-sm lg:table-cell">
      <p class="text-gray-900">
        <span>{{ formatDate(task.due_date, 'MM/DD/YYYY') }}</span>
      </p>
    </td>
    <td class="hidden px-4 py-5 table-item lg:table-cell">
      <p class="text-sm text-left text-gray-900">
        {{ task.description }}
      </p>
    </td>
    <td class="px-4 py-5 table-item">
      <StatusTaskBadge :status="task.status" @change="updateStatusTask" />
    </td>
  </tr>
</template>