<script setup>
import { ref, toRefs, computed } from 'vue'
import { breakpointsTailwind, useBreakpoints, useEventListener } from '@vueuse/core'
import { formatDate, timezones } from '@/utils/dates.js'
import { isEmpty } from '@/utils/helpers.js'
import * as routes from '@/utils/routes'
import StatusBadge from '@/Shared/StatusBadge.vue'
import Icon from '@/Shared/Icon.vue'
import dayjs from 'dayjs'

const props = defineProps({
  appointments: Object,
  dateOptions: Object,
})

const { appointments, dateOptions } = toRefs(props)

const formatOfDate = 'MM/DD/YYYY'
const localTimeZone = ref(timezones[0])
const dateSelected = ref(dayjs(dateOptions.value.date_selected).format(formatOfDate))
const searchQuery = ref('')
const agentsList = ref([])
const breakpoints = useBreakpoints(breakpointsTailwind)

useEventListener(document, 'keydown', (e) => {
  const startOfWeek = dayjs(dateOptions.value.date_selected).day(0).subtract(1, 'day').format(formatOfDate)
  const endOfWeek = dayjs(dateOptions.value.date_selected).day(6).add(1, 'day').format(formatOfDate)
  let canMove = null

  if (e.key == 'ArrowRight') {
    canMove = dayjs(dateSelected.value).add(1, 'day').isBetween(startOfWeek, endOfWeek, null, '()')
    if (canMove) {
      dateSelected.value = dayjs(dateSelected.value).add(1, 'day').format(formatOfDate)
    } else {
      window.location = nextWeek.value
    }
  }

  if (e.key == 'ArrowLeft') {
    canMove = dayjs(dateSelected.value).subtract(1, 'day').isBetween(startOfWeek, endOfWeek, null, '()')
    if (canMove) {
      dateSelected.value = dayjs(dateSelected.value).subtract(1, 'day').format(formatOfDate)
    } else {
      window.location = previousWeek.value
    }
  }
})

const appointmentsOfTheWeek = computed(() => {
  let newsAppointments = {}

  if (dateOptions.value.show_calendar) {
    for (let i = 0; i <= 6; i++ ) {
      newsAppointments[dayjs(dateOptions.value.date_selected).day(i).format(formatOfDate)] = []
    }
  } else {
    newsAppointments[dayjs(appointments.value[0].start).format(formatOfDate)] = []
  }

  if (searchQuery.value && searchQuery.value.length > 3) {

    appointments.value.forEach((appointment) => {
      const date = dayjs(appointment.start).format(formatOfDate)
      if (appointment.agent.toLowerCase().includes(searchQuery.value.toLowerCase())) {
        newsAppointments[date].push(appointment)
      }
    })

  } else {
    appointments.value.forEach((appointment) => {
      const date = dayjs(appointment.start).format(formatOfDate)
      newsAppointments[date].push(appointment)
    })
  }

  return newsAppointments
})

const weekLabel = computed(() => {
  const date = dayjs(dateSelected.value)
  return `${date.day(0).format('MMM DD')} - ${date.day(6).format('MMM DD')}`
})

const selectDayOfWeek = (day) => {
  dateSelected.value = day
}

const openCrmAppointment = (id) => {
  window.open(`https://crm.zoho.com/crm/hartland1/tab/Potentials/${id}`)
}

const timeZone = computed(() => {
  if ( localTimeZone.value.value == 'local' ) {
    return dayjs.tz.guess()
  }
  return localTimeZone.value.value
})

const previousWeek = computed(() => {
  const startOfWeek = dayjs(dateOptions.value.date_selected).day(0).subtract(1, 'day').format('DD/MM/YYYY')
  return routes.calendar_schedulings({ week: startOfWeek, tz: timeZone.value })
})

const nextWeek = computed(() => {
  const startOfWeek = dayjs(dateOptions.value.date_selected).day(6).add(1, 'day').format('DD/MM/YYYY')
  return routes.calendar_schedulings({ week: startOfWeek, tz: timeZone.value })
})

const checkUrlTimeZone = () => {
  const url = new URL(window.location)
  let tzIndex = 0
  if (url.searchParams.has('tz')) {
    const urlTimeZone = url.searchParams.get('tz')
    tzIndex = timezones.findIndex((tz) => { return tz.value == urlTimeZone})
    if (tzIndex == -1) {
      tzIndex = 0
    }
  }
  
  localTimeZone.value = timezones[tzIndex]
}

const checkDay = () => {
  if (!dateOptions.value.show_calendar) {
    dateSelected.value = dayjs(appointments.value[0].start).format(formatOfDate)
  }
}

const generateAgentArray = () => {
  const agents = appointments.value.map(appointment => {
    return appointment.agent
  })
  agentsList.value = new Set(agents)
}

checkUrlTimeZone()
checkDay()
generateAgentArray()
</script>

<template>
  <div class="h-screen pb-10 overflow-y-scroll bg-gray-100">
    <div class="lg:pt-8 min-h-screen max-w-[120rem] mx-auto overflow-x-hidden">
      <div class="lg:px-20">
        <div class="py-8 mb-2 bg-white rounded-md lg:px-8">
          <div v-if="dateOptions.show_calendar">
            <div class="flex items-center justify-center px-2 pb-5 md:justify-between lg:px-0">
              <div class="hidden w-[30rem]  md:block">
                <select
                  id="type"
                  v-model="localTimeZone"
                  class="border-gray-300 rounded-md shadow-sm invalid:text-gray-600 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm inline-block w-60 mr-3"
                >
                  <option :value="null" disabled selected>
                    Select a timezone
                  </option>
                  <option v-for="tz in timezones" :key="tz.value" :value="tz">
                    {{ tz.label }}
                  </option>
                </select>
                <a :href="routes.calendar_schedulings()" class="inline-block px-3 py-2 text-sm border-2 rounded-md border-hartblue button-primary">
                  Current Day
                </a>
              </div>
              <div class="flex items-center justify-center space-x-4">
                <a :href="previousWeek" class="text-2xl text-blue-600">&lt;</a>
                <h2 class="md:text-lg xl:text-xl ">
                  {{ weekLabel }}
                </h2>
                <a :href="nextWeek" class="text-2xl text-blue-600">&gt;</a>
              </div>
              <div class="hidden w-[30rem] md:flex justify-end ">
                <div class="relative rounded-md shadow-sm w-60">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <Icon name="search" class="w-4 h-4 text-gray-500 pointer-events-none fill-current" />
                  </div>
                  <input
                    id="searchBox"
                    v-model="searchQuery"
                    type="search"
                    class="block w-full pl-10 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Search by Agent"
                    list="agents-list"
                  >
                </div>
                <datalist id="agents-list">
                  <option v-for="(agent, index) in agentsList" :key="index" :value="agent">
                    {{ agent }}
                  </option>
                </datalist>
              </div>
            </div>
            <div class="w-full px-2 pb-4 mb-8 border-b border-gray-200 lg:flex lg:items-center lg:justify-between lg:pb-8 lg:px-0">
              <div class="w-full grid grid-cols-1 md:grid-cols-7 border divide-y md:divide-y-0 md:divide-x divide-[#ddd] border-[#ddd]">
                <div v-for="(day, index) in appointmentsOfTheWeek" :key="index" class="flex md:block select-none">
                  <div class="bg-[#f3f4f6] text-center py-3 px-3 w-20 md:w-full flex md:block items-center md:border-b border-[#ddd]">
                    <h3 class="text-sm font-semibold xl:text-base">
                      {{ dayjs(index).format('ddd') }}
                    </h3>
                  </div>
                  <div :class="{ 'bg-gray-100': dateSelected == index }" class="relative w-full h-16 md:h-auto" @click="selectDayOfWeek(index)">
                    <div class="pt-1 pr-3 text-right">
                      <span class="text-gray-400">{{ dayjs(index).format('DD') }}</span>
                    </div>
                    <div class="flex flex-wrap content-start w-full h-16 px-3 md:h-25 xl:h-36" />
                    <div class="absolute top-0 flex items-center justify-center w-full h-full space-x-2 text-center text-gray-400 md:flex-col md:space-x-0">
                      <span class="text-xl md:text-3xl xl:text-5xl">{{ day.length }}</span>
                      <span class="text-sm font-semibold">Appointments</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full px-2 lg:px-0">
            <table class="w-full leading-normal text-left table-fixed lg:w-auto lg:min-w-full">
              <thead>
                <tr>
                  <th class="px-4 lg:text-center table-header gray-header md:blue-header">
                    Time
                  </th>
                  <th class="table-header gray-header md:blue-header">
                    Agent
                  </th>
                  <th class="px-4 table-header gray-header md:blue-header">
                    Client / Lead
                  </th>
                  <th class="hidden md:table-cell table-header gray-header md:blue-header">
                    Type
                  </th>
                  <th class="hidden md:table-cell table-header gray-header md:blue-header">
                    Location
                  </th>
                  <th class=" lg:text-center lg:w-56 table-header gray-header md:blue-header">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody class="lg:divide-y lg:divide-gray-200">
                <tr v-for="appointment in appointmentsOfTheWeek[dateSelected]" :key="appointment.id" @click="openCrmAppointment(appointment.id)">
                  <td class="px-4 pt-5 pb-3 lg:py-5 lg:text-center table-item">
                    <p class="text-gray-800 text-xxs lg:text-sm">
                      <span class="block font-semibold">{{ formatDate(appointment.start, 'MM/DD/YY') }}</span>
                      <span class="block">{{ formatDate(appointment.start, 'hh:mm A', timeZone) }} - {{ formatDate(appointment.end, 'hh:mm A', timeZone) }}</span>
                    </p>
                  </td>
                  <td class="px-4 pt-5 pb-3 lg:py-5 table-item">
                    {{ appointment.agent }}
                  </td>
                  <td class="px-4 pt-5 pb-3 lg:py-5 table-item">
                    {{ appointment.contact_first_name }} {{ appointment.contact_last_name }}
                  </td>
                  <td class="hidden px-4 pt-5 pb-3 lg:py-5 md:table-cell table-item">
                    {{ appointment.plan_type }}
                  </td>
                  <td class="hidden px-4 pt-5 pb-3 lg:py-5 md:table-cell table-item">
                    <p v-if="appointment.type != 'Home'" class="flex text-sm text-gray-800">
                      <Icon
                        name="building"
                        class="w-5 h-5 mr-1 fill-current text-hartblue"
                      />
                      {{ appointment.type == 'Office' ? `${appointment.type} - ${isEmpty(appointment.office_location, 'N/A')}` : appointment.type }}
                    </p>
                    <p v-else class="flex space-x-1 text-xs text-hartblue">
                      <Icon
                        name="location"
                        class="w-5 h-5 fill-current text-hartblue shrink-0"
                      />
                      {{ appointment.contact_mailing_street }} {{ appointment.contact_mailing_city }} {{ appointment.contact_mailing_state }} {{ appointment.contact_mailing_zip }}
                    </p>
                  </td>
                  <td class="px-4 pt-5 pb-3 lg:py-5 table-item">
                    <StatusBadge
                      class="mx-auto"
                      :dropdown="false"
                      :status="appointment.stage"
                    />
                  </td>
                </tr>
                <tr v-if="appointmentsOfTheWeek[dateSelected].length === 0">
                  <td :colspan="breakpoints.isSmaller('sm') ? '4' : '6'" rowspan="4" class="px-4 py-2 text-xs text-center table-item lg:text-sm">
                    <div class="flex flex-col items-center py-8 space-y-4">
                      <img src="@/images/EmptyStates.svg" class="w-40 lg:w-60 " alt="No clients to show picture">
                      <p class="text-sm font-semibold lg:text-lg">
                        No Appointments to show.
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>