<script setup>
import { toRefs } from 'vue'
import Icon from '@/Shared/Icon.vue'
import StageSelect from './AppointmentStageSelect.vue'
import StatusBadge from './StatusBadge.vue'
import { formatDate, formatHourOnly } from '@/utils/dates.js'
import useAppointmentStageUpdater from '@/composables/useAppointmentStageUpdater.js'

const props = defineProps({
  appointment: Object,
})

const { appointment } = toRefs(props)

const emit = defineEmits(['openModal', 'openEditModal'])

const { update } = useAppointmentStageUpdater(appointment)

const updateMiddleware = (stage) => {
  if (stage.name != 'Sold' && stage.name != 'Lost' && stage.name != 'Ongoing') {
    update(stage)
    return true
  }
  emit('openModal', { appointment: appointment.value, ...stage })
}
</script>

<template>
  <div class="flex flex-wrap items-center py-3 border-b lg:py-2">
    <div class="w-4/6 cursor-pointer lg:w-1/6 sm:pr-3 ">
      <p class="text-sm font-semibold text-hartblue">
        {{ formatDate(appointment.start, 'MMMM DD, YYYY') }}
      </p>
      <p class="text-hartblue text-xsm lg:hidden">
        {{ formatHourOnly(appointment.start) }} to {{ formatHourOnly(appointment.end) }}
      </p>
      <p class="lg:hidden text-sm">
        {{ appointment.plan_type }}
      </p>
      <a
        href="#"
        class="flex items-center text-sm text-hartblue lg:hidden"
      >
        <Icon
          name="location"
          class="w-5 h-5 mr-1 fill-current"
        />
        {{ appointment.type == 'Office' ? `${appointment.type} - ${appointment.office_location}` : appointment.type }}
      </a>
    </div>
    <div class="hidden w-4/6 lg:w-1/5 sm:pr-3 lg:inline">
      <p class="text-sm">
        {{ appointment.plan_type }}
      </p>
    </div>
    <div class="hidden w-full lg:w-1/5 sm:px-3 lg:inline">
      <span class="text-sm text-gray-900">{{ formatHourOnly(appointment.start) }} to {{ formatHourOnly(appointment.end) }}</span>
    </div>
    <div class="hidden w-2/6 lg:w-1/5 sm:px-3 lg:block">
      <a
        href="#"
        class="flex items-center text-sm text-hartblue"
      >
        <Icon
          name="location"
          class="w-5 h-5 mr-1 fill-current"
        />
        {{ appointment.type == 'Office' ? `${appointment.type} - ${appointment.office_location}` : appointment.type }}
      </a>
    </div>
    <div class="block w-2/6 lg:w-1/5 sm:px-3">
      <StatusBadge v-if="appointment.stage == 'Updating'" :dropdown="false" :status="appointment.stage" />
      <StageSelect
        v-else
        class="mx-auto"
        :stage="appointment.stage"
        @change="updateMiddleware"
      />
    </div>
  </div>
</template>