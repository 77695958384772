<script setup>
import { toRefs, computed } from 'vue'
import Icon from '@/Shared/Icon.vue'

const props = defineProps({
  clients: Array,
  products: Array,
  filters: Object,
  modelValue: [Number, String],
})

const emit = defineEmits(['close', 'update:modelValue'])

const { filters, products } = toRefs(props)

const typeOptions = [
  { label: 'None', value: '' },
  { label: 'Medicare', value: 'Medicare' },
  { label: 'ACA', value: 'ACA' },
  { label: 'Life', value: 'Life' },
]

const carrierOptions = computed(() => {
  let hash = []
  return products.value.filter((current) => {
    let exists = !hash[current.company]
    hash[current.company] = true
    return exists
  }).map(product => {
    return {
      label: product.company,
      value: product.company,
    }
  })
})

function clearFilters() {
  filters.value.type = ''
  filters.value.carrier = ''
}

function close() {
  emit('close')
}
</script>

<template>
  <div>
    <div class="relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <Icon name="search" class="w-4 h-4 text-gray-500 pointer-events-none fill-current" />
      </div>
      <input
        id="email"
        type="text"
        class="block w-full pl-10 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        placeholder="Search client..."
        @input="emit('update:modelValue', $event.target.value)"
      >
    </div>

    <div class="flex justify-between pt-8">
      <h2 for="period" class="block mb-1 text-xl font-semibold text-white lg:text-gray-900">
        Filter by
      </h2>
      <button class="text-gray-600" @click="clearFilters">
        Clear filters
      </button>
    </div>

    <div class="pt-8">
      <label class="block pb-1 font-semibold text-gray-900">Client Status</label>
      <span class="relative z-0 inline-flex w-full rounded-md shadow-sm">
        <button
          :class="filters.clientStatus ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
          type="button"
          class="relative inline-flex items-center justify-center flex-1 px-4 py-3 text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          @click="filters.clientStatus = true"
        >
          Active
        </button>
        <button
          :class="!filters.clientStatus ? 'bg-hartblue text-white border-hartblue font-medium' : 'bg-white text-gray-800 hover:bg-gray-100 border-gray-300'"
          type="button"
          class="relative inline-flex items-center justify-center flex-1 px-4 py-3 -ml-px text-sm leading-4 transition duration-300 ease-out border border-gray-300 rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          @click="filters.clientStatus = !filters.clientStatus"
        >
          All
        </button>
      </span>
    </div>

    <div class="pt-8">
      <label for="type" class="block pb-1 font-semibold text-gray-900">Type</label>
      <select id="type" v-model="filters.type" class="form-underline">
        <option v-for="(option, index) in typeOptions" :key="index" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <div class="pt-8">
      <label for="carrier" class="block pb-1 font-semibold text-gray-900">Carrier</label>
      <select id="carrier" v-model="filters.carrier" class="form-underline">
        <option value="">
          None
        </option>
        <option v-for="(option, index) in carrierOptions" :key="index" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <!-- <div class="hidden pt-16 lg:block">
      <button class="w-full px-4 py-2 text-blue-400 border-2 border-blue-400 button-rounded" @click="clearFilters">
        Clear Filters
      </button>
    </div> -->
    <div class="flex justify-center pt-10 mx-4 lg:hidden">
      <button
        class="w-full px-6 py-2 leading-relaxed button-rounded button-primary"
        @click="close"
      >
        Apply Filters
      </button>
    </div>
  </div>
</template>