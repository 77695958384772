function blank(str) {
  if (!str) {
    return '--'
  } else {
    return str
  }
}

function phone(phone) {
  if (!phone) {
    return '--'
  } else {
    return phone
      .replace(/[^0-9]/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
}

function moneyFormat(value) {
  if (!value || value == undefined) return '--'
  var formatter = new Intl.NumberFormat('en-US')
  return formatter.format(value)
}

export { blank, phone, moneyFormat }