<script setup>
import { ref } from 'vue'
const props = defineProps({
  size: Number,
  modelValue: [Number, String, Array],
})

const emit = defineEmits(['completed', 'update:modelValue'])

const code = ref([])

function validate(event) {
  const next = event.target.nextElementSibling
  if (event.target.value === '') return

  let finalCode = code.value.join('')
  if (code.value.length === props.size) {
    let passEval = true

    code.value.forEach((val) => {
      if (val === '') {
        passEval = false
      }
    })

    if (passEval) {
      emit('completed', finalCode)
    }

  }
  emit('update:modelValue', finalCode)

  if (next === null) return
  event.target.nextElementSibling.focus()
}

function pasteEvent(event) {
  const data = event.clipboardData.getData('text')
  code.value = data.toString().split('').slice(0, props.size)
  const lastChild = event.target.parentNode.querySelectorAll('input')[props.size - 1]
  lastChild.focus()
  validate(event)
}

function deleteEvent(event) {
  const previous = event.target.previousElementSibling
  if (event.target.value != '') return
  let finalCode = code.value.join('')
  emit('update:modelValue', finalCode)
  if (previous === null) return
  previous.focus()
}

function moveFocus(event) {
  const next = event.target.nextElementSibling
  const previous = event.target.previousElementSibling
  if (event.keyCode == 37) {
    if (previous === null) return
    previous.focus()
  }

  if (event.keyCode == 39) {
    if (next === null) return
    next.focus()
  }

}
</script>

<template>
  <div>
    <input
      v-for="item in size"
      :key="item"
      v-model="code[item - 1]"
      type="tel"
      class="w-12 h-16 pb-2 text-xl font-bold leading-relaxed text-center border-2 form-underline"
      maxlength="1"
      @input="validate"
      @keyup.delete="deleteEvent"
      @paste="pasteEvent"
      @keyup="moveFocus"
    >
  </div>
</template>