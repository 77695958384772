<script setup>
import { ref, toRef, computed, watch, nextTick } from 'vue'
import Icon from '@/Shared/Icon.vue'

const props = defineProps({
  leadSource: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String,
    default: null,
  },
})

const leadSource = toRef(props, 'leadSource')

defineEmits(['update:modelValue'])

const requiredNoteText = ref('')
const leadSourceContentAlert = ref(null)
const leadSourceContentInput = ref(null)
const leadSourceContentHeight = ref(0)
const CONTAINER_DIVIDE_SPACE_Y = 26

const showRequiredNotes = computed(() => {
  return leadSource.value == 'Agent Referral' || leadSource.value == 'Client Referral'
})

const showAgentLeadDescription = computed(() => {
  return leadSource.value == 'Agent Referral'
})

const toggleNotesAndDescription = () => {
  nextTick(() => {
    if (showRequiredNotes.value && showAgentLeadDescription.value) {
      requiredNoteText.value = 'Please provide a detailed description of how this lead was sourced so it can be paid as an agent lead.'
      leadSourceContentHeight.value = leadSourceContentAlert.value?.offsetHeight + leadSourceContentInput.value?.offsetHeight + CONTAINER_DIVIDE_SPACE_Y
    } else if (showRequiredNotes.value && !showAgentLeadDescription.value) {
      requiredNoteText.value = 'Remember, this lead source is for when a previous client referred us. It will be paid as an HIS lead.'
      leadSourceContentHeight.value = leadSourceContentAlert.value.offsetHeight
    } else {
      leadSourceContentHeight.value = 0
    }
  })
}

watch(leadSource, toggleNotesAndDescription, { immediate: true })
</script>

<template>
  <div :class="['transition-all duration-500 overflow-hidden', showRequiredNotes ? '' : '!mt-0' ]" :style="{ height: showRequiredNotes ? leadSourceContentHeight + 'px' : '0px' }">
    <div ref="leadSourceContentAlert" class="flex w-full px-6 py-4 bg-yellow-50 gap-x-3">
      <Icon name="information-circle" class="w-6 h-6 text-[#D65A00] mt-[2px]" />
      <div class="w-[90%]">
        <p class="text-sm h-[63px] leading-[21px]">
          {{ requiredNoteText }}
        </p>
      </div>
    </div>
    <div v-if="showAgentLeadDescription" ref="leadSourceContentInput" class="mt-6">
      <label class="flex pb-1 text-sm font-medium text-gray-700">
        Please provide description <span class="ml-1 text-red-600"> * </span>
      </label>
      <textarea
        :value="modelValue"
        rows="5"
        class="border-red-600 resize-none form-underline"
        required
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
  </div>
</template>