<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
defineProps({
  id: {
    type: String,
    default() {
      return `text-input-${Math.random()}`
    },
  },
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
})

defineEmits(['update:modelValue'])
</script>

<template>
  <div>
    <label v-if="label" class="block text-sm font-medium text-gray-700" :for="id">
      {{ label }} <span v-if="$attrs['required'] != undefined" class="text-red-600">*</span>
    </label>
    <div class="mt-1">
      <input
        :id="id"
        v-bind="$attrs"
        class="form-underline"
        :class="{ error: errors.length }"
        :type="type"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      >
    </div>
    <div
      v-if="errors.length"
      class="mt-2 text-xs text-red-600"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>