<script setup>
import { ref, toRefs, computed } from 'vue'
import Icon from '@/Shared/Icon.vue'
import useTasks from '@/composables/useTasks.js'
import NewTask from '@/Shared/NewTask.vue'
import TaskItem from './TaskItem.vue'
import Pagination from '@/Shared/Pagination.vue'
import usePagination from '@/composables/usePagination.js'

const selected = ref(true)

const props = defineProps({
  client: Object,
  tasks: Array,
})

const { client, tasks } = toRefs(props)

const displayTasks = computed(() => {
  if (selected.value) {
    return tasks.value.filter((task) => {
      return task.status == 'Open'
    })
  } else {
    return tasks.value.filter((task) => {
      return task.status == 'Completed'
    })
  }
})

const {
  openNewTask,
  taskForm,
  createTask,
} = useTasks(client)

const {
  paginatedData,
  paginator,
} = usePagination(displayTasks, { numButtons: 3, pageSize: 3 })

</script>

<template>
  <div id="tasks" class="pt-10">
    <div class="flex flex-row">
      <div class="w-1/2">
        <h2 class="pt-1 pb-2 text-xl font-bold text-gray-900">
          Tasks
        </h2>
      </div>
      <div class="flex justify-end w-1/2 lg:hidden">
        <button
          class="flex justify-end px-4 py-2 m-2 text-xs button-rounded lg:hidden button-primary"
          @click="openNewTask = true"
        >
          New Task
          <Icon
            name="plus"
            class="w-3 h-3 ml-2 fill-current lg:mt-1"
          />
        </button>
      </div>
    </div>
    <div class="flex items-center justify-between pb-2 lg:border-b">
      <span class="relative z-0 inline-flex rounded-md shadow-sm">
        <button
          :class="selected ? 'bg-hartblue text-white border-hartblue font-semibold' : 'bg-white text-gray-900 font-medium hover:bg-gray-100 border-gray-300'"
          type="button"
          class="relative inline-flex items-center px-4 py-2 transition duration-300 ease-out border text-xsm md:text-sm rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          @click="selected = true"
        >
          Open
        </button>
        <button
          :class="!selected ? 'bg-hartblue text-white border-hartblue font-semibold' : 'font-medium bg-white text-gray-900 hover:bg-gray-100 border-gray-300'"
          type="button"
          class="relative inline-flex items-center px-4 py-2 -ml-px transition duration-300 ease-out border text-xsm md:text-sm rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          @click="selected = !selected"
        >
          Completed
        </button>
      </span>
    </div>

    <TaskItem v-for="task in paginatedData" :key="task.id" :task="task" />
    <Pagination v-if="displayTasks.length > 4" :paginator="paginator" />
    <div v-if="paginatedData.length == 0" class="px-2 py-2 mt-2 text-sm text-gray-800 bg-gray-100 rounded-md lg:py-4">
      No tasks to show
    </div>

    <NewTask
      v-model="taskForm"
      :open="openNewTask"
      @close="() => { openNewTask = false; taskForm.reset() }"
      @submit="createTask"
    />
  </div>
</template>