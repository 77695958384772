<script setup>
import { computed } from 'vue'
import * as routes from '@/utils/routes'
import { useForm, usePage } from '@inertiajs/inertia-vue3'

import Logo from '@/Shared/Logo.vue'

const flash = computed(() => usePage().props.value.flash)

const props = defineProps({
  token: {
    type: String,
  },
})

const form = useForm({
  user: {
    password: null,
    password_confirmation: null,
  },
})

</script>

<template>
  <div class="flex h-screen">
    <div class="flex flex-col justify-center w-full max-w-md px-3 mx-auto sm:px-8 lg:px-16 sm:max-w-xl lg:w-1/2">
      <img
        src="@/images/logo.png"
        alt="Hartland Logo"
        class="block h-24 mx-auto lg:h-32 xl:h-32"
      >

      <form class="px-4 sm:px-0 lg:pt-16" @submit.prevent="form.patch(routes.password(props.token))">
        <h1 class="mb-2 text-2xl font-bold text-center text-gray-900">
          Reset Password
        </h1>
        <div class="w-full h-16 mb-4">
          <div v-if="flash.alert" class="px-2 py-2 mb-3 bg-red-100 rounded-md">
            <span class="block text-sm font-medium text-red-700">
              {{ flash.alert }}
            </span>
          </div>
          <div v-else-if="flash.success" class="px-2 py-2 mb-3 bg-green-100 rounded-md">
            <span class="block text-sm font-medium text-green-800">
              {{ flash.success }}
            </span>
          </div>
        </div>

        <div class="flex flex-col">
          <label class="block mb-1 text-sm font-medium text-gray-700">Password</label>
          <input
            v-model="form.user.password"
            type="password"
            required
            class="leading-relaxed form-underline"
          >
        </div>

        <div class="flex flex-col mt-6 sm:mt-10">
          <label class="block mb-1 text-sm font-medium text-gray-700">Confirm Password</label>
          <input
            v-model="form.user.password_confirmation"
            type="password"
            required
            class="leading-relaxed form-underline"
          >
        </div>

        <div class="pt-10">
          <button type="submit" class="w-full px-8 py-3 text-lg button-rounded button-primary">
            Reset Password
          </button>
        </div>
      </form>
      <div class="flex items-center mx-auto lg:pt-16">
        <p class="font-semibold text-gray-500">
          By
        </p>
        <Logo
          version="iq-gray"
          class="block h-4 ml-1"
        />
      </div>
    </div>

    <div class="items-center hidden w-1/2 lg:flex bg-pureblue">
      <img
        class="object-cover object-left w-full h-full ml-auto"
        src="@/images/bg.png"
        alt="Background"
      >
    </div>
  </div>
</template>