<script setup>
import { toRefs, computed } from 'vue'
import useLeadOrClientInfo from '@/composables/useLeadOrClientInfo.js'
import * as routes from '@/utils/routes'
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon.vue'
import StatusBadge from '@/Shared/StatusBadge.vue'

const props = defineProps({
  client: Object,
})

const { client } = toRefs(props)
const { email, formatedPhone, googleMapsUrl, cityState, zipCode } = useLeadOrClientInfo(client)

const clientOrLeadUrl = computed(() => {
  if (client.value.type == 'Client') return routes.activity_client(client.value.id)
  return client.value.type == 'Lead' ? routes.activity_lead(client.value.id) : '#'
})

const clientOrLeadStatus = computed(() => {
  if (client.value.type == 'Client') return client.value.active_client ? 'Active' : 'Inactive'
  return client.value.lead_status
})
</script>

<template>
  <tr v-if="(client.agent == $page.props.auth.user.name)">
    <td class="py-3 lg:py-5 lg:px-4 table-item">
      <Link
        :href="clientOrLeadUrl"
        class="block text-xs font-semibold leading-4 text-hartblue lg:text-sm"
      >
        {{ client.full_name }}
      </Link>
      <div class="flex mt-1 lg:hidden">
        <Icon
          name="location"
          class="w-4 h-4 mr-1 fill-current text-hartblue"
        />
        <a
          :href="googleMapsUrl"
          class="block mt-1 text-xs text-gray-800 lg:hidden"
        >{{ cityState }} {{ zipCode }}</a>
      </div>
    </td>
    <td class="w-20 px-4 text-center lg:w-auto table-item">
      <StatusBadge :dropdown="false" class="mx-auto lg:mx-0" :status="clientOrLeadStatus" />
    </td>
    <td class="lg:px-4 table-item lg:table-cell w-[75px] lg:w-auto">
      <p class="flex text-xs text-gray-900 lg:text-sm">
        <Icon
          name="users"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
        {{ client.type }}
      </p>
    </td>
    <td class="hidden px-4 lg:py-5 table-item lg:table-cell">
      <a
        :href="`tel:${client.mobile}`"
        class="flex text-sm whitespace-nowrap text-hartblue"
      >
        {{ formatedPhone }}
        <Icon
          name="phone"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
      </a>
    </td>
    <td class="hidden px-4 lg:py-5 table-item lg:table-cell">
      <a
        :href="googleMapsUrl"
        class="text-sm text-gray-800 transition duration-300 ease-out lg:flex hover:text-hartblue"
      >
        {{ cityState }} {{ zipCode }}
        <Icon
          name="location"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
      </a>
    </td>
    <td class="hidden px-4 lg:py-5 table-item lg:table-cell">
      <a
        :href="`mailto:${email}`"
        class="text-sm text-gray-800 transition duration-300 ease-out hover:text-hartblue"
      >{{ email }}</a>
    </td>
    <td class="table-cell w-11 lg:hidden">
      <div class="flex items-center py-1">
        <a
          :href="`tel:${client.mobile}`"
          class="flex p-2 ml-1 bg-gray-200 rounded-full text-hartblue"
        >
          <Icon
            name="phone"
            class="w-5 h-5 fill-current"
          />
        </a>
      </div>
    </td>
  </tr>
  <tr v-else>
    <td class="py-3 lg:py-5 lg:px-4 table-item">
      <span
        class="block text-xs font-semibold leading-4 lg:text-sm"
      >
        {{ client.full_name }}
      </span>
      <div class="flex mt-1 lg:hidden">
        <Icon
          name="location"
          class="w-4 h-4 mr-1 fill-current text-hartblue"
        />
        <span
          class="block mt-1 text-xs text-gray-800 lg:hidden"
        >{{ cityState }} {{ zipCode }}</span>
      </div>
    </td>
    <td class="w-20 px-4 lg:w-auto table-item">
      <span>********</span>
    </td>
    <td class="lg:px-4 table-item lg:table-cell w-[75px] lg:w-auto">
      <p class="flex text-xs text-gray-900 lg:text-sm">
        <Icon
          name="users"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
        {{ client.type }}
      </p>
    </td>
    <td class="hidden px-4 lg:py-5 table-item lg:table-cell">
      <span
        class="flex text-sm whitespace-nowrap "
      >
        {{ formatedPhone }}
        <Icon
          name="phone"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
      </span>
    </td>
    <td class="hidden px-4 lg:py-5 table-item lg:table-cell">
      <span
        class="text-sm text-gray-800 transition duration-300 ease-out lg:flex"
      >
        {{ cityState }} {{ zipCode }}
        <Icon
          name="location"
          class="w-5 h-5 ml-1 fill-current text-hartblue"
        />
      </span>
    </td>
    <td class="hidden px-4 lg:py-5 table-item lg:table-cell">
      <span
        class="text-sm text-gray-800 transition duration-300 ease-out"
      >{{ email }}</span>
    </td>
    <td class="table-cell w-11 lg:hidden">
      <div class="flex items-center py-1">
        <span
          class="flex p-2 ml-1 bg-gray-200 rounded-full text-hartblue"
        >
          <Icon
            name="phone"
            class="w-5 h-5 fill-current"
          />
        </span>
      </div>
    </td>
  </tr>
</template>