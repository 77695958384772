<script setup>
import { ref, toRefs, computed } from 'vue'
import NotesItem from './NotesItem.vue'
import Pagination from '@/Shared/Pagination.vue'
import usePagination from '@/composables/usePagination.js'
import { scrollToEnd } from '@/utils/helpers.js'

const props = defineProps({
  notes: Array,
  limit: {
    type: Number,
    default: 3,
  },
  creating: Boolean,
  modelValue: {
    type: Object,
  },
})

const { notes, limit } = toRefs(props)

const emit = defineEmits(['update:modelValue', 'submit'])

const form = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const formElement = ref(null)

function submitForm() {
  if (formElement.value.requestSubmit) {
    formElement.value.requestSubmit()
  } else {
    formElement.value.submit()
  }
}

const {
  paginatedData,
  paginator,
} = usePagination(notes, { numButtons: 3, pageSize: limit.value })
</script>

<template>
  <div
    id="notes"
    class="pt-8"
  >
    <h2 class="py-2 text-xl font-bold text-gray-900">
      Notes
    </h2>
    <div class="">
      <NotesItem v-for="note in paginatedData" :key="note.id" :note="note" />
      <Pagination v-if="notes.length > 3" :paginator="paginator" />
      <div v-if="paginatedData.length == 0" class="px-2 py-2 mt-2 text-sm text-gray-800 bg-gray-100 rounded-md lg:py-4">
        No notes to show
      </div>
    </div>
    <div class="relative pt-4">
      <form ref="formElement" @submit.prevent="emit('submit')">
        <textarea
          v-model="form.content"
          rows="5"
          class="resize-none form-underline pb-14"
          placeholder="Start typing to leave a note…"
          required
          @input="scrollToEnd"
        />
        <div class="absolute bottom-0 right-0 flex items-center px-4 py-4">
          <button
            type="button"
            class="px-8 py-2 text-xs lg:text-sm button-rounded button-primary disabled:cursor-not-allowed disabled:bg-blue-300 disabled:border-blue-300"
            :disabled="creating"
            @click="submitForm"
          >
            {{ creating?'Creating':'Submit' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>