<script setup>
import { ref, toRefs, computed } from 'vue'
import Icon from '@/Shared/Icon.vue'
import { formatDate } from '@/utils/dates.js'

const props = defineProps({
  note: Object,
})

const { note } = toRefs(props)
const showMore = ref(false)

const noteTypes = {
  'Agent': {
    icon: 'bell',
    class: 'bg-blue-400',
  },
  'Audit': {
    icon: 'lock',
    class: 'bg-blue-600',
  },
  'Recorded': {
    icon: 'phone',
    class: 'bg-blue-800',
  },
  'Default': {
    icon: 'calendar',
    class: 'bg-hartblue',
  },
}

const noteType = computed(() => {
  if (note.value.title != null && note.value.title.includes('Agent Note -')) return 'Agent'
  if (note.value.title != null && note.value.title.includes('Audit Log')) return 'Audit'
  if (note.value.title != null && note.value.title.includes('Call Recorded')) return 'Recorded'
  return 'Default'
})

const noteTitle = computed(() => {
  if (note.value.title != null && note.value.title.includes('Agent Note -')) return note.value.title
  if (note.value.title != null && note.value.title.includes('Audit Log')) return note.value.title
  if (note.value.title != null && note.value.title.includes('Call Recorded')) return note.value.title
  return `Hartland - ${note.value.owner_name}`
})

const noteDate = computed(() => {
  return formatDate(note.value.created_at, 'MMM DD, YYYY [a]t h:mm a')
})

const filterContent = computed(() => {
  return note.value?.content?.replace(/\n/g, '<br/>')
})
</script>

<template>
  <div class="lg:flex lg:items-start">
    <div :class="[noteTypes[noteType].class]" class="hidden mt-5 text-white rounded-full lg:block">
      <Icon
        :name="noteTypes[noteType].icon"
        class="w-6 h-6 m-2 fill-current "
        @click="showMore = !showMore"
      />
    </div>
    <div class="flex flex-col flex-1 py-4 border-b lg:ml-4">
      <div class="flex items-center justify-between">
        <div :class="[noteTypes[noteType].class]" class="text-white rounded-full lg:hidden">
          <Icon
            :name="noteTypes[noteType].icon"
            class="w-6 h-6 m-1 fill-current"
            @click="showMore = !showMore"
          />
        </div>
        <div class="flex-1 pl-3 cursor-pointer lg:pl-0" @click="showMore = !showMore">
          <p class="text-sm font-semibold text-gray-900 lg:text-base">
            {{ noteTitle }}
          </p>
          <p class="font-medium text-gray-600 text-xsm lg:hidden">
            {{ noteDate }}
          </p>
        </div>
      </div>
      <div class="flex items-center justify-between mt-2 lg:mt-1">
        <p
          v-if="!showMore"
          class="flex-1 py-1 font-medium text-gray-700 lg:py-0 text-xsm lg:text-sm lg:pr-20 line-clamp-3"
          v-html="filterContent"
        />
        <p
          v-else
          class="flex-1 py-1 font-medium text-gray-700 lg:py-0 text-xsm lg:text-sm lg:pr-20"
          v-html="filterContent"
        />
        <Icon 
          v-if="noteType == 'Recorded'" 
          v-tooltip="'Please email help@gohartland.com for a copy of the recorded call.'" 
          name="information-circle" 
          class="w-5 h-5 mr-3 text-blue-400" 
        />
        <p class="hidden text-sm font-medium text-gray-600 lg:block">
          {{ noteDate }}
        </p>
      </div>
    </div>
  </div>
</template>