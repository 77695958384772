<script setup>
import { ref } from 'vue'
// import { DatePicker } from 'v-calendar'
import Icon from '@/Shared/Icon.vue'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import * as routes from '@/utils/routes'

const props = defineProps({
  filters: Object,
})

const filters = ref(props.filters)

const emit = defineEmits(['close'])

const typeOptions = [
  { label: 'All', value: 'All' },
  { label: 'Medicare', value: 'Medicare' },
  { label: 'ACA', value: 'ACA' },
  { label: 'Life', value: 'Life' },
]

const dateRangeOptions = [
  { label: 'Recent + Future', value: 'recent-future' },
  { label: 'Upcoming', value: 'upcoming' },
  { label: 'This Week', value: 'this-week' },
  { label: 'Last Week', value: 'last-week' },
  { label: 'Next Week', value: 'next-week' },
  { label: 'This month', value: 'this-month' },
  { label: 'Last 30 days', value: 'last-thirty-days' },
  { label: 'Last Month', value: 'last-month' },
  { label: 'Current Year', value: 'this-year' },
]

const statusOptions = [
  { label: 'Need to Schedule', value: 'Need to Schedule' },
  { label: 'Scheduled', value: 'Scheduled' },
  { label: 'Unable to Confirm', value: 'Unable to Confirm' },
  { label: 'Confirmed', value: 'Confirmed' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'No Show', value: 'No Show' },
  { label: 'Started', value: 'Started' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Lost', value: 'Lost' },
  { label: 'Ongoing', value: 'Ongoing' },
  { label: 'Completed', value: 'Completed' },
]

const clearFilters = () => {
  filters.value.type = 'All'
  filters.value.dateRange = 'upcoming'
  filters.value.stage = []
}
// const date = ref(new Date())
</script>

<template>
  <div>
    <div class="flex justify-between pt-1">
      <h2 for="period" class="block mb-1 text-xl font-semibold text-white lg:text-gray-900">
        Filter by
      </h2>
      <button class="text-gray-600" @click="clearFilters">
        Clear filters
      </button>
    </div>

    <div class="pt-4">
      <label for="type" class="block pb-1 font-semibold text-gray-900">Type</label>
      <select id="type" v-model="filters.type" class="form-underline">
        <option v-for="(option, index) in typeOptions" :key="index" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <div class="pt-8">
      <label for="period" class="block mb-1 font-semibold text-gray-900">Date Range</label>
      <select id="period" v-model="filters.dateRange" class="form-underline">
        <option v-for="option in dateRangeOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <div class="pt-8">
      <label for="status" class="block mb-1 font-semibold text-gray-900">Status</label>
      <Listbox v-model="filters.stage" multiple>
        <div class="relative">
          <ListboxButton class="relative w-full py-2 pl-3 pr-10 min-h-[38px] text-left border border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500">
            {{ filters.stage.map((person) => person).join(', ') }}
          </ListboxButton>
          <ListboxOptions
            class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-for="option in statusOptions"
              :key="option.value"
              v-slot="{ active, selected }"
              :value="option.value"
            >
              <li
                :class="[
                  active ? 'bg-gray-100 text-gray-700' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-10 pr-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ option.label }}
                </span>
                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-hartblue">
                  <Icon name="check" class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
    <!-- <div class="mt-8 rounded shadow-sm">
      <DatePicker v-model="date" color="indigo" is-expanded />
    </div>
    <div class="flex justify-center py-3 space-x-8">
      <div class="flex items-center">
        <span
          style="margin-bottom: 2px;"
          class="w-2 h-2 bg-red-600 rounded-full"
        />
        <p class="ml-1 text-sm font-medium text-gray-900">
          Filled
        </p>
      </div>
      <div class="flex items-center">
        <span
          style="margin-bottom: 2px;"
          class="w-2 h-2 rounded-full bg-hartblue"
        />
        <p class="ml-1 text-sm font-medium text-gray-900">
          Available
        </p>
      </div>
    </div>
    <p class="text-xs text-center text-gray-800">
      *Days without a blue or red dot means they are  blocked.
    </p> -->

    <div class="flex justify-center pt-10 mx-4 lg:hidden">
      <button
        class="w-full px-6 py-2 leading-relaxed button-rounded button-primary"
        @click="emit('close')"
      >
        Apply Filters
      </button>
    </div>
    <div class="flex pt-5 mt-5 border-t border-gray-400">
      <a
        class="flex items-center justify-center w-full px-6 py-2 text-sm text-center border-2 border-hartblue button-rounded button-outline"
        :href="routes.availability_appointments()"
        target="_blank"
      >
        View Calendar
        <Icon
          name="calendar"
          class="w-5 h-5 mt-px ml-2 fill-current"
        />
      </a>
    </div>
  </div>
</template>