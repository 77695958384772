<script setup>
import { ref, toRefs, computed } from 'vue'
import { DatePicker } from 'v-calendar'
import SlideOver from '@/Shared/SlideOver.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import * as routes from '@/utils/routes'
import 'v-calendar/dist/style.css'
import { timezones, formatDateOnly, dayjs, formatHourOnly } from '@/utils/dates.js'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  appointments: {
    type: Array,
    default() {
      return []
    },
  },
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['close', 'update:modelValue', 'submit'])

const form = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const { open, appointments } = toRefs(props)
const formElement = ref(null)
const timezone = ref(timezones[0])

const dateRules = ref({
  hours: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
})

const timezoneCalendar = computed(() => {
  if (timezone.value.value == 'local') return null
  return timezone.value.value
})

const appointmentsOfSelectedDay = computed(() => {
  const dateSelected = form.value.appointment.date || dayjs()
  return appointments.value.filter(appointment => {
    return formatDateOnly(appointment.start) == formatDateOnly(dateSelected) && appointment.stage != 'Cancelled'
  }).sort((a, b) => {
    return new Date(a.start) - new Date(b.start)
  })
})

const places = [
  { id: 'Office', name: 'Office' },
  { id: 'Home', name: 'Home' },
  { id: 'Phone', name: 'Phone' },
  { id: 'Zoom', name: 'Zoom' },
]

const offices = [
  { id: 'Berwyn', name: 'Berwyn' },
  { id: 'Cicero', name: 'Cicero' },
  { id: 'Woodridge', name: 'Woodridge' },
  { id: 'Lawrence', name: 'Lawrence' },
  { id: 'Las Vegas', name: 'Las Vegas' },
  { id: 'Phoenix', name: 'Phoenix' },
  { id: 'Temporary', name: 'Temporary' },
]

const planTypes = [
  { id: 'Medicare', name: 'Medicare' },
  { id: 'ACA', name: 'ACA' },
  { id: 'Life', name: 'Life' },
]

function submitForm() {
  if (!validateFormDates()) return
  if (formElement.value.requestSubmit) {
    formElement.value.requestSubmit()
  } else {
    formElement.value.submit()
  }
}

function validateFormDates() {
  if (form.value.appointment.id != undefined) return true
  form.value.errors.date = undefined
  let pass = false
  if (form.value.appointment.date != null) {
    pass = true
  } else {
    pass = false
    form.value.errors.date = ['Select a date']
  }
  return pass
}
</script>

<template>
  <SlideOver :title="form.appointment.id ? 'Edit appointment' : ' New appointment'" :open="open" @close="emit('close')">
    <template #default>
      <div class="relative">
        <div v-if="form.processing" class="absolute z-10 flex items-center justify-center w-full h-full bg-gray-300 bg-opacity-60 ">
          <div class="mt-1 lds-ring">
            <div /><div /><div /><div />
          </div>
        </div>
        <form ref="formElement" class="flex flex-col divide-y divide-gray-200" @submit.prevent="emit('submit')">
          <div class="px-4 pb-40 space-y-6 lg:pb-5 sm:px-6">
            <SelectInput
              v-model="timezone"
              label="Timezone"
              :errors="[]"
            >
              <option :value="null" diabled selected>
                Select a timezone
              </option>
              <option v-for="tz in timezones" :key="tz.value" :value="tz">
                {{ tz.label }}
              </option>
            </SelectInput>
            <div>
              <label class="block text-sm font-medium text-gray-700">Select day</label>
              <div class="mt-1 rounded shadow-sm">
                <DatePicker
                  v-model="form.appointment.date"
                  mode="dateTime"
                  color="indigo"
                  :timezone="timezoneCalendar"
                  :rules="dateRules"
                  expanded
                />
              </div>
              <div
                v-if="form.errors.date"
                class="mt-2 text-xs text-red-600 lg:text-sm"
              >
                {{ form.errors.date[0] }}
              </div>
            </div>
            <div>
              <div class="flex justify-between mb-2">
                <h3 class="block text-sm font-semibold text-gray-700">
                  Scheduled this day
                </h3>
                <a
                  class="text-sm text-hartblue"
                  :href="routes.availability_appointments()"
                  target="_blank"
                >
                  Full Calendar
                </a>
              </div>
            
              <ul v-if="appointmentsOfSelectedDay.length >= 1" class="divide-y rounded bg-gray-100 py-1 px-2">
                <li v-for="appointment in appointmentsOfSelectedDay" :key="appointment.id" class="flex justify-between py-1">
                  <span class="text-gray-800">{{ formatHourOnly(appointment.start) }} to {{ formatHourOnly(appointment.end) }}</span>
                  <span class="text-gray-800">{{ appointment.type == 'Office' ? `${appointment.type} - ${appointment.office_location}` : appointment.type }}</span>
                </li>
              </ul>
              <div v-else class="bg-gray-100 py-1 text-center">
                <span class="font-semibold text-gray-800">None</span>
              </div>
            </div>
            <!-- <SelectInput
              v-model="form.appointment.hour"
              label="Pick an hour"
              required
              :errors="form.errors.hour"
            >
              <option :value="null" diabled selected>
                Select an hour
              </option>
              <option v-for="hour in hours" :key="hour.id" :value="hour.id">
                {{ hour.name }}
              </option>
            </SelectInput> -->

            <SelectInput
              v-model="form.appointment.type"
              label="Meeting place"
              required
              :errors="form.errors.type"
              :disabled="form.appointment.type == 'Office'"
            >
              <option value="" diabled selected>
                Select a meeting place
              </option>
              <option v-for="place in places" :key="place.id" :value="place.id">
                {{ place.name }}
              </option>
            </SelectInput>

            <SelectInput
              v-if="form.appointment.type == 'Office'"
              v-model="form.appointment.office_location"
              label="Office location"
              required
              :errors="form.errors.office_location"
            >
              <option value="" diabled selected>
                Select an office location
              </option>
              <option v-for="office in offices" :key="office.id" :value="office.id">
                {{ office.name }}
              </option>
            </SelectInput>

            <SelectInput
              v-model="form.appointment.plan_type"
              label="Type"
              required
              :errors="form.errors.plan_type"
            >
              <option value="" diabled selected>
                Select an type
              </option>
              <option v-for="planType in planTypes" :key="planType.id" :value="planType.id">
                {{ planType.name }}
              </option>
            </SelectInput>
            <!-- <div>
              <label for="description" class="block text-sm font-medium text-gray-700">Notes</label>
              <div class="mt-1">
                <textarea
                  id="description"
                  v-model="form.appointment.description"
                  class="resize-none form-underline"
                  rows="6"
                  placeholder="A short description of the appointment..."
                />
              </div>
            </div> -->
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end flex-shrink-0 px-4 py-4 space-x-3">
        <button
          class="px-4 py-2 text-sm border-2 button-outline button-rounded"
          @click="emit('close')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="px-6 py-2 text-sm border-2 border-hartblue button-rounded button-primary"
          :enabled="form.processing"
          @click="submitForm"
        >
          {{ form.appointment.id ? 'Update appointment' : 'Create Appointment' }}
        </button>
      </div>
    </template>
  </SlideOver>
</template>
