<script setup>
import { ref, onMounted, toRefs } from 'vue'
import Icon from '@/Shared/Icon.vue'
import useRecordCall from '@/composables/useRecordCall.js'
import usePhoneVerification from '@/composables/usePhoneVerification.js'
import { formatPhone } from '@/utils/helpers.js'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const props = defineProps({
  client: Object,
  phoneField: String,
})

const { verifyCallerId } = usePhoneVerification()
const phoneVerified = ref(false)

onMounted(async () => phoneVerified.value = await verifyCallerId())

const { client, phoneField } = toRefs(props)
const { open, call, generateConferenceCode, closeModalAndCleanData } = useRecordCall(client, phoneField.value)

</script>

<template>
  <button v-if="phoneVerified" class="w-5 h-5 ml-2 bg-red-600 rounded-full" @click="generateConferenceCode()">
    <span class="sr-only">Record Call</span>
  </button>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="closeModalAndCleanData()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button type="button" class="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2" @click="closeModalAndCleanData()">
                  <span class="sr-only">Close</span>
                  <Icon name="close" class="w-4 h-4 fill-current" aria-hidden="true" />
                </button>
              </div>
              <div class="mt-5 text-center">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                  🔴 Calling
                </DialogTitle>
              </div>
              <div class="flex">
                <div class="relative flex items-center justify-center w-64 h-64">
                  <span :class="{ 'calling-bubbles': call.agent_calling }" />
                  <div class="absolute flex items-center justify-center w-12 h-12 mx-auto bg-blue-100 rounded-full ">
                    <Icon name="phone" class="w-6 h-6 fill-current text-hartblue" aria-hidden="true" />
                  </div>
                  <p class="absolute pt-20 text-sm font-semibold text-gray-500">
                    {{ $page.props.auth.user.name }}
                  </p>
                  <p class="absolute pt-[124px]   text-sm text-gray-500">
                    on {{ formatPhone($page.props.auth.user.phone.slice(2)) }}
                  </p>
                </div>
                <div class="relative flex items-center justify-center w-64 h-64">
                  <span :class="{ 'calling-bubbles': call.client_calling }" />
                  <div class="absolute flex items-center justify-center w-12 h-12 mx-auto bg-blue-100 rounded-full ">
                    <Icon name="phone" class="w-6 h-6 fill-current text-hartblue" aria-hidden="true" />
                  </div>
                  <p class="absolute pt-20 text-sm font-semibold text-gray-500">
                    {{ client.full_name }}
                  </p>
                  <p class="absolute pt-[124px]   text-sm text-gray-500">
                    on {{ formatPhone(client[phoneField]) }} | {{ phoneField != 'mobile' ? 'Home' : 'Mobile' }}
                  </p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>