<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  id: {
    type: String,
    default() {
      return `select-input-${Math.random()}`
    },
  },
  modelValue: {
    type: [String, Number, Boolean, Object],
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])

const selected = ref(props.modelValue)

watch(selected, (selected) => {
  emit('update:modelValue', selected)
})
</script>

<template>
  <div>
    <label v-if="label" class="block text-sm font-medium text-gray-700" :for="id">
      {{ label }} <span v-if="$attrs['required'] != undefined" class="text-red-600">*</span>
    </label>
    <div class="mt-1">
      <select
        :id="id"
        v-model="selected"
        v-bind="$attrs"
        class="form-underline"
        :class="{ error: errors.length }"
      >
        <slot />
      </select>
    </div>
    <div
      v-if="errors.length"
      class="mt-2 text-xs text-red-600"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>